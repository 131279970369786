import React, {Fragment, useRef, useContext, useEffect, useState} from 'react';
import { Toast } from 'primereact/toast';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment/moment";
import {MessageNoteContext} from "../../../context/messageNoteContext";
import './MessageNote.css'
import {RadioButton} from "primereact/radiobutton";
import ReactTooltip from "react-tooltip";

import ApiLoan from "../../../service/ApiLoanService";
import InfiniteScroll from 'react-infinite-scroller';
import CustomSpinner from '../../../UI/CustomSpinner';

const MessageNote = (props) => {
    const {messageNotes, selectedClient, globalClients, updateSelectedMessageNote, deleteReminder,
        seenNoteReminder, getPageNote, getResetNote, getPagesSheduled, loadingScheduled, loadingNote, hasMoreNote, hasMoreScheduled} = useContext(MessageNoteContext);
    const [messagesTemp, setMessagesTemp] = useState([]);
    const [notes, setNotes] = useState([]);
    const [messageNoteType, setMessageNoteType] = useState('notes');
    const [loadingNotes, setLoadingNotes] = useState(false);
    const scrollParentRef = useRef(null);

    const isMobile = () => {
        return window.innerWidth <= 750;
    };

    useEffect(() => {
        if (selectedClient !== null){
            if (!loadingNotes){
                const apiService = new ApiLoan();
                return apiService.getResources({
                    url: '/note/reminders',
                    filters: {
                        message_types: ['note'],
                        client_id: selectedClient
                    },
                    results_per_page: 40,
                    page: 1
                }).then(response => {
                    setNotes(response.data.objects.filter(x => x.message_type.name === 'note'))
                    setLoadingNotes(true);
                });
            }
            getResetNote(['scheduled_message','note_reminder']);
        }

    }, [selectedClient, loadingNotes])

    useEffect(() => {
        if (selectedClient !== null){
            if (messageNoteType !== 'notes'){
                setMessagesTemp(messageNotes[messageNoteType])
            } else {
                setMessagesTemp(notes)
            }
        }
        else if (globalClients){
            if (messageNoteType === 'notes'){
                setMessageNoteType('note_reminders')
                return
            }
            setMessageNoteType(messageNoteType)
            let messagesFiltered = messageNotes[messageNoteType]
            setMessagesTemp(messagesFiltered)
        }
    }, [messageNotes, notes, selectedClient, globalClients, messageNoteType]);

    const showItemHandler = (data) => {
        const client_contact = data.client.contacts.filter(x => x.contact_type_id === 1)[0];

        if (client_contact === undefined){
            return null;
        }

        updateSelectedMessageNote({
            message_id: data.id,
            message_type: data.message_type.name,
            contact: client_contact
        });
    }

    const itemTemplate = (data) => {
        const time = moment(data.created_timezone).format('HH:mm');
        const date = moment(data.created_timezone).format('DD/MM/YY');
        const fulltime = time.concat('hs').concat(' - ').concat(date);
        const description = data?.user?.username ? (data?.user?.username?.concat(' - ').concat(fulltime)) : fulltime;

        let disabled_delete_button = data.message_type.name === 'scheduled_message' && data.has_children
        let removeButtonClass = 'btn-actions btn-fontawesome'

        if (disabled_delete_button){
            removeButtonClass = removeButtonClass + ' ' + 'note-button-cancel';
        }

        const noteButtonRemove = (
            <Fragment>
                <button data-tip disabled={disabled_delete_button} data-for="deleteNote"
                        className="p-link note-button-cancel"
                        onClick={(e) => deleteReminder({message_id: data.id, message_type: data.message_type.name})}>
                    <FontAwesomeIcon icon={'times'} color={'red'} size={"xs"} className={removeButtonClass}/>
                </button>
                {!isMobile() &&
                    <ReactTooltip id="deleteNote" place="top" effect="solid">
                        Eliminar
                    </ReactTooltip>
                }
            </Fragment>
        );

        const date_now = moment(new Date()).format('MM/DD/YYYY')
        let reminder_date = data.note_reminder !== null ?  moment(moment(data.note_reminder?.reminder_date).format('MM/DD/YYYY')) : null
        let note_reminder_expired = reminder_date !== null ? reminder_date.isSameOrAfter(date_now) ? 'expired' : 'not-expired' : '';
        let note_reminder_color = reminder_date !== null ? reminder_date.isSame(date_now) ? 'expired' : 'not-expired' : '';

        const noteReminderCheck = note_reminder_expired === 'expired' && data.message_type.name !== 'scheduled_message' ? (
            <Fragment>
                <button data-tip data-for="seenNote" className="p-link" onClick={(e) => seenNoteReminder(data.id)}>
                    <FontAwesomeIcon icon={"check"} color={'#607D8B'} size={"xs"} className={"btn-actions btn-fontawesome"}/>
                </button>
                {!isMobile() &&
                    <ReactTooltip id="seenNote" place="top" effect="solid">
                        Marcar como leído
                    </ReactTooltip>
                }
            </Fragment>
        ) : null

        let note_icon = 'note-sticky';
        let tooltip = null;
        let tooltip_id = null;
        let color = 'grey'

        if (messageNoteType === 'note_reminders' || messageNoteType === 'scheduled_messages'){
            note_icon = messageNoteType === 'note_reminders' ? 'bell' : 'robot';
            let note_reminder_date = reminder_date

            if (note_reminder_date !== null){
                let message_type = messageNoteType === 'note_reminders' ? 'Recordatorio' : 'Mensaje Programado';
                let active_label = note_reminder_date.isSameOrAfter(date_now) && !data.note_reminder.seen ? 'activo' : 'inactivo';
                color = note_reminder_date.isSameOrAfter(date_now) && !data.note_reminder.seen ? 'orange' : 'grey';
                let tooltip_label = (
                    <p> {message_type} {active_label} : {moment(data.note_reminder.reminder_date).format('DD-MM-YYYY HH:mm')}</p>
                )

                tooltip_id = 'message-note-id-' + data.id;

                tooltip = (
                    !isMobile() ?
                    <ReactTooltip id={tooltip_id} place="top" effect="solid" key={color}>
                        {tooltip_label}
                    </ReactTooltip> : null
                );
            }

        }

        return (
            <Fragment>
                <div className={`p-fluid p-mt-3 p-mb-3 reminder-${note_reminder_color}`}>
                    <FontAwesomeIcon icon={note_icon} color={color} size={"xs"} data-tip data-for={tooltip_id} className={"btn-actions btn-fontawesome"}/>
                    <div className="p-col p-sm-12 p-md-12 p-lg-8">
                        <span>{data.body}</span>
                        <p className="p-text-left" style={{color:"#4a4141"}}>
                            <small>{description}</small>
                        </p>
                    </div>
                    <div className="p-field">
                        <Fragment>
                            <button data-tip data-for="showNote" className="p-link" onClick={(e) => showItemHandler(data) }>
                                <FontAwesomeIcon icon={"eye"} color={'#607D8B'} size={"xs"} className={"btn-actions btn-fontawesome"}/>
                            </button>
                            {!isMobile() &&
                                <ReactTooltip id="showNote" place="top" effect="solid">
                                    Visualizar
                                </ReactTooltip>
                            }
                        </Fragment>
                        {noteButtonRemove}
                        {noteReminderCheck}
                    </div>
                </div>
                {tooltip}
            </Fragment>
        );
    }

    const messageNotesList = messagesTemp.map((element) => {
        return itemTemplate(element);
    });

    const toast = useRef(null);

    let header_label = messageNoteType === 'notes' ? 'Notas' : messageNoteType === 'note_reminders' ? 'Recordatorios' : 'Mensajes Programados';

    let radio_button_disabled = globalClients ? 'note-radio-button-disabled' : '';

    const toggleRadioButton = (value) => {
        if (scrollParentRef.current) {
            scrollParentRef.current.scrollTop = 0
        };
        setMessageNoteType(value);
    }

    const getMorePage = () => {
        switch (messageNoteType) {
            case 'note_reminders':
                getPageNote();
                break;
            case 'scheduled_messages':
                getPagesSheduled()
                break;
            case 'notes':
                break;
        }
    }

    const resultNotFount = (
        <div style={{ textAlign: 'center' }}>
          <p>No se encontró ningún resultado.</p>
        </div>
    );

    const getResultNotFount = () => {
        switch (messageNoteType) {
            case 'note_reminders':
                if (!loadingNote && messageNotesList.length === 0) return true;
            case 'scheduled_messages':
                if (!loadingScheduled && messageNotesList.length === 0) return true;
            case 'notes':
                if (loadingNotes && messageNotesList.length === 0) return true;
        }
    };

    const spinnerNote = (
        loadingNote && <CustomSpinner status={'Obteniendo ' + header_label + '...'}/>
    );

    const spinnerScheduled = (
        loadingScheduled && <CustomSpinner status={'Obteniendo ' + header_label + '...'}/>
    );

    return (
        <Fragment>
            <Toast ref={toast} />
            <div className={"p-d-flex"}>
                <div className={`p-ml-2 p-mr-2 ${radio_button_disabled}`}>
                    <label className={"p-mr-2"} htmlFor="notes">Notas</label>
                    <RadioButton inputId="note" name="message_note" value={'notes'} onChange={(e) => toggleRadioButton(e.value)} checked={messageNoteType === 'notes'} disabled={globalClients} />
                </div>
                <div className="p-ml-2 p-mr-2">
                    <label className={"p-mr-2"} htmlFor="reminders">Recordatorios</label>
                    <RadioButton inputId="reminder" name="message_note" value={'note_reminders'} onChange={(e) => toggleRadioButton(e.value)} checked={messageNoteType === 'note_reminders'} />
                </div>
                <div className="p-ml-2 p-mr-2">
                    <label className={"p-mr-2"} htmlFor="reminders">Mensajes Programados</label>
                    <RadioButton inputId="scheduled_message" name="message_note" value={'scheduled_messages'} onChange={(e) => toggleRadioButton(e.value)} checked={messageNoteType === 'scheduled_messages'} />
                </div>
            </div>
            <div style={{height:'20px'}}></div>
            {getResultNotFount() && resultNotFount}
            <div className='scrollable datascroller-notes' style={{height: "50vh"}} ref={scrollParentRef}>
                <InfiniteScroll
                    pageStart={1}
                    isReverse={false}
                    initialLoad={false}
                    useWindow={false}
                    loadMore={getMorePage}
                    hasMore={messageNoteType === 'notes' ? true : messageNoteType === 'note_reminders' ? hasMoreNote : hasMoreScheduled}
                    getScrollParent={() => scrollParentRef.current}
                >
                {messageNotesList}
                </InfiniteScroll>
            </div>
            {spinnerNote}
            {spinnerScheduled}
        </Fragment>
    );
}

export default MessageNote;