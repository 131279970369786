import React, {Fragment, useState, useEffect, useRef, useContext} from 'react';

import ApiLoan from "../../../service/ApiLoanService";

import { MessageContext } from "../../../context/messageContext";

import CustomSpinner from "../../../UI/CustomSpinner";

import ConversationList from '../ConversationList';
import MessageList from '../MessageList';
import { CustomModal, CustomModalHeader, CustomModalBody } from "../../../UI/CustomModal";
import MessageNoteContextProvider from "../../../context/MessageNoteContextProvider";

import './Messenger.css';
import {switchRole} from "../../../shared/roles";
import {CompanyContext} from "../../../context/companyContext";

export default function Messenger(props) {
  const [client, setClient] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const scrolleableDiv = useRef(null);
  const [buttonsActivated, setButtonsActivated] = useState(false)

  const [loading, setLoading] = useState(true)
  const [customTags, setCustomTags] = useState([])
  const [clientCustomTags, setClientCustomTags] = useState([])
  const [selectedCustomTags, setSelectedCustomTags] = useState([])
  const [closeModal, setCloseModal] = useState(false)
  const [messageContext, setMessageContext] = useState(null);
  const [messageReaction, setMessageReaction] = useState(null);
  const [height, setHeight] = useState(window.innerWidth);

  const [selectedMessageContext, setSelectedMessageContext] = useState(null);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [foundMessageContext, setFoundMessageContext] = useState(false);

  const [forward, setForward] = useState(false);
  const [selectedForwardMessage, setSelectedForwardMessage] = useState([]);
  const [selectedForwardContact, setSelectedForwardContact] = useState(null);
  const [forwardContactModal, setForwardContactModal] = useState(false)

  const [selectedPhoneContact, setSelectedPhoneContact] = useState(null);
  const [loadingSearchSelectedPhoneContact, setLoadingSearchSelectedPhoneContact] = useState(false);

  const isMobile = () => {
    return window.innerWidth <= 750;
  };

  const company_context = useContext(CompanyContext);
  const socket = company_context.socket;
  const [groups, setGroups] = useState({
    options: [],
    page: 1,
    num_results: 0,
    loading: true
  });

  useEffect(() => {
    if (isMobile() && client !== null) {
      window.history.pushState(null, document.title, window.location.href);

      window.history.replaceState(null, document.title, window.location.href);

      window.addEventListener('popstate', function() {
        setClient(null);
      });
    }
  },[isMobile(), client]);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newHeight = window.innerWidth;
      setHeight(newHeight);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);

  }, []);

  useEffect(() => {
    const apiService = new ApiLoan();
    apiService.getResources({
      url: '/all_custom_tags',
    }).then(response => {
      const objects = response.data.objects;
      const tags = objects.map(object => {
        return {
          'id': object.id,
          'name': object.name,
          'fixed': object.fixed,
          'color': object.color,
        }
      });
      setCustomTags(tags);
      setLoading(false);
    });
    return () => {
      setLoading(true)
      setCustomTags([])
    }
  },[]);

  useEffect(() => {
      const apiService = new ApiLoan();

      apiService.getResources({
          url: '/groups',
          page: groups.page
      }).then(response => {
          setGroups((prevState) => {
             return {
                 ...prevState,
                 options: [
                     ...prevState.options,
                     ...response.data.objects
                 ],
                 page: response.data.page,
                 num_results: response.data.num_results,
                 loading: response.data.page < response.data.total_pages
             }
          });
      })
  }, [groups.page]);

  useEffect(() => {
      if (groups.options.length !== groups.num_results){
          setGroups((prevState) => {
             return {
                 ...prevState,
                 page: prevState.page + 1
             }
          });
      }
  }, [groups.num_results, groups.options]);

  useEffect(() => {
    socket.on('client-removal-' + localStorage.getItem('provider_id'), checkCurrentClient);
    return () => {
      socket.off('client-removal-' + localStorage.getItem('provider_id'), checkCurrentClient);
    }
  }, [client]);

  const checkCurrentClient = ( message ) => {
    if (client && message.client_id === client.id) {
      setClient(null);
      setShowModal(true);
    }
  }

  useEffect(() => {
    socket.on('new-custom-tag-' + localStorage.getItem('provider_id'), newCustomTag);
    socket.on('delete-custom-tag-' + localStorage.getItem('provider_id'), deleteCustomTag);
    socket.on('update-custom-tag-' + localStorage.getItem('provider_id'), updateCustomTag)
    socket.on('custom-tag-csv-' + localStorage.getItem('provider_id'), customTagsCSV)
    return () => {
      socket.off('new-custom-tag-' + localStorage.getItem('provider_id'), newCustomTag);
      socket.off('delete-custom-tag-' + localStorage.getItem('provider_id'), deleteCustomTag);
      socket.off('update-custom-tag-' + localStorage.getItem('provider_id'), updateCustomTag)
      socket.off('custom-tag-csv-' + localStorage.getItem('provider_id'), customTagsCSV)
    }
  }, [customTags, clientCustomTags, client]);

  useEffect(() => {
    socket.on('new-group-' + localStorage.getItem('provider_id'), newGroup)
    socket.on('update-group-' + localStorage.getItem('provider_id'), updateGroup)
    socket.on('delete-group-' + localStorage.getItem('provider_id'), deleteGroup)
    return () => {
      socket.off('new-group-' + localStorage.getItem('provider_id'), newGroup)
      socket.off('update-group-' + localStorage.getItem('provider_id'), updateGroup)
      socket.off('delete-group-' + localStorage.getItem('provider_id'), deleteGroup)
    }
  }, [groups.options])

  const newCustomTag = (custom_tag) => {
    let tags = [...customTags];

    let tag = {
      'id': custom_tag.id,
      'name': custom_tag.name,
      'fixed': custom_tag.fixed,
      'color': custom_tag.color
    }

    tags.push(tag);
    tags.sort(function(a, b){
      return a.name.localeCompare(b.name);
    });

    setCustomTags(tags);
  }

  const deleteCustomTag = (custom_tag) => {
    let custom_tags = [...customTags];

    custom_tags = custom_tags.filter(x => x.id !== custom_tag.id);
    setCustomTags(custom_tags);
  }

  const updateCustomTag = ({custom_tag}) => {
    let custom_tags = [...customTags];

    let custom_tag_index = custom_tags.findIndex(x => x.id === custom_tag.id);

    if (custom_tag_index !== -1){
      custom_tags[custom_tag_index] = custom_tag;
      setCustomTags(custom_tags);
    }
  }

  const customTagsCSV = ({new_custom_tags}) => {
    if (new_custom_tags.length > 0){
      let tags = [...customTags];
      new_custom_tags.map(new_custom_tag => {
        tags.push(new_custom_tag)
      });
      tags.sort(function(a, b){
        return a.name.localeCompare(b.name);
      });
      setCustomTags(tags);
    }
  }

  const newGroup = (data) => {
    let notPrivateInbox = !company_context.privateInbox || ['admin', 'supervisor'].includes(switchRole());
    let notify_new_group = false
    if (notPrivateInbox){
      notify_new_group = true
    } else {
      if (data.users.filter(x => x.username === localStorage.getItem('username'))[0] !== undefined){
        notify_new_group = true
      }
    }
    if (notify_new_group){
      let old_groups = [...groups.options];
      let new_group = {
          'id': data.id,
          'name': data.name,
          'users': data.users
        }
        old_groups.push(new_group)
        // setGroups(old_groups)
        setGroups(prevState => {
          return {
            ...prevState,
            options: old_groups,
            num_results: prevState.num_results + 1
          }
        })
    }
  }

  const updateGroup = (data) => {
    let old_groups = [...groups.options];
    let group_index = old_groups.findIndex(x => x.id === data.id);

    let new_group = {
      'id': data.id,
      'name': data.name,
      'users': data.users
    }

    if (group_index !== -1){
      old_groups[group_index] = new_group;
      setGroups(prevState => {
          return {
            ...prevState,
            options: old_groups
          }
        })
      // setGroups(old_groups);
    } else {
      let notPrivateInbox = !company_context.privateInbox || ['admin', 'supervisor'].includes(switchRole());
      if (!notPrivateInbox){
        let notify_new_group = false;
        if (data.users.filter(x => x.username === localStorage.getItem('username'))[0] !== undefined){
          notify_new_group = true
        }
        if (notify_new_group){
          let old_groups = [...groups.options];
          old_groups.push(new_group)
          setGroups(prevState => {
            return {
              ...prevState,
              options: old_groups
            }
          })
        }
      }
    }
  }

  const deleteGroup = (data) => {
    let old_groups = [...groups.options];
    old_groups = old_groups.filter(x => x.id !== data.id);
    setGroups(prevState => {
      return {
        ...prevState,
        options: old_groups,
        num_results: prevState.num_results - 1
      }
    })
  }

  const updateClientCustomTags = (data) => {
    let client_custom_tags = [...clientCustomTags, ...data];
    setClientCustomTags(client_custom_tags);
  }

  const updateSelectedCustomTags = (data) => {
    if (data.length === 0){
      updateCloseModal(true);
    }
    setSelectedCustomTags(data);
  }

  const updateCloseModal = (data) => {
    setCloseModal(data)
  }

  if (loading){
    return <CustomSpinner/>
  }

  const messageList = client ? (<MessageList onSelectClientHandler={setClient} client={client} buttonsActivated={buttonsActivated}/>) : '';
  const warningModal = (
    <CustomModal show={true}>
      {( onClose ) => {
        return (
          <Fragment>
            <CustomModalHeader title={'Cliente Reasignado'} onClose={() => {
              setShowModal(false);
              onClose();
            }}/>
            <CustomModalBody>
              <div><p>El cliente ha sido reasignado</p></div>
            </CustomModalBody>
          </Fragment>
        );
      }}
    </CustomModal>
  );

  const Logo = (
    !(height < 950) && (
      <div className='image-center'>
        <img alt="logo" className='img-centro-vert-hor' src={`${process.env.REACT_APP_PAGE}/favicon.ico`}/>
      </div>
    )
  );

  return (
    <div className="messenger">
      <MessageContext.Provider
          value={{
            customTags: customTags,
            clientCustomTags: clientCustomTags,
            selectedCustomTags: selectedCustomTags,
            closeModal: closeModal,
            messageContext: messageContext,
            messageReaction: messageReaction,
            selectedMessageContext: selectedMessageContext,
            loadingMessages: loadingMessages,
            foundMessageContext: foundMessageContext,
            forward: forward,
            selectedForwardMessages: selectedForwardMessage,
            selectedForwardContact: selectedForwardContact,
            forwardContactModal: forwardContactModal,
            groups: groups.options,
            selectedPhoneContact: selectedPhoneContact,
            loadingSearchSelectedPhoneContact: loadingSearchSelectedPhoneContact,
            updateClientCustomTags: (e) => updateClientCustomTags(e),
            updateSelectedCustomTags: (e) => updateSelectedCustomTags(e),
            updateCloseModal: (e) => updateCloseModal(e),
            updateMessageContext: (e) => setMessageContext(e),
            updateMessageReaction: (e) => setMessageReaction(e),
            updateSelectedMessageContext: (e) => setSelectedMessageContext(e),
            updateLoadingMessages: (e) => setLoadingMessages(e),
            updateFoundMessageContext: (e) => setFoundMessageContext(e),
            updateForward: (e) => setForward(e),
            updateSelectedForwardMessages: (e) => setSelectedForwardMessage(e),
            updateSelectedForwardContact: (e) => setSelectedForwardContact(e),
            updateForwardContactModal: (e) => setForwardContactModal(e),
            updateSelectedPhoneContact: (e) => setSelectedPhoneContact(e),
            updateLoadingSearchSelectedPhoneContact: (e) => setLoadingSearchSelectedPhoneContact(e),

          }}>
          <MessageNoteContextProvider>
            {isMobile() && (
              client === null && (
                <div className="scrollable sidebar scrollable-change-color"
                     style={{width: isMobile() ? height - 30 : 'auto'}}
                     ref={scrolleableDiv}>
                     <ConversationList onSelectClientHandler={setClient}
                                       parentRef={scrolleableDiv}
                                       setButtonsActivated={setButtonsActivated} />
                </div>
              )
            )}
            {!isMobile() && (
              <div className="scrollable sidebar scrollable-change-color"
                   ref={scrolleableDiv}>
                   <ConversationList onSelectClientHandler={setClient}
                                  parentRef={scrolleableDiv}
                                  setButtonsActivated={setButtonsActivated} />
              </div>
            )}
            {(messageList) && (
              <div className="sidebar"
                   style={{width: isMobile() ? height - 30 : 'auto'}}>
                   {messageList}
              </div>
            )}
            {!messageList && (
              Logo
            )}
          </MessageNoteContextProvider>
      </MessageContext.Provider>
    </div>
  );
}