import React, { Component } from 'react';
import ResourceFormGeneric from '../../Forms/ResourceFormGeneric';
import {switchRole}  from '../../../shared/roles';
import {roles} from './clientConfig';

class ClientForm extends Component {

    state = {
        uri: '/debtors',
        resource_id: this.props.match.params.id,
        action: this.props.match.params.id ? 'edit' : 'create',
        title: (this.props.match.params.id ? 'Guardar' : 'Crear') + (
            this.props.match === '/debtor/:id/edit' ? ' Deudor' : ' Cliente'),
        fields: [
            {
                name: 'document_number', 
                elementType: 'text',
                elementConfig: {
                    keyfilter: 'pint'
                },
                value: '',
                traduction: 'Identificador'
            },
            {
                name: 'lastname',
                elementType: 'text',
                elementConfig: {
                },
                value: '',
                traduction: 'Apellido'

            },
            {
                name: 'firstname',
                elementType: 'text',
                elementConfig: {
                },
                value: '',
                traduction: 'Nombre'

            },
            {
                name: 'cuil',
                elementType: 'text',
                elementConfig: {
                    keyfilter: 'pint'
                },
                value: '',
                traduction: 'CUIL'

            },
            {
                name: 'notification_subscription',
                elementType: 'checkbox',
                value: false,
                traduction: 'Estado de notificaciones',
                elementConfig: {
                }
            },
            {
                name: 'assign',
                elementType: 'checkbox',
                value: localStorage.getItem('role') !== '9' ? true : false,
                traduction: 'Asignarmelo',
                elementConfig: {}
            },
            {
                name: 'contacts',
                elementType: 'FormList',
                elementConfig: {
                    childFields: [
                        {
                            name: 'contact_type_id',
                            elementType: 'dropdown',
                            elementConfig: {
                                options: null,
                                placeholder: 'Seleccione un Tipo de Contacto'
                            },
                            value: '',
                            traduction: 'Tipo de Contacto',
                            requestResource: {
                                request:{
                                    url: '/contact_types',
                                    params: {},
                                },
                                format: {
                                    value: 'id',
                                    label: 'name'
                                }
                            }
                        },
                        {
                            name: 'country_prefix',
                            elementType: 'hidden',
                            elementConfig: {
                            }
                        },
                        {
                            name: 'value',
                            elementType: 'contact',
                            elementConfig: {
                            },
                            dependence_of: [
                                'contact_type_id',
                                'country_prefix'
                            ],
                            traduction: 'Contacto'
                        },
                        {
                            name: 'id',
                            elementType: 'hidden',
                            elementConfig: {
                            }
                        }

                    ],
                    elements: 1
                },
                value: [],
                panelLabel: 'Contactos',
                panelChildren: "Nuevo Contacto"
            },
            {
                name: 'provider_id',
                elementType: 'dropdown',
                elementConfig: {
                    options: null,
                    placeholder: 'Seleccione un cliente'
                },
                value: '',
                traduction: 'Cliente',
                requestResource: {
                    request:{
                        url: '/clients',
                        params: {},
                        filters: [
                            {
                                name: "available",
                                op: "eq",
                                val: true
                            }
                        ],
                    },
                    format: {
                        value: 'id',
                        label: 'name'
                    }
                }
            }
        ],
        loading: false
    };



    setFields = (fields) => {
        this.setState({fields: fields})
    };

    render(){
        let {fields} = this.state;
        if (this.props.match.params.id) fields = fields.filter((item) => item.name !== 'assign')
        if(roles[switchRole()]){
            fields = fields.filter(field => {
                return !roles[switchRole()].exclude_fields.includes(field.name)
            })
        }

        const form = (<ResourceFormGeneric 
                            setFields={this.setFields}
                            fields={fields}
                            url={'/debtors'}
                            resource_id={this.state.resource_id}
                            resource_traduction={this.state.title}/>);

        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12 p-lg-12">
                    <div className="card card-w-title">
                        <h1>{this.state.title}</h1>
                        {!this.state.loading && form}
                    </div>
                </div>
            </div>
        );
    }
}

export default ClientForm;