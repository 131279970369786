import React, { Component } from 'react';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from "primereact/components/column/Column";
import axios from 'axios';
import MoneyField from '../UI/MoneyField';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class DebtorsRank extends Component {
    
    state = {
        loading: true,
        debtors: []
    };

    resource = {
        colsHeader: [
            { field: 'firstname', header: 'Nombre'},
            { field: 'lastname', header: 'Apellido'},
            { field: 'amount', header: 'Monto'},
            { field: 'days', header: 'Días de atraso'},
            { field: 'action', header: 'Acciones' }
        ],
        action: {
            show: '/debtor/',
            balance: '/loans/debtor/'
        },
    };


    componentDidMount(){
        axios.get('/statistics/rank_debtors')
                .then(response => {
                    this.setState({
                        loading: false,
                        debtors: response.data.map(data => {return {
                            id: data.id,
                            firstname: data.firstname,
                            lastname: data.lastname,
                            amount: (<MoneyField>{data.amount}</MoneyField>),
                            days: data.days
                        }})
                    });
                }).catch(error => {
                    this.setState({loading: false});
                    console.log('Se produjo un error. Intente nuevamente en unos minutos');
        });

    }

    actionTemplate = (rowData, column) => {
        return (
            <div>
                <Link to={this.resource.action.show + rowData.id}>
                    <FontAwesomeIcon icon={"search"} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome"}/>
                </Link>
                <Link to={this.resource.action.balance + rowData.id}>
                    <FontAwesomeIcon icon={"dollar-sign"} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome"}/>
                </Link>
            </div>
        );
    };


    render(){
        const header = <div className="p-clearfix p-text-center p-text-bold" style={{'lineHeight':'1.87em'}}>Principales Morosos</div>;
        const cols = this.resource.colsHeader.map(col => {
            if (col.field === 'action'){
                return <Column body={this.actionTemplate} field={col.field} header={col.header} key={col.field} style={{textAlign:'center'}}/>;
            } else {
                return (<Column key={col.field} field={col.field} header={col.header}/>);
            }
        });

        return (<React.Fragment>
                    <div className="p-col-12 p-md-8 p-lg-8 p-md-offset-2 p-lg-offset-2">
                        <DataTable value={this.state.debtors.slice(0,10)} responsive={true} header={header}>
                            {cols}
                        </DataTable>
                    </div>
                </React.Fragment>);
    }

}



export default DebtorsRank;
