import React, {Fragment, useState, useMemo, useRef, useContext, useEffect} from 'react';
import ReactTooltip from 'react-tooltip';
import { ContextMenu } from 'primereact/contextmenu';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {markAsSeen, markAsArchived, toggleAssign, markAsPinup, markAsArchivedAndClean} from "../../../utils/messenger";
import { CompanyContext } from '../../../context/companyContext';
import Tag from '../../../UI/Tag';
import moment from 'moment';
import './ConversationListItem.css';

import ConversationPreview from '../ConversationPreview';
import jwtDecode from "jwt-decode";

import Avatar from 'react-avatar';
import { removeNumbersAndSpecialCharacters } from '../../../utils/utils';

export default function ConversationListItem(props) {
    const [toggleMoreTags, setToggleMoreTags] = useState(false);
    const refMenu = useRef(null);
    const refMenuClean = useRef(null);
    const [showOptions, setShowOptions] = useState(false);
    const {name, text, client, mimetype, message_type , sent, read, delivered, type, created, created_timezone, id} = props.data;
    const time = moment(created_timezone).format('HH:mm');
    const date = moment(created_timezone).format('DD/MM/YY');
    const fulltime = time.concat('hs').concat(' - ').concat(date);
    const company_context = useContext(CompanyContext);

    const isMobile = () => {
        return window.innerWidth <= 750;
    };

    const hideMenuClean = (e) => {
        refMenuClean.current.show(e);
        refMenu.current.hide(e);
    }

    const markCurrentUser = (users) => {
        const token = localStorage.getItem('token');
        if (token) {
          const decodedJwt = jwtDecode(token);
          users.forEach(user => {
            if (user.id === decodedJwt.user_claims.user_id && !user.username.includes("(Tú)")) {
              user.username = user.username + " (Tú)";
            }
          });
        }
        return users;
    }

    const userTag = client.conversation && (
        <Tag>
            <FontAwesomeIcon icon={'user'}></FontAwesomeIcon>
            {' ' + markCurrentUser([client.conversation.user])[0].username}
        </Tag>
    );

    const InitialCustomTags = () => {

        let tags = useMemo(() => {
            return client.tags.sort(function (a, b){
                return a.name.length - b.name.length;
            });
        }, [client.tags, client.id]);

        let firstRowTags = tags.slice(0, 1);

        const first_tag = firstRowTags.map((tag, index) => {

                let add_title = tag.name;
                let tag_color = tag.color === '' ? 'green' : tag.color;

                return (
                    <Fragment key={index}>
                        <span className="custom-tags" data-tip={add_title}
                          style={{
                              marginTop: '0.4em',
                              backgroundColor: tag_color
                          }}><FontAwesomeIcon icon={'tag'}></FontAwesomeIcon>{' ' + tag.name}
                        </span>
                        <ReactTooltip place="right" effect="solid"/>
                    </Fragment>
                );
        });


        let secondRowTags = tags.slice(1, 2);

        const second_tag = secondRowTags.map((tag, index) => {
                let add_title = tag.name;
                let tag_color = tag.color === '' ? 'green' : tag.color;
                return (
                    <Fragment key={index}>
                        <span className="custom-tags" data-tip={add_title}
                          style={{
                            backgroundColor: tag_color,
                            marginBottom: '0.4em',
                          }}><FontAwesomeIcon icon={'tag'}></FontAwesomeIcon>{' ' + tag.name}
                        </span>
                        <ReactTooltip place="right" effect="solid"/>
                    </Fragment>
                );
        });

        const group_tag = client.conversation?.group && (<Tag defaultStyles={{backgroundColor: 'green'}} ><FontAwesomeIcon icon={'people-group'}></FontAwesomeIcon>{' ' + client.conversation.group.name}</Tag>);

        return (
            <div style={{display: 'grid'}}>
                <div style={{display: 'flex'}}>
                {group_tag && (<div style={{marginTop: '10px', marginRight: '0.5em', padding: '0px 0px 1.8px'}}> {group_tag} </div>)}
                <div style={{marginTop: '10px', padding: '0px 0px 1.8px'}}> {userTag} </div>
                {client.pinup && (
                    !(company_context.privateInbox && props.supervisor) && (
                        <div style={{position: 'absolute', right: '10px'}}> <FontAwesomeIcon icon={'thumbtack'}></FontAwesomeIcon> </div>
                    )
                )}
                </div>
                <div> {first_tag} </div>
                <div> {second_tag} </div>
            </div>
        );
    }

    const MoreTags = () => {
        let form = null;

        let tags = useMemo(() => {
            return client.tags.sort(function(a, b){
                return a.name.length - b.name.length;
            });
        }, [client.tags, client.id]);

        let client_custom_tags = [...tags]

        if (toggleMoreTags){
            client_custom_tags = client_custom_tags.slice(2);

            let client_tags = client_custom_tags.map((tag,index) => {
                let add_title = tag.name.length > 35 ? tag.name : '';
                let tag_color = tag.color === '' ? 'green' : tag.color;

                return (
                    <Fragment key={index}>
                        <span className="custom-tags p-col-12 custom-tags-span"
                          style={{
                            backgroundColor: tag_color
                          }} data-tip={add_title}><FontAwesomeIcon icon={'tag'}></FontAwesomeIcon>{' ' + tag.name}
                        </span>
                        <ReactTooltip place="right" effect="solid" />
                    </Fragment>
                );

            });

            form = (
                <div className="p-grid" style={{marginLeft: '0.1em'}}>
                    {client_tags}
                </div>
            );
        }
        return form;
    }

    const Fulltime = () => {
        return (
            id && (
                <div>
                    <div className='full-time' style={{color: (client.unread_messages === true ? "#77b8ff" : '#888')}}>
                        {fulltime}
                    </div>
                </div>
            )
        )
    }

    const clientHasMoreTags = () => {
        let form = null;
        let tags = client.tags;
        let total_tags = tags.length;

        if (total_tags > 2){
            let icon = toggleMoreTags ? 'pi pi-angle-double-up' : 'pi pi-angle-double-down';
            form = (
                <div className="more-tags">
                        <span onClick={(e) => setToggleMoreTags(!toggleMoreTags)}>
                            <i className={icon}></i>
                        </span>
                </div>
            );
        }
        return form;
    }


    const formatBold = (body) => {
        return <b>{body.replace(/\*(.*?)\*/g, "$1")}</b>
    }
    const formatItalic = (body) => {
        return <i>{body.replace(/_(.*?)_/g, "$1")}</i>
    }

    const formatBody = (body) => {
        if(body.match(/\*(.*?)\*/g)){
            body = formatBold(body)
        }
        else if (body.match(/_(.*?)_/g)){
            body = formatItalic(body)
        }
        return body
    }

    const showIconOptions = (isMobile() ? true : showOptions) ? (
        <Fragment>
            <div className="overlay-button">
                <button className="p-link" onClick={(e) => refMenu.current.show(e)}>
                    <FontAwesomeIcon icon={"chevron-down"} color={'grey'} size={"xs"}/>
                </button>
            </div>
        </Fragment>
    ): null;

    const actionUnread = (
        <div>
            {name}
            {props.selectedTab !== 2 && (
                <span style={{backgroundColor: isMobile() ? 'white' : 'transparent'}} className="badges p-badge p-badge-info right">
                    {showIconOptions}
                </span>
            )}
            <div>
                <span style={{marginTop: '0.5em'}} className="badges p-badge p-badge-info right">
                    {client.counter_unread_messages == null ? 1 : client.counter_unread_messages === 0 ? 1 : client.counter_unread_messages}
                </span>
            </div>
        </div>
    )

    const actionRead = (
        <div>
            {name}
            {props.selectedTab !== 2 && (
                <span style={{backgroundColor: isMobile() ? 'white' : 'transparent'}} className="badges p-badge p-badge-info right">
                    {showIconOptions}
                </span>
            )}
        </div>
    )

    const active_element = props.conversationActive ? 'active' : '';

    const initial_tags = InitialCustomTags();
    const more_tags = MoreTags();
    const full_time = Fulltime();

    const token = localStorage.getItem('token');
    const decoded_jwt = jwtDecode(token);

    let itemsClean = [
        {
           label:`Limpiar sólo operador`,
           command:()=>{ markAsArchivedAndClean(true, true, false, client.id); }
        },
        {
           label:`Limpiar operador y etiquetas`,
           command:()=>{ markAsArchivedAndClean(true, true, true, client.id); }
        },
    ];

    let items = [
        {
           label:`Marcar como ${client.unread_messages ? 'leído' : 'no leído'}`,
           command:()=>{ markAsSeen(client.unread_messages ? true : false, client.id); }
        },
        {
           label:`Marcar como ${client.archived ? 'no archivado' : 'archivado'}`,
           command:()=>{ markAsArchived(client.archived ? false : true, client.id); }
        },
        {
           label: <div onClick={hideMenuClean}> {`Marcar como archivado y limpiar`} </div>,
        },
        {
           label:`Marcar como ${client.pinup ? 'no fijado' : 'fijado'}`,
           command:()=>{ markAsPinup(client.pinup ? false : true, client.id); }
        },
     ];

    if (company_context.privateInbox && props.supervisor) {
        items = [items[0],items[1],items[2]]
    }

    if (client.archived) {
        items = [items[1]]
    }

    if (client.conversation !== undefined){
        items.push(
            {
                label: client.conversation === null ? 'Asignar' : 'Desasignar',
                command:()=>{ toggleAssign(client.id, decoded_jwt.user_claims.user_id, true); }
            }
        )
    }

    const contextMenu = (<ContextMenu model={items} ref={refMenu}/>);
    const contextMenuClean = (<ContextMenu model={itemsClean} ref={refMenuClean}/>);

    return (
        <Fragment>
            <div className={`conversation-list-item ${active_element}`} onClick={props.onClick} onContextMenu={(e) => refMenu.current.show(e)} onMouseEnter={() => setShowOptions(true)} onMouseLeave={() => setShowOptions(false)}>
                {props.selectedTab !== 2 && (
                    <div style={{position: 'absolute', marginLeft:  !isMobile() ? '240px' : '' , marginBottom: '80px'}}>
                        {contextMenu}
                        {contextMenuClean}
                    </div>
                )}
                <Avatar maxInitials={1} className="conversation-photo" name={removeNumbersAndSpecialCharacters(name)} round={true} size="60"/>
                <div>
                    {clientHasMoreTags()}
                </div>
                <div className="conversation-info">
                    <h1 className="conversation-title"> {client.unread_messages ? actionUnread : actionRead} </h1>
                    <div className="conversation-snippet" style={{fontWeight: (client.unread_messages === true &&  "bold"), color: (client.unread_messages === true && "#77b8ff")}}>
                        <ConversationPreview sent={sent} read={read} type={type} delivered={delivered} mimetype={mimetype} data={formatBody(text)} unread={client.unread_messages}
                                             messageType={message_type}/>
                    </div>
                        {full_time}
                        {initial_tags}
                        {more_tags}
                </div>
            </div>
        </Fragment>
    );
}