import React from 'react'
import Resource from '../../Resources/Resource'
import * as roles from "../../../shared/roles";

const audienceView = (props) => {

    let title = 'Audiencia';

    let role = roles.switchRole();

    if (role === 'payment_order_creator' || role === 'texter' || process.env.REACT_APP_PAGE === 'pip-app'){
        title = 'Clientes'
    }

    const resource = {
        title: title,
        audience_id: props.match.params.id,
        url: '/audiences/',
        fields: [
            { name:'lastname', traduction:'Apellido'},
            { name:'firstname', traduction:'Nombre'},
            { name:'document_number',traduction:'Identificador'},
            { name:'cuil',traduction:'CUIL'},
            // {
            //     name:'contacts',
            //     traduction:'Contactos',
            //     card: { title: 'Contactos' },
            //     component:{
            //         name: 'ContactList',
            //         props:{
            //             client_id: props.match.params.id
            //         }
            //     }
            // },
            {
                card: { title: 'Tags' },
                name: 'custom_tags',
                traduction: 'Tags',
                component:{
                    name: 'CustomTagsList',
                    props: {
                        client_id: props.match.params.id
                    }
                }
            },
        ]
    }

    const filtered_fields = resource.fields.filter((field) => {
        if(field.name === 'custom_tags' && role === 'texter'){
            return false;
        }
        return true;
    })

    return (
        <div>
            <Resource title={resource.title} url={resource.url} resource_id={resource.audience_id} fields={filtered_fields} />
        </div>
    );

}

export default audienceView;