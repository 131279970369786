import React, {Component, Fragment} from 'react';

import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';

import '../../../assets/css/NotificationHelper.css';

class NotificationHelper extends Component {

    state = {
        visible: false
    };

    render(){
        let interoperationList = null;
        let prontocobro_fields = null;

        if (this.props.interoperation){
            interoperationList = (
                <Fragment>
                    <b>{"{DebtorTotalDebt}"}:</b> Total de deuda<br/>
                    <b>{"{DebtorTotalPaid}"}:</b> Total pagado<br/>
                    <b>{"{DebtorExpiredDebt}"}:</b> Total deuda vencida<br/>
                </Fragment>
            );
        } else {
            if (process.env.REACT_APP_PAGE === 'prontocobro'){
                interoperationList = (
                    <Fragment>
                        <b>{"{OperationDetail}"}:</b> Detalle de la operación<br/>
                        <b>{"{LoanAmount}"}:</b> Monto de la operación<br/>
                        <b>{"{LoanDebt}"}:</b> Deuda Vencida de la operación<br/>
                        <b>{"{LoanInterest}"}:</b> Interés de la operación<br/>
                        <b>{"{InstallmentDueDate}"}:</b> Fecha de la cuota<br/>
                        <b>{"{InstallmentNumber}"}:</b> Nº de cuota<br/>
                        <b>{"{InstallmentAmount}"}:</b> Monto de la cuota<br/>
                    </Fragment>
                );

                prontocobro_fields = (
                    <Fragment>
                        <b>{"{ClientUrl}"}:</b> Url personalizada de la empresa<br/>
                        <b>{"{ClientShortUrl}"}:</b> Url a la página con el detalle de la deuda<br/>
                        <b>{"{ClientShortUrlAlt}"}:</b> Reemplazar Alt por el código de dominio alternativo (ej. AR)<br/>
                        <b>{"{ClientTotalAmount}"}:</b> Total deuda inicial<br/>
                        <b>{"{LoanCbu}"}:</b> Cbu del deudor<br/>
                        <b>{"{LoanBank}"}:</b> Banco del deudor<br/>
                        <b>{"{ProviderName}"}:</b> Nombre de la empresa<br/>
                        <b>{"{ProviderAddress}"}:</b>  Dirección de la empresa<br/>
                        <b>{"{ProviderEmail}"}:</b> Email de la empresa<br/>
                        <b>{"{ProviderPhone}"}:</b> Teléfono de la empresa<br/>
                        <b>{"{ProviderCbu}"}:</b> Cbu de la empresa<br/>
                        <b>{"{ProviderCuit}"}:</b> Cuit de la empresa<br/>
                    </Fragment>
                );
            }
        }

        const varList = (<Fragment>
                                <div className='p-grid'>
                                    <div className='p-col-12'>
                                        <h3>Variables para utilizar</h3>
                                    </div>
                                </div>
                                <div className='p-grid'>
                                    <div className='p-col-12 notification-helper'>
                                        <b>{"{ClientName}"}:</b> Nombre<br/>
                                        <b>{"{ClientLastname}"}:</b> Apellido<br/>
                                        <b>{"{SanitizedClientName}"}:</b> Nombre sanitizado<br/>
                                        <b>{"{SanitizedClientLastname}"}:</b> Apellido sanitizado<br/>
                                        <b>{"{ClientDocumentNumber}"}:</b> Nº Doc<br/>
                                        <b>{"{ClientCuil}"}:</b> Cuil<br/>
                                        <b>{"{Variable1}"}:</b> Información extra 1<br/>
                                        <b>{"{Variable2}"}:</b> Información extra 2<br/>
                                        <b>{"{Variable3}"}:</b> Información extra 3<br/>
                                        {prontocobro_fields}

                                        {interoperationList}
                                    </div>
                                </div>
                            </Fragment>);

        const mobileVarList = (<Fragment>
                                    <Sidebar visible={this.state.visible} onHide={(e) => this.setState({visible:false})}>
                                        {varList}
                                    </Sidebar>

                                    <Button className="p-button-rounded" icon="pi pi-question-circle" onClick={(e) => this.setState({visible:true})}/>
                                </Fragment>);

        return (<Fragment>
                    {window.innerWidth < 768 ? mobileVarList : varList}
                </Fragment>);

    }

}

export default NotificationHelper