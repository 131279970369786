import React from 'react';

import '../../assets/css/Attribute.css'
import ButtonEditInline from "../../UI/ButtonEditInline";

const Attribute = (props) =>  {
    let form = (
        <div className={props.classNameValue || "p-col-12 p-md-10"}>
            {props.children}
        </div>
    );

    if (props.edit){
        form = (
            <ButtonEditInline value={props.children} formData={props.formData} classNameValue={props.classNameValue}
                              elementConfig={props.elementConfig} sumaryToast={props.sumaryToast}/>
        );
    }
    return (
        <React.Fragment key={props.name} >
            <div className={(props.classNameLabel || "p-col-12 p-md-2") + " attr-label"}>
                <label htmlFor="input">{props.traduction}</label>
            </div>
            {form}
        </React.Fragment>
    );
}

export default Attribute;