import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { Chart } from "primereact/chart";

class OperationsByDefaultStatuses extends Component {

	state = {
		labels: null,
		datasets: null,
		loading: true
	}

	colors = ['#EC407A',
              '#AB47BC',
	          '#42A5F5',
	          '#7E57C2',
	          '#66BB6A',
	          '#FFCA28',
	          '#26A69A'];

	componentDidMount(){
		axios.get('/statistics/operations_by_default_statuses')
                .then(response => {
                	const labels = response.data.map(data => data.default_status);
                    let amountOfOperations = response.data
                                                     .map(operationsByDs => operationsByDs.amount)
                                                     .reduce((totalAmount, amount)=>{
                                                        return totalAmount + amount
                                                      });

                	const datasets = response.data.map((data, index) => {
                		return {label: data.default_status + ' (' + ((data.amount/amountOfOperations)*100).toFixed(2) + '%)' ,
			    				backgroundColor: this.colors[index],
			                	data: [data.amount]}
                	});
                    this.setState({
                        labels: labels,
                        datasets: datasets,
                        loading: false
                    });
                }).catch(error => {
                    this.setState({loading: false});
                    console.log('Se produjo un error. Intente nuevamente en unos minutos');
                });
	}

    render() {
		const data = this.state.loading ? null : this.state;

        return (
			<Fragment>
	            <div className="p-col-12 p-md-8 p-lg-8 p-md-offset-2 p-lg-offset-2">
	            	<Chart type="bar" data={data}/>
	        	</div>
			</Fragment>
        );
    }
}

export default OperationsByDefaultStatuses;