import React, {useState, useEffect} from 'react';
import DialogGeneric from "../../UI/DialogGeneric";
import {Card} from "primereact/card";
import ApiLoan from "../../service/ApiLoanService";
import '../../assets/css/TemplateWhatsapp.css';


const TemplateWhatsapp = (props) => {

    const [templateValue, setTemplateValue] = useState({
        value: props.value,
        template_uuid: '',
        title: ''
    });

    const [notificationTags, setNotificationTags] = useState({
        search: false,
        tags: [],
        loading: true,
        count: 0
    });

    useEffect(() => {
        if (props.value){
            setTemplateValue({
                value: props.value,
                title: ''
            });
        }
    }, []);

    useEffect(() => {
        if (props.channel_id !== 7){
            setTemplateValue({
                value: '',
                title: ''
            });

            props.onChange({'body': '', 'template_uuid': '', 'template_title': '' })
        }
    }, [props.channel_id])


    useEffect(() => {
        if (props.value !== templateValue.value){
            setTemplateValue((prevState) => {
                return {
                    ...prevState,
                    value: templateValue.value
                }
            });
            props.onChange({'body': templateValue.value, 'template_uuid': templateValue.template_uuid, 'template_title': templateValue.title })
        }
    }, [props.value, templateValue.value]);

    useEffect(() => {
        if (templateValue.value !== ''){
            if (props.tags.custom_tag_id.length !== 0){
                setNotificationTags((prevState) => {
                    return {
                        ...prevState,
                        search: true,
                        tags: props.tags.custom_tag_id,
                        loading: true
                    }
                });
            } else {
                setNotificationTags((prevState) => {
                   return {
                       ...prevState,
                       search: false,
                       tags: [],
                       loading: false
                   }
                });
            }
        }
    }, [props.tags, templateValue.value]);

    useEffect(() => {
        if (notificationTags.search){
            const apiService = new ApiLoan();

            let formData = {
                custom_tags: notificationTags.tags
            }

            apiService.postResource({
                url: '/whatsapp/messages/notification',
                data: formData
            }).then(response => {
                setNotificationTags((prevState) => {
                    return {
                        ...prevState,
                        loading: false,
                        count: response.data.count,
                        search: false
                    }
                });
            }).catch(error => {
                setNotificationTags((prevState) => {
                    return {
                        ...prevState,
                        loading: false,
                        count: 0,
                        search: false
                    }
                });
            });
        }
    }, [notificationTags]);


    let value_dialog = {
        'url': '',
        'method': 'get',
        'header': 'Plantillas Guardadas',
        'show_footer': false,
        'submit_label': 'Crear',
        'sumaryToast': 'Etiquetas',
        'fields': [
            {
                name: 'notification_whatsapp',
                elementType: 'notificationWhatsappBody',
                elementConfig: {
                    onClose: props.setActive,
                    updateTemplateValue: setTemplateValue,
                    filter: [{ name: "whatsapp_template", op: "eq", val: true }]
                },
                value: templateValue,
                elementClass: 'p-col-12'
            }
        ],
        'buttons_rounded': true
    }

    const getNotificationWhatsappInfo = () => {
        let className = `${notificationTags.count > 500 ? 'notification-info-error' : 'notification-info-success'}`

        return (
            <Card title='Información sobre envio'>
                <p>
                    Tags seleccionados: {notificationTags.tags.length}
                </p>
                <p>
                    Cantidad de clientes vinculados: {notificationTags.count}
                </p>
                <p className={className}>
                    El tags seleccionado en este momento supera el limite de envios por fecha (500)
                </p>
            </Card>
        );
    }

    if (props.active){
        return <DialogGeneric visible={props.active} show_footer={false} setVisible={props.setActive} closeModal={true} {...value_dialog}/>;
    } else {
        if (notificationTags.tags.length > 0 && !notificationTags.loading){
            return getNotificationWhatsappInfo()
        } else {
            return null
        }
    }
}

export default TemplateWhatsapp