import React from 'react';
import Resources from '../Resources/Resources';

const ProviderScheduleds = () => {

    const resource = {
        title: 'Configuración de Mensajes Programados',
        url: '/provider_scheduled',
        colsHeader: [
            { field: 'method', header: 'Método' },
            { field: 'url', header: 'URL'},
            { field: 'body', header: 'JSON', truncate: false},
            { field: 'active', header: 'Activo', checkbox: true},
            { field: 'provider_scheduled_action.description', header: 'Acción'},
            { field: 'authorization', header: 'Autenticacion'},
            { field: 'frecuency', header: 'Próxima Frecuencia', cron: true},
            { field: 'count', header: 'Cantidad'},
            { field: 'provider_scheduled_period.description', header: 'Periodo'},
            { field: 'action', header: 'Acciones' }
        ],
        action: {
            add: '/new',
            show: '/provider_scheduled/',
            edit: '/edit',
            delete: true
        },
        urlTable: [
            '/provider_scheduleds',
            '/provider_scheduled/new',
            '^\\/provider_scheduled\\/\\d+$',
            '^\\/provider_scheduled\\/\\d+\\/edit$',
        ]
    }

    return (<Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader} action={resource.action}
                       customTableHeaderButtons={resource.customTableHeaderButtons} customActions={resource.customActions}
                       urlTable={resource.urlTable} />);
}

export default ProviderScheduleds;