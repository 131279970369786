import React, {useState, useEffect, useRef, Fragment} from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import {Checkbox} from 'primereact/checkbox';
import ApiLoan from "../../service/ApiLoanService";
import ReactJson from 'react-json-view'
import {Toast} from "primereact/toast";
import { Calendar } from 'primereact/calendar';
import { convertFromCron, convertToCronFormat } from '../../utils/utils';

const ProviderScheduledForm = (props) => {
    const [action, setAction] = useState('create');
    const [method, setMethod] = useState(null);
    const [urlPage, setUrlPage] = useState('');
    const [body, setBody] = useState({});
    const [active, setActive] = useState(false);
    const [count, setCount] = useState('');
    const [providerScheduledAction, setproviderScheduledAction] = useState(null);
    const [providerScheduledPeriod, setproviderScheduledPeriod] = useState(null);
    const [providerScheduledActionOptions, setproviderScheduledActionOptions] = useState(null);
    const [providerScheduledPeriodOptions, setproviderScheduledPeriodOptions] = useState(null);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [authorization, setAuthorization] = useState({
        authorization_type: null,
        value: ''
    });
    const [selectAction, setSelectAction] = useState(null);
    const [errorRangeTime, setErrorRangeTime] = useState(false);
    const [time, setTime] = useState('');

    const authorizationOptions = [
        { value: 'Basic', label: 'Basic' },
        { value: 'Bearer', label: 'Bearer' }
    ];

    useEffect(() => {
        let apiService = new ApiLoan();

        apiService.getResources({
            url: '/provider_scheduled_actions'
        }).then(response => {
            let options = [];
            let data_objects = response.data.objects;

            data_objects.map(x => {
                return options.push({
                    'value': x.id,
                    'label': x.description
                });
            });
            setproviderScheduledActionOptions(options);

        }).catch(error => {
            props.history.push('/')
        });
        return () => {
            setproviderScheduledActionOptions(null);
        };
    }, []);

    useEffect(() => {
        let apiService = new ApiLoan();

        apiService.getResources({
            url: '/provider_scheduled_periods'
        }).then(response => {
            let options = [];
            let data_objects = response.data.objects;

            data_objects.map(x => {
                return options.push({
                    'value': x.id,
                    'label': x.name + ' (' + x.description + ')'
                });
            });
            setproviderScheduledPeriodOptions(options);

        }).catch(error => {
            props.history.push('/')
        });
        return () => {
            setproviderScheduledPeriodOptions(null);
        };
    }, []);

    useEffect(() => {
        let apiService = new ApiLoan();
        let edit = props.match.params.id !== undefined;
        if (edit){
            apiService.getResource({
                url: '/provider_scheduled/',
                resource_id: props.match.params.id
            }).then(response => {
                const data = response.data;
                setMethod(data.method);
                setUrlPage(data.url);
                setproviderScheduledAction(data.provider_scheduled_action_id);
                setActive(data.active);
                setBody(JSON.parse(data.body))
                if (data.authorization) {
                    setAuthorization(JSON.parse(data.authorization))
                } else {
                    setAuthorization({
                        authorization_type: null,
                        value: ''
                    })
                }
                setTime(convertFromCron(data.frecuency));
                setproviderScheduledPeriod(data.provider_scheduled_period_id)
                setCount(data.count)
            });
            setAction('edit');
        } else {
            apiService.getResource({
                url: '/provider_scheduled',
            }).then(response => {
                if (response.data.objects.length === 1){
                    refToast.current.show({severity: 'error', summary: 'Scheduleds', detail: 'Ya existe una configuración del Mensaje Programado.'});
                    props.history.push('/provider_scheduled')
                }
            });
        }
    }, []);

    useEffect(() => {
        let timeRange = findLabelByValue(providerScheduledPeriodOptions, providerScheduledPeriod);
        if (providerScheduledPeriod && timeRange) setErrorRangeTime(compareHours(time, timeRange));
    },[time, providerScheduledPeriod])

    useEffect(() => {
        if (method !== null && urlPage !== '' && body !== null && providerScheduledAction !== null){
            setSubmitDisabled(false);
        } else {
            if (submitDisabled === false){
                setSubmitDisabled(true)
            }
        }
    }, [method, urlPage, body, providerScheduledAction]);

    const methodOptions = [
        { value: 'POST', label: 'POST' },
        { value: 'PATCH', label: 'PATCH' }
    ];

    useEffect(()=>{
        setSelectAction(findLabelByValue(providerScheduledActionOptions, providerScheduledAction))
    },[providerScheduledAction, providerScheduledActionOptions])

    const editJson = (e) => {
        setBody(e.updated_src);
    }

    const deleteObjectJson = (e) => {
        setBody(e.updated_src);
    }

    function findLabelByValue(list, targetValue) {
        if (list?.length === undefined) return null;
        for (let i = 0; i < list.length; i++) {
            if (list[i].value === targetValue) {
                return list[i].label;
            }
        }
        return null;
    }

    function compareHours(dateString, timeRange) {
        let date = new Date(dateString);
        let hourOfDay = date.getHours();
        let minutesOfDay = date.getMinutes();
        let match = timeRange.match(/(\d{2}):(\d{2}) - (\d{2}):(\d{2})/);
        let startHour = parseInt(match[1]);
        let startMinute = parseInt(match[2]);
        let endHour = parseInt(match[3]);
        let endMinute = parseInt(match[4]);

        if (endHour < startHour || (endHour === startHour && endMinute < startMinute)) {
            return (hourOfDay >= startHour || hourOfDay < endHour) ||
                   (hourOfDay === endHour && minutesOfDay <= endMinute);
        } else {
            return (hourOfDay > startHour || (hourOfDay === startHour && minutesOfDay >= startMinute)) &&
                   (hourOfDay < endHour || (hourOfDay === endHour && minutesOfDay <= endMinute));
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (errorRangeTime) {
            refToast.current.show({severity: 'error', summary: 'Scheduleds',
                detail: 'Verifique los datos del formulario.'});
            return
        }

        const apiService = new ApiLoan();

        const formData = {
            'method': method,
            'url': urlPage,
            'provider_scheduled_action_id': providerScheduledAction,
            'provider_scheduled_period_id': providerScheduledPeriod,
            'active': active,
            'body': body,
            'authorization': authorization,
            'frecuency': convertToCronFormat(time)
        }

        let api_request = null;
        let url = 'provider_scheduled';
        if (action === 'create'){
            api_request = apiService.postResource;
        } else {
            api_request = apiService.patchResource;
            url = url + '/' + props.match.params.id
        }


        api_request({
            'url': url,
            'data': formData
        }).then(response => {
            refToast.current.show({severity: 'success', summary: 'Scheduleds', detail: 'Se creó correctamente.'});
            props.history.push('/provider_scheduled')
        }).catch(error => {
            let error_message = error.response.data.message;
            if (typeof(error.response.data.message) === 'object'){
                error_message =  'Verifique los datos del formulario.'
            }
            refToast.current.show({severity: 'error', summary: 'Scheduleds',
                detail: error_message});
        });
    }

    const handleAuthorizationType = (value) => {
        let _value = value === 'Basic' ? {user: '', password: ''} : '';
        setAuthorization((prevState) => {
            return {
                ...prevState,
                authorization_type: value,
                value: _value
            }
        });
    }

    const handleAuthorizationValue = (authorization_type, field, value) => {
        let _authorization = {...authorization.value}
        if (authorization_type === 'Basic'){
            if (field === 'user'){
                _authorization.user = value;
            } else {
                _authorization.password = value;
            }
        } else if (authorization_type === 'Bearer'){
            _authorization = value;
        } else {
            _authorization = null;
        }

        setAuthorization((prevState) => {

            return {
                ...prevState,
                value: _authorization
            }
        });
    }

    const getAuthorizationBasic = () => {
        return (
            <Fragment>
                <div className="p-field p-col">
                    <span className="p-float-label">
                        <InputText id="inputtext" value={authorization.value.user}
                                           onChange={(e) => handleAuthorizationValue('Basic', 'user', e.target.value)} />
                        <label htmlFor="inputtext">User</label>
                    </span>
                </div>
                <div className="p-field p-col">
                    <span className="p-float-label">
                        <InputText id="inputtext" value={authorization.value.password}
                                           onChange={(e) => handleAuthorizationValue('Basic', 'password', e.target.value)} />
                        <label htmlFor="dropdown">Password</label>
                    </span>
                </div>
            </Fragment>
        )
    }

    const getAuthorizationBearer = () => {
        return (
            <div className="p-field p-col">
                <span className="p-float-label">
                    <InputText id="inputtext" value={authorization.value}
                                       onChange={(e) => handleAuthorizationValue('Bearer', null, e.target.value)} />
                    <label htmlFor="dropdown">Token</label>
                </span>
            </div>
        )
    }

    const getAuthorizationForm = () => {
        let form = null;
        if (authorization.authorization_type === 'Basic') {
            form = getAuthorizationBasic();
        } else if (authorization.authorization_type === 'Bearer'){
            form = getAuthorizationBearer();
        }
        return form;
    }

    const getFieldError = (errors, description) => {
        let form = null;
        if (errors){
            form = <small style={{color:'#f44336'}} className="p-error"> {description} </small>
        }
        return form;
    }

    const refToast = useRef(null);
    const labelSubmit = props.match.params.id !== undefined ? 'Guardar' : 'Crear';
    let form = (
        <div className="p-grid">
            <div className="p-col-12 p-md-12">
                <div className="card p-fluid">
                    <h5>Mensajes Programados</h5>
                    <Toast ref={refToast}/>
                    <div className="p-formgrid p-grid">
                        <div className="p-field p-col">
                            <span className="p-float-label">
                                <Dropdown id="dropdown" value={method} options={methodOptions} onChange={(e) => setMethod(e.value)}/>
                                <label htmlFor="dropdown">Método</label>
                            </span>
                        </div>
                        <div className="p-field p-col">
                            <span className="p-float-label">
                                <InputText id="inputtext" value={urlPage} onChange={(e) => setUrlPage(e.target.value)} />
                                <label htmlFor="inputtext">Url</label>
                            </span>
                        </div>
                        <div className="p-field p-col">
                            <span className="p-float-label">
                                <Dropdown value={providerScheduledAction} options={providerScheduledActionOptions} onChange={(e) => setproviderScheduledAction(e.value)}/>
                                <label>Acción</label>
                            </span>
                        </div>
                        {selectAction !== "Mr Turno" && (
                            <div className="p-field p-col">
                                <span className="p-float-label">
                                    <Dropdown id="dropdown_auth" value={authorization?.authorization_type}
                                            options={authorizationOptions} showClear
                                              optionLabel="label" optionValue="value"
                                              onChange={(e) => handleAuthorizationType(e.value)}/>
                                    <label htmlFor="dropdown_auth">Authorization</label>
                                </span>
                            </div>
                        )}
                        {getAuthorizationForm()}
                        <div className="p-field p-col">
                            <span className="p-float-label">
                                <Calendar className={errorRangeTime && "p-invalid"} value={time} onChange={(e) => setTime(e.value)} timeOnly />
                                <label>Frecuencia</label>
                            </span>
                            {getFieldError(errorRangeTime, 'Frecuencia Invalida')}
                        </div>
                        <div className="p-field p-col">
                            <span className="p-float-label">
                                <Dropdown value={providerScheduledPeriod} options={providerScheduledPeriodOptions} onChange={(e) => setproviderScheduledPeriod(e.value)}/>
                                <label>Periodo</label>
                            </span>
                        </div>
                        {props.match.params.id !== undefined && (
                            <div className="p-field p-col">
                                <span className="p-float-label">
                                    <InputText disabled={true} id="inputtext" value={count} onChange={(e) => setCount(e.target.value)} />
                                    <label htmlFor="inputtext">Cantidad</label>
                                </span>
                            </div>
                        )}
                        <div className="p-field p-col">
                            <span className="p-float-label p-mt-2">
                                <Checkbox checked={active} onChange={e => setActive(e.checked)} />
                                <label className={"p-ml-3 "} >Habilitado</label>
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <ReactJson src={body} theme="monokai" name={null} enableClipboard={false}
                               onEdit={(e) => editJson(e)} style={{minHeight: '20em' }}
                               onDelete={(e) => deleteObjectJson(e)}
                               onAdd={(e) => true}
                               validationMessage={"Verifique las variables disponibles"}/>
                </div>
                <div className="p-field p-fluid">
                    <Button label={labelSubmit} disabled={submitDisabled} className={"p-mt-4"}
                                onClick={(e) => handleSubmit(e)} />
                </div>
            </div>
        </div>
    )
    return form;
}

export default ProviderScheduledForm;