import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { MessageContext } from "../../../context/messageContext";
import { useContext } from "react";

export default function MessageContextItem() {
  const message_context = useContext(MessageContext);
    const contextItems = () => {
        let context = null;
        if (message_context.messageContext !== null){
          let author_name = 'Tú';
          let context_body = null;
    
          switch(message_context.messageContext.mime_type) {
            case 'audio/mpeg':
            case 'audio/ogg; codecs=opus':
            case 'audio/ogg':
              context_body = (
                  <FontAwesomeIcon icon={"file-audio"} color={'#607D8B'} size={"2x"} className={'p-mt-2 p-mr-2'}/>
              )
              break;
            case 'image/webp':
            case 'image/png':
            case 'image/jpeg':
              context_body = (
                  <img src={message_context.messageContext.body} alt={'message'} className={'context-file'}/>
              )
              break;
            case 'video/mp4':
              context_body = (
                  <video controls>
                    <source src={message_context.messageContext.body} type="video/mp4" className={'context-file'}/>
                  </video>
              )
              break;
            case 'application/pdf':
              context_body = (
                  <FontAwesomeIcon icon={"file-pdf"} color={'#607D8B'} size={"2x"} className={'p-mt-2 p-mr-2'}/>
              )
              break;
            case 'text/plain':
              context_body = (
                  <FontAwesomeIcon icon={"file-alt"} color={'#607D8B'} size={"2x"} className={'p-mt-2 p-mr-2'}/>
              )
              break;
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
              context_body = (
                  <FontAwesomeIcon icon={"file-powerpoint"} color={'#607D8B'} size={"2x"}
                                   className={'p-mt-2 p-mr-2'}/>
              )
              break;
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/msword':
              context_body = (
                  <FontAwesomeIcon icon={"file-word"} color={'#607D8B'} size={"2x"} className={'p-mt-2 p-mr-2'}/>
              )
              break;
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.ms-excel':
            case 'text/csv':
              context_body = (
                  <FontAwesomeIcon icon={"file-excel"} color={'#607D8B'} size={"2x"} className={'p-mt-2 p-mr-2'}/>
              )
              break;
            case 'application/contact':
              context_body = (
                  <FontAwesomeIcon icon={"contact-card"} color={'#607D8B'} size={"2x"} className={'p-mt-2 p-mr-2'}/>
              )
              break;
            case 'application/maps':
              context_body = (
                  <FontAwesomeIcon icon={"map-location"} color={'#607D8B'} size={"2x"} className={'p-mt-2 p-mr-2'}/>
              )
              break;
            default:
              context_body = (
                  <div className={'message-body'}>
                    <span className={'message-body-text'}>{ message_context.messageContext.body }</span>
                  </div>
              )
              break;
          }
    
          let mime_type_context_form = null;
          let message_context_file = null;
          let mime_type_context = getMimetypeContext(message_context.messageContext.mime_type);
          if (mime_type_context !== null) {
            mime_type_context_form = (
                <div className={'message-context-body-text'}>
                  {mime_type_context}
                </div>
            );
            message_context_file = (
                <div className={'message-context-file'}>
                  <div className={'message-context-file-body'}>
                    <div className={'message-context-body-text'}>
                      {context_body}
                    </div>
                  </div>
                </div>
            )
          } else {
            mime_type_context_form = (
                <div className={'message-context-body-text'}>
                  {context_body}
                </div>
            )
          }
          context = (
              <div className={'message-preview-context context-compose'}>
                <span className={'message-context-line'}></span>
                <div className={'message-context'}>
                  <div className={'message-context-body'}>
                    <div className={'message-context-body-text'}>
                      <span>{author_name}</span>
                    </div>
                    {mime_type_context_form}
                  </div>
                  {message_context_file}
                  <button className="p-link" onClick={() => message_context.updateMessageContext(null)}>
                    <FontAwesomeIcon icon={"close"} color={'#607D8B'} size="xs"
                                     className={"btn-actions btn-fontawesome"} />
                  </button>
                </div>
              </div>
          )
        }
        return context;
    }

    const getMimetypeContext = (mime_type) => {
        switch(mime_type) {
          case 'audio/mpeg':
          case 'audio/ogg; codecs=opus':
          case 'audio/ogg':
            return (
                <span>
                    <FontAwesomeIcon icon={"microphone"} color={'#607D8B'} size={"sm"} className={'p-mr-1'} />
                    Audio
                </span>
            )
          case 'image/webp':
          case 'image/png':
          case 'image/jpeg':
            return (
                <span>
                    <FontAwesomeIcon icon={"camera"} color={'#607D8B'} size={"sm"} className={'p-mr-1'}/>
                    Foto
                </span>
            )
          case 'video/mp4':
            return (
                <span>
                    <FontAwesomeIcon icon={"video"} color={'#607D8B'} size={"sm"} className={'p-mr-1'} />
                    Video
                </span>
            )
    
          case 'application/pdf':
            return (
                <span>
                    <FontAwesomeIcon icon={"file-pdf"} color={'#607D8B'} size={"sm"} className={'p-mr-1'} />
                    PDF
                </span>
            )
    
          case 'text/plain':
            return (
                <span>
                    <FontAwesomeIcon icon={"file-alt"} color={'#607D8B'} size={"sm"} className={'p-mr-1'} />
                    Archivo
                </span>
            )
          case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            return (
                <span>
                    <FontAwesomeIcon icon={"file-powerpoint"} color={'#607D8B'} size={"sm"} className={'p-mr-1'} />
                    PPT
                </span>
            )
    
          case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          case 'application/msword':
            return (
                <span>
                    <FontAwesomeIcon icon={"file-word"} color={'#607D8B'} size={"sm"} className={'p-mr-1'}/>
                    Word
                </span>
            )
    
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          case 'application/vnd.ms-excel':
          case 'text/csv':
            return (
                <span>
                    <FontAwesomeIcon icon={"file-excel"} color={'#607D8B'} size={"sm"} className={'p-mr-1'}/>
                    CSV
                </span>
            )
          case 'application/contact':
            return (
                <span>
                    <FontAwesomeIcon icon={"address-card"} color={'#607D8B'} size={"sm"} className={'p-mr-1'}/>
                    Contacto
                </span>
            )
    
          case 'application/maps':
            return (
                <span>
                    <FontAwesomeIcon icon={"map-marked-alt"} color={'#607D8B'} size={"sm"} className={'p-mr-1'} />
                    Ubicación
                </span>
            )
          default:
            return null;
        }
    }
    return(
        contextItems()
    )
}