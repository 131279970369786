import React from 'react';
import Resources from '../../Resources/Resources';

const Questionnaires = () => {
    const resource = {
        title: 'Cuestionarios bot',
        url: '/root_questionnaires',
        colsHeader: [
            { field: 'name', header: 'Nombre'},
            { field: 'action', header: 'Acciones' }
        ],
        action: {
            add: '/new',
            show: '/questionnaires/',
            edit: '/edit',
            duplicate: '/duplicate_root_questionnaire',
            delete: true,
            canShowButtonView: false
        },
        urlTable: [
            '/questionnaires',
            '/questionnaires/new',
            '^\\/questionnaire\\/\\d+$',
            '^\\/questionnaires\\/\\d+\\/edit$'
        ]
    }

    return (<Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader}
                       action={resource.action} urlTable={resource.urlTable} />);
}

export default Questionnaires;
