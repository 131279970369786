import React, {Component} from 'react';
import * as actions from '../../store/actions/index';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import CustomSpinner from "../../UI/CustomSpinner";
import {showErrorMessage} from "../../shared/messagesToast";
import "../../assets/css/Auth.css";


class Auth extends Component {
    state = {
        username: {
            value: '',
        },
        password: {
            value: '',
        },
        eyes: false,
    }

    componentDidMount() {
        if (this.props.authRedirectPath !== '/') {
            this.props.onSetAuthRedirectPath();
        }
    };


    inputChangedHandler = (event, inputIdentifier) => {
        const updatedFormElement = {
            ...this.state[inputIdentifier]
        };

        updatedFormElement.value = event.target.value;

        this.setState({[inputIdentifier]: updatedFormElement});

    }


    submitHandler = (event) => {
        event.preventDefault();
        this.props.onAuth(this.state.username.value, this.state.password.value);
    };

    render (){
        if (this.props.loading ) {
            return <CustomSpinner />
        }

        if (this.props.error ) {
            showErrorMessage(this, "Login", this.props.error);
            this.props.clearErrors();
        }

        let authRedirect = null;

        if (this.props.isAuthenticated){
            authRedirect = <Redirect to={this.props.authRedirectPath} />
        }

        if (!this.props.loading && this.props.redirectDomain.status){
            window.location.href = this.props.redirectDomain.url;
            return null;
        }


        return (
            <div className={process.env.REACT_APP_PAGE === 'pip-app' ? 'body-container' : 'body-container-prontocobro'} >
                <div className="container" id="container">
                    <div className="form-container sign-in-container">
                        <form onSubmit={this.submitHandler}>
                            <img src={`${process.env.REACT_APP_PAGE}/images/logo.png`} className="logo" alt="logo-login"/>
                            <span><b>Iniciar sesión</b></span>
                            <input value={this.state.username.value} onChange={(event) => this.inputChangedHandler(event, 'username')} type="username" placeholder="Usuario" autoComplete="off"/>
                            <div className="p-inputgroup">
                                <input value={this.state.password.value} onChange={(event) => this.inputChangedHandler(event, 'password')} type={this.state.eyes ? "text" : "password"} placeholder="Contraseña" autoComplete="off"/>
                                <span onClick={()=>this.state.eyes ? this.setState({eyes: false}) : this.setState({eyes: true})} style={{position: 'relative', height: '70%', top: '8px', border: 'none', backgroundColor: '#f8f8f8', display: 'none !important', cursor: 'pointer'}} className="p-inputgroup-addon">
                                    <i className={this.state.eyes ? "pi pi-eye" : "pi pi-eye-slash"}></i>
                                </span>
                            </div>
                            <button>Iniciar</button>
                            {process.env.REACT_APP_PAGE === 'pip-app' && (
                                <a target="_blank" rel="noopener noreferrer" href="https://fintechsolutions.io/pip/">Prueba Gratis</a>
                            )}
                        </form>
                    </div>
                    <div className="overlay-container">
                        <div className={process.env.REACT_APP_PAGE === 'pip-app' ? "overlay" : "overlay-prontocobro"}>
                            <div className="overlay-panel overlay-right">
                            <img src={`${process.env.REACT_APP_PAGE}/images/login.png`} className="logo" alt="logo-login"/>
                            </div>
                        </div>
                        {authRedirect}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.auth.loading,
        error: state.auth.error,
        isAuthenticated: state.auth.token !== null,
        authRedirectPath: state.auth.authRedirectPath,
        redirectDomain: state.auth.redirectDomain,
        messages: state.message.messages,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (username,password) => dispatch(actions.auth(username,password)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/')),
        clearErrors: () => dispatch(actions.clearErrors()),
        showToast: (message) => dispatch(actions.showToast(message)),
        clearMessages: () => dispatch(actions.clearMessages())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
