import React, { Component } from 'react';
import InputForm from '../../../UI/InputForm';
import { Button } from 'primereact/button';
import ApiLoan from '../../../service/ApiLoanService';
import {withRouter} from "react-router-dom";

import {showToast} from "../../../store/actions";
import {showSuccessMessage, showErrorMessage} from "../../../shared/messagesToast";
import connect from "react-redux/es/connect/connect";
import {messageErrorObject} from "../../../shared/utility";
import update from "immutability-helper";

class ProviderChannel extends Component {

    state = {
        apiService: new ApiLoan(),
        uri: '/client/channels',
        title: 'Canales de Cliente',
        providerChannelForm: {
            provider_channels: {
                elementType: 'multiSelect',
                elementConfig: {
                    options: null,
                    defaultLabel: 'Seleccione tipo de canal',
                    selectedItemsLabel: '{0} canales seleccionados',
                    maxSelectedLabels: 3,
                },
                value: '',
                traduction: 'Canales'
            }
        },
        channels: null,
        loading: true
    };

    componentDidMount() {
        let channelsOptions = [];
        let channelsValues = [];

        let providerChannelForm = {
            ...this.state.providerChannelForm
        };

        this.state.apiService.getResource({
            url: this.props.match.url
        }).then(response => {
            const response_data = response.data;

            for (let key in response_data) {
                channelsOptions.push({
                    'value':  response_data[key].id,
                    'label': response_data[key].description
                });

                if (response_data[key].active){
                    channelsValues.push(response_data[key].id);
                }
            }

            let channelsForm = update(providerChannelForm, {
                provider_channels: {
                    elementConfig: {
                        options: {
                            $set: channelsOptions
                        }
                    },
                    value: { $set: channelsValues }
                }
            });

            providerChannelForm = channelsForm;

            this.setState({
                providerChannelForm: providerChannelForm,
                loading: false
            });
        }).catch(error => {
            showErrorMessage(this, "Clientes", 'Error en la carga de canales');
            this.props.history.push( '/clients' );
        });
    }

    providerChannelHandler = (event) => {
        event.preventDefault();
        this.setState( { loading: true } );

        const formData = {};

        for (let formElementIdentifier in this.state.providerChannelForm) {
            formData[formElementIdentifier] = this.state.providerChannelForm[formElementIdentifier].value;
        }

        formData['provider_id'] = this.props.match.params.id;

        this.state.apiService.postResource({
            url: this.state.uri,
            data: formData
        }).then(response => {
            this.setState( { loading: false } );
            showSuccessMessage(this, "Canal", "Se edito satisfactoriamente");
            this.props.history.push( '/clients' );
        }).catch(error => {
            let messageError = error.response.data.message;

            let providerChannelForm = {
                ...this.state.providerChannelForm
            };

            let errorMessages = messageErrorObject(providerChannelForm, messageError);

            if (errorMessages !== null){
                this.setState({
                    providerChannelForm: errorMessages,
                    loading: false
                });
            } else {
                this.setState( { loading: false } );
                showErrorMessage(this, "Cliente", messageError);
            }
        });
    };

    inputChangedHandler = (event, inputIdentifier) => {
        let inputFormChanged = this.state.providerChannelForm;

        const updatedProviderChannelForm = {
            ...inputFormChanged
        };

        const updatedFormElement = {
            ...updatedProviderChannelForm[inputIdentifier]
        };
        updatedFormElement.value = event.value;
        updatedProviderChannelForm[inputIdentifier] = updatedFormElement;

        if (updatedFormElement.elementConfig.error !== undefined){
            updatedFormElement.elementConfig.error = null;
        }

        this.setState({providerChannelForm: updatedProviderChannelForm});
    }

    render(){
        const formElementsArray = [];
        let providerChannelForm = this.state.providerChannelForm;
        let submitForm = this.providerChannelHandler;
        let submitFormButton = 'Actualizar';
        let form = null;

        if (!this.state.loading){
            for (let key in providerChannelForm) {
                formElementsArray.push({
                    id: key,
                    config: providerChannelForm[key]
                });
            }

            form = (
                <form onSubmit={submitForm}>
                    {formElementsArray.map(formElement => (
                        <InputForm
                            key={formElement.id}
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            value={formElement.config.value}
                            typeElement={formElement.id}
                            traduction={formElement.config.traduction}
                            title={formElement.config.title}
                            changed={(event) => this.inputChangedHandler(event, formElement.id)}
                        />
                    ))}

                    <div className="p-col-12 p-md-9">
                        <Button label={submitFormButton} style={{marginBottom:'10px'}} />
                    </div>

                </form>
            );
        }


        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12 p-lg-12">
                    <div className="card card-w-title">
                        <h1>{this.state.title}</h1>
                        <div className="p-col-12 form-group">
                            {form}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapDispatchToProps = {
    showToast
};

export default withRouter(connect(null, mapDispatchToProps)(ProviderChannel));