import React from 'react';
import './ConversationSearch.css';
import { InputText } from 'primereact/inputtext';

export default function ConversationSearch(props) {
 
  /*const _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (props.value){
        props.onSubmitForm(e);
      }
    }
  }*/
  return (
    <div className="conversation-search">
      <span className="p-input-icon-left conversation-search">
        <i className={(props.loading && props.value) ? "pi pi-spin pi-spinner" : "pi pi-search"} />
        <InputText
        className={"conversation-search-input"}
        placeholder={"Buscador"}
        value={props.value}
        onChange={props.onChange}/>
        {props.value && (
          <i className={"pi pi-times clean"} onClick={()=>{props.onClearValue()}}/>
        )}
      </span>
    </div>
  );
}