import React, { Component } from 'react';
import ResourceFormGeneric from '../../Forms/ResourceFormGeneric';
import {roles} from './emailSettingConfig';
import {switchRole} from "../../../shared/roles";
import ApiLoan from "../../../service/ApiLoanService";
import {showErrorMessage} from "../../../shared/messagesToast";
import {showToast} from "../../../store/actions";
import {withRouter} from "react-router-dom";

import connect from "react-redux/es/connect/connect";
class EmailSettingForm extends Component {

    state = {
        uri: '/email_settings',
        resource_id: this.props.match.params.id,
        action: this.props.match.params.id ? 'edit' : 'create',
        title: 'Configuración de email',
        fields: [
            {
                name: 'default_mail_sender',
                elementType: 'text',
                elementConfig: {
                },
                value: '',
                traduction: 'Email'
            },
            {
                name: 'reply_to',
                elementType: 'text',
                elementConfig: {
                },
                value: '',
                traduction: 'Responder a'
            },
            {
                name: 'mail_port',
                elementType: 'number',
                elementConfig: {
                },
                value: '',
                traduction: 'Puerto'
            },
            {
                name: 'mail_server',
                elementType: 'text',
                elementConfig: {
                },
                value: '',
                traduction: 'SMTP'
            },
            {
                name: 'mail_use_ssl',
                elementType: 'checkbox',
                elementConfig: {
                },
                value: false,
                traduction: 'SSL'
            },
            {
                name: 'mail_use_tls',
                elementType: 'checkbox',
                elementConfig: {
                },
                value: false,
                traduction: 'TLS'
            },
            {
                name: 'mail_username',
                elementType: 'text',
                elementConfig: {
                },
                value: '',
                traduction: 'Usuario'
            },
            {
                name: 'mail_password',
                elementType: 'password',
                elementConfig: {
                    feedback: false,
                    autoComplete: 'off'
                },
                value: '',
                traduction: 'Password'
            },
            {
                name: 'mail_name',
                elementType: 'text',
                elementConfig: {
                },
                value: '',
                traduction: 'Nombre del Usuario'
            },
            {
                name: 'provider_id',
                elementType: 'dropdown',
                elementConfig: {
                    options: null,
                    placeholder: 'Seleccione un cliente'
                },
                value: '',
                traduction: 'Cliente',
                requestResource: {
                    request:{
                        url: '/clients',
                        params: {},
                        filters: [
                            {
                                name: "available",
                                op: "eq",
                                val: true
                            }
                        ],
                    },
                    format: {
                        value: 'id',
                        label: 'name'
                    }
                }
            }
        ],
        apiService: new ApiLoan(),
        loading: false
    };

    componentDidMount(){
        let role = switchRole();
        if (role !== 'admin'){
            let url_edit = '/email_settings/:id/edit';
            if (this.props.match.path !== url_edit){
                this.state.apiService.getResources({
                    url: '/email_settings'
                }).then(response => {
                    if (response.data['num_results'] >= 1){
                        showErrorMessage(this, "Configuración Email", 'Ya existe una configuración de correo');
                        this.props.history.push( '/email_setting' );
                    }
                }).catch(error => {
                    showErrorMessage(this, "Configuración Email", 'Error al cargar la configuración de correo');
                    this.props.history.push( '/email_setting' );
                });
            }
        }
    }

    setFields = (fields) => {
        this.setState({fields: fields})
    };

    render(){
        let fields = [...this.state.fields];

        if(roles[switchRole()]){
            fields = fields.filter(field => {
                return !roles[switchRole()].exclude_fields.includes(field.name)
            })
        }

        const form = (<ResourceFormGeneric 
                            setFields={this.setFields}
                            fields={fields}
                            url={'/email_settings'}
                            resource_id={this.state.resource_id}
                            resource_traduction={this.state.title}/>);

        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12 p-lg-12">
                    <div className="card card-w-title">
                        <h1>{this.state.title}</h1>
                        {!this.state.loading && form}
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    showToast
};

export default withRouter(connect(null, mapDispatchToProps)(EmailSettingForm));
