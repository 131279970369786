import React, { useEffect, useState, useContext } from 'react';
import { NavLink } from "react-router-dom";

import ApiLoan from "../../service/ApiLoanService";

import { CompanyContext } from "../../context/companyContext"

const MessageIcon = () => {
    const [notSeenConversations, setNotSeenConversations] = useState(0);

    const companyContext = useContext(CompanyContext);
    const socket = companyContext.socket;

    useEffect(() => {
        const apiLoan = new ApiLoan();
        apiLoan.getResources({
            url: '/conversations/not_seen'
        }).then((response) => {
            setNotSeenConversations(response.data.not_seen_conversations)
        })
    },[companyContext.privateInbox]);

    useEffect(() => {
        socket.on('not-seen-conversations', updateNotSeenConversation)
        return () => { 
            socket.off('not-seen-conversations', updateNotSeenConversation)
        }
      },[companyContext.privateInbox]);

    const updateNotSeenConversation = ({not_seen_conversations}) => {
        setNotSeenConversations(not_seen_conversations)
    }

    const number = (notSeenConversations ? (<span className="topbar-badge animated rubberBand">{notSeenConversations}</span>) : '')

    return (<button className="p-link">
                <NavLink to={"/messenger"} >
                    <i className="pi pi-comments"></i>
                    {number}
                    <span className="topbar-item-name">Whatsapp</span>
                </NavLink>
            </button>);
}

export default MessageIcon