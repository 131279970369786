import React, { useState, useEffect, Fragment, useRef } from 'react';
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses } from 'react-pro-sidebar';
import ReactTooltip from "react-tooltip";

const AppMenuCustom = (props) => {
    const [items, setItems] = useState(null);

    useEffect(() => {

        if (props?.model !== null){
            setItems(props?.model[0]?.items);
        }

    }, [props.model]);
    
    const isMobile = () => {
        return window.innerWidth <= 1025;
    };

    const isClick = (click) => {
        props.onSidebarClick(click);
    };

    const renderMenuItem = (menuItem, index) => {
        if (menuItem.items === undefined) {
          return (
            <MenuItem
              key={`${menuItem.slug}-${index}`}
              onClick={() => isClick(false)}
              data-tip
              data-for={menuItem.slug}
              component={<NavLink to={menuItem?.to}/>}
              icon={<FontAwesomeIcon icon={menuItem.icon} size={"lg"} />}
            >
              {menuItem?.label}
            </MenuItem>
          );
        } else {
          return (
            <SubMenu
              key={`${menuItem.slug}-${index}`}
              onClick={() => isClick(true)}
              data-tip
              data-for={menuItem.slug}
              icon={<FontAwesomeIcon icon={menuItem.icon} size={"lg"} />}
              label={menuItem?.label}
            >
              {menuItem.items.map((subItem, subIndex) => renderMenuItem(subItem, subIndex))}
            </SubMenu>
          );
        }
    };

    const item = () => {
        return items && items.map((menuItem, index) => renderMenuItem(menuItem, index));
    };

    const tooltip = () =>{ 
        return items && items.map((item, index) =>{
            return (
                <Fragment key={`${item.slug}-${index}`}>
                    {props.layoutMode === 'static' && !isMobile() && (
                    <ReactTooltip id={item.slug} place="left" effect="solid">
                        {item.label}
                    </ReactTooltip>)}
                </Fragment>
            );
        })
    }

    return (
        <Sidebar transitionDuration={1} collapsedWidth={'70px'} collapsed={!isMobile() ? props.layoutMode === 'static' : false } style={{left: !isMobile() ? props.layoutMode === 'static' ? '170px' : '' : ''}}>
            <Menu rootStyles={{['.css-uocmrg']: {display: 'none !important '}}} closeOnClick={true}>
                {tooltip()}
                {item()}  
            </Menu>
        </Sidebar>
    );

}

export default AppMenuCustom;