import React from "react";
import cronstrue from 'cronstrue/i18n';

const FrecuencyView = (props) => {

    const getFrecuency = () => {
        let frecuency = cronstrue.toString(props.value, { locale: "es" });

        return (
            <div className="p-col-12">
                <div className={"p-col-12 p-md-10"}>
                    <div className="p-grid">
                        <div className={"p-col-12 p-md-4"}>
                            <span>Frecuencia</span>
                        </div>
                        <div className={"p-col-12 p-md-4"}>
                            {frecuency}
                        </div>
                    </div>
                </div>

            </div>
        );

    };


    return getFrecuency();


};

export default FrecuencyView;