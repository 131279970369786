import React, {useState, useEffect, useContext} from 'react';
import DialogGeneric from "../../../UI/DialogGeneric";
import {MessageContext} from "../../../context/messageContext";

const MessageForwardModal = (props) => {
    const [visibleDialog, setVisibleDialog] = useState(false);
    const [valueDialog, setValueDialog] = useState(null);
    const [modal, setModal] = useState(null);
    const {forward, forwardContactModal, selectedForwardMessages, selectedForwardContact, updateForward,
        updateForwardContactModal, updateSelectedForwardMessages} = useContext(MessageContext);

    useEffect(() => {
        if (props.enabled){
            setVisibleDialog(props.enabled)
        }
    }, [props.enabled])

    useEffect(() => {
        if (visibleDialog){
            dialogMessageForward('list')
        }
    }, [visibleDialog])

    useEffect(() => {
        if (valueDialog !== null) {
            setModal(<DialogGeneric visible={visibleDialog} withoutCancel={true} closable={true} setVisible={setVisibleDialog}
                                    closeModal={true} {...valueDialog}  />);
        }
    }, [valueDialog, visibleDialog]);

    useEffect(() => {
        if (!visibleDialog && forwardContactModal){
            if (modal !== null){
                setModal(null);
            }
            if (valueDialog !== null){
                setValueDialog(null);
                updateForward(false);
                updateForwardContactModal(!forwardContactModal)
                if (selectedForwardMessages.length > 0){
                    updateSelectedForwardMessages([])
                }
            }
        }
    }, [visibleDialog, props.enabled, modal, forward, forwardContactModal])

    useEffect(() => {
        if (selectedForwardContact !== null){
            setVisibleDialog(false);
        }
    }, [selectedForwardContact]);

    const getDialogParams = (action) => {
        let data = {};
        let title = 'Contactos';
        switch (action){
            case 'list':
                data['width'] = {'width': '35vw'};
                data['height'] = {'height': '30vw', 'overflowY': 'hidden'};
                data['title'] = title;
                data['url'] = 'URL';
                data['method'] = 'post';
                data['fields'] = [{
                    name: 'reminders',
                    elementType: 'messageForwardContact',
                    elementConfig: {},
                    elementClass: 'p-col-12 p-md-12',
                }];
                break;
        }
        return data;
    }

    const getHeaderDialog = (title) => {
        return (
            <div className="p-dialog-title">
                <span>
                    <b>{title}</b>
                </span>
            </div>
        );
    }

    const dialogMessageForward = (action) => {
        const params = getDialogParams(action);

        if (Object.keys(params).length === 0){
            return setVisibleDialog(false);
        }

        let value_dialog = {
            'url': params.url,
            'method': params.method,
            'header': getHeaderDialog(params.title, action),
            'show_footer': false,
            'submit_label': 'Crear',
            'sumaryToast': 'Contactos',
            'fields': params.fields,
            'width': params.width,
            'height': params.height,
            'buttons_rounded': true
        }

        setValueDialog(value_dialog)
    }

    return visibleDialog ? modal : null;

}

export default MessageForwardModal;