import React, {Component} from "react";

import axios from 'axios';

import {Button} from "primereact/button";
import {OverlayPanel} from "primereact/overlaypanel";

import '../../../assets/css/PaymentProvider.css';

import InputForm from '../../../UI/InputForm';
import Logs from '../../Logs/Logs';
import CsvExample from '../../BulkDataCsv/CsvExample';
import {TabMenu} from "primereact/tabmenu";

class LoanBulk extends Component {
    state = {
        url: null,
        title: 'Subir Operaciones',
        loanBulkForm: {
            filename: {
                elementType: 'file_upload',
                elementConfig: {
                    allowMultiple: false,
                    maxFiles: 1,
                    acceptedFileTypes: ['text/csv', 'application/vnd.ms-excel']
                }
            }
        },
        itemsMenu: [
            {label: 'Subir Operaciones', icon: 'pi pi-plus', slug: 'upload'},
            {label: 'Editar Operaciones', icon: 'pi pi-pencil', slug: 'edit'}
        ],
        filenameExample: 'ejemplo_operaciones.csv',
        imgSrc: 'assets/layout/images/upload/loans.png',
        typeFile: 'loans_load',
        logTitle: 'Historial de Subidas',
        count: 0,
        loading: true,
        revertUrl: '/loans/revert'
    };

    componentDidMount(){

        this.setState({
            url: axios.defaults.baseURL + '/debtors/loans/bulk',
            loading: false
        });

    };

    onTabChange = (e) => {
        if (e.value.slug === 'upload'){
            this.setState({
                count: this.state.count + 1,
                typeFile: 'loans_load',
                activeItem: e.value,
                url: axios.defaults.baseURL + '/debtors/loans/bulk',
                title: 'Subir Operaciones',
                filenameExample: 'ejemplo_operaciones.csv',
                imgSrc: 'assets/layout/images/upload/loans.png',
                logTitle: 'Historial de Subidas',
            });
        } else {
            this.setState( {
                count: this.state.count + 1,
                typeFile: 'loans_edit_load',
                activeItem: e.value,
                url: axios.defaults.baseURL + '/debtors/loans/edit/bulk',
                title: 'Editar Operaciones',
                filenameExample: 'ejemplo_editar_operaciones.csv',
                imgSrc: 'assets/layout/images/upload/loans_edit.png',
                logTitle: 'Historial de Ediciones',
            });
        }
    };

    getExample = () => {
        let exampleData = [
            ["DNI", "CBU", "Nro Factura", "Importe Prestamo", "Importe Total", "Cant Cuotas", "Nro Cuota", "Fecha Cuota", "Descripcion"],
            ["11222333", "2850590940090418135201", "100357", "10000", "12000", "3", "1", "2018-04-10", "Prestamo"],
            ["11222333", "2850590940090418135201", "100357", "10000", "12000", "3", "2", "2018-05-10", "Prestamo"],
            ["11222333", "2850590940090418135201", "100357", "10000", "12000", "3", "3", "2018-06-11", "Prestamo"],
            ["22333444", "4170231820000000010500", "100311", "10000", "12000", "3", "1", "2019-02-11", "Prestamo"],
            ["22333444", "4170231820000000010500", "100311", "10000", "12000", "3", "2", "2019-03-11", "Prestamo"],
            ["22333444", "5170231820000000010500", "100311", "10000", "12000", "3", "3", "2019-04-10", "Prestamo"]
        ];

        if (this.state.activeItem !== undefined){
            if (this.state.activeItem.slug === 'edit'){
                exampleData = [
                    ["CBU", "Nro Factura","Importe Prestamo", "Importe Total", "Descripcion"],
                    ["2850590940090418135201", "100357", "10000", "12000", "Prestamo"],
                    ["2850590940090418135201", "100311", "10000", "12000", "Prestamo"]
                ];
            }
        }
        return exampleData;
    };

    getImage = () => {
        return (
            <div>
                <Button label="Ejemplo" onClick={(event) => this.overlayPanel1.toggle(event)} />
                <OverlayPanel ref={el => this.overlayPanel1 = el} showCloseIcon={true}>
                    <img alt="operations" src={this.state.imgSrc} />
                </OverlayPanel>
            </div>
        );
    };

    render(){
        const formElementsArray = [];
        let loanBulkForm = this.state.loanBulkForm;

        for (let key in loanBulkForm) {
            formElementsArray.push({
                id: key,
                config: loanBulkForm[key]
            });
        }


        const example = (<CsvExample CSV_TYPE='LOANS_BULK_CSV_SETTINGS'/>);


        let form = (
            <div className="ui-g-12 ui-md-12">
                <form>
                    {formElementsArray.map(formElement => (
                        <InputForm
                            key={formElement.id}
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            value={formElement.config.value}
                            typeElement={formElement.id}
                            title={formElement.config.title}
                            url={this.state.url}
                        />
                    ))}
                </form>
            </div>

        );

        return (
            <div className="ui-g ui-fluid">
                <div className="ui-g-12 ui-lg-12">
                    <div className="card card-w-title">
                        <h1>{this.state.title}</h1>
                        <div className='p-grid'>
                            <div className='p-col-12'>
                                <div className='p-grid'>
                                    <div className="p-col-12 p-col-12">
                                        <TabMenu model={this.state.itemsMenu} activeItem={this.state.activeItem}
                                                 onTabChange={(e) => this.onTabChange(e)}/>
                                    </div>
                                    <div className='p-col-5 p-md-2'>
                                        {example}
                                    </div>
                                    <div className='p-col-5 p-md-2'>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ui-g form-group">
                            {form}
                        </div>
                    </div>
                </div>
                <div className="ui-g-12 ui-lg-12">
                    <div className="card card-w-title">
                        <div className='p-grid'>
                            <div className='p-col-12'>
                                <Logs title={this.state.logTitle} name={"type"} op={"eq"} val={this.state.typeFile}
                                      key={this.state.count} revertUrl={this.state.revertUrl}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoanBulk;