import React, { useEffect, useContext } from 'react';
import classNames from 'classnames';
import {NavLink} from "react-router-dom";
import ApiLoan from "./service/ApiLoanService";
import {switchRole} from './shared/roles';
import { CompanyContext } from './context/companyContext';
import { useHistory } from 'react-router-dom';
import MessageIcon from "./components/Messenger/MessengerIcon";
import WhatsappNotification from "./components/Notifications/Notification/WhatsappNotification";
import './assets/css/AppTobbar.css';

const AppTopbar = (props) => {

    const companyState = useContext(CompanyContext);
    const socket = companyState.socket;
    const history = useHistory();

    const goDashboard = () => {
        if (history.location.pathname !== '/'){
            history.push('/');
        }
    }

    useEffect(() => {
        const changeSettings = ({ private_inbox, private_group }) => {
            companyState.setPrivateInbox(private_inbox);
            companyState.setPrivateGroup(private_group);
        }
        socket.on('settings', changeSettings);
        return () => {
            socket.off('settings', changeSettings)
        }
      },[companyState.privateInbox, companyState.privateGroup]);


    useEffect(() => {
        const whatsappService = localStorage.getItem('whatsappService');
        const managerRole = switchRole() === 'manager';
        const paymentOrderCreatorRole = switchRole() === 'payment_order_creator';
        const apiService = new ApiLoan();

        if(whatsappService === null && !managerRole && !paymentOrderCreatorRole){
            apiService.getResources({
                url: "/channels/available"
            }).then(response => {
                const whatsappChannel = response.data.filter(channel => {
                    return channel.description === 'Whatsapp';
                });
                if (whatsappChannel.length > 0 ){
                    localStorage.setItem('whatsappService', 'true');
                }
            });
        }
    }, []);

    let topbarClass = classNames('topbar-menu fadeInDown', { 'topbar-menu-active': props.topbarMenuActive })
    let horizontalIcon = (props.layoutMode === 'horizontal') &&
        <button type="button" className="p-link topbar-logo">
            <img
                alt="logo"
                src={`${process.env.REACT_APP_PAGE}/images/logo-blanco.png`}
                style={{
                    height:"48px",
                    width:"auto",
                }}
                className="mobile-logo"
            />
        </button>;
    let logo = (!props.isMobile()) &&
        <i onClick={goDashboard}>
            <img
                alt="logo"
                src={process.env.REACT_APP_PAGE === 'pip-app' ? `${process.env.REACT_APP_PAGE}/images/logo-blanco.png` : `${process.env.REACT_APP_PAGE}/favicon.ico`}
                style={{position: 'relative', height:"55px", bottom: '10px', margin: 'auto', cursor: 'pointer'}}
            />
        </i>

    let messengerItem = null;
    if (localStorage.getItem('gatewayWaba') && switchRole() !== 'manager' && switchRole() !== 'payment_order_creator' && switchRole() !== 'admin'){
        messengerItem = (<li key={localStorage.getItem('provider_id')} role="menuitem"><MessageIcon/></li>);
    }

    let messageAnalyticsItem = null;
    if (localStorage.getItem('whatsappService') && (localStorage.getItem('gatewayWaba') === 'DIALOG_360' || localStorage.getItem('gatewayWaba') === 'CLOUD-API')
        && switchRole() !== 'manager' && switchRole() !== 'payment_order_creator' && switchRole() !== 'admin'){
        messageAnalyticsItem = (<WhatsappNotification activeTopbarItem={props.activeTopbarItem}
                                                      isMobile={props.isMobile}
                                                      onTopbarItemClick={props.onTopbarItemClick}/>);
    }

    return (
        <div className="layout-topbar">
            {horizontalIcon}
            <img
                alt="logo"
                src={`${process.env.REACT_APP_PAGE}/images/logo-blanco.png`}
                style={{
                    height:"48px !important",
                    width:"auto",
                }}
                className="mobile-logo"
            />

            <button type="button" className="p-link menu-btn" onClick={props.onMenuButtonClick}>
                <i className="pi pi-bars"></i>
            </button>

            <button type="button" className="p-link topbar-menu-btn" onClick={props.onTopbarMobileMenuButtonClick}>
                <i className="pi pi-cog"></i>
            </button>

            <div className="layout-topbar-menu-wrapper">
                <ul className={topbarClass}>
                    {logo}
                    <li role="menuitem">
                        <button className="p-link">
                            <NavLink to={"/logout"} >
                                <i className="pi pi-power-off"></i>
                                <span className="topbar-item-name">Salir</span>
                            </NavLink>
                        </button>
                    </li>
                    <li role="menuitem">
                        <button className="p-link" >
                            <NavLink to={"/profile"} >
                                <i className="pi pi-user"></i>
                                <span className="topbar-item-name">Perfil</span>
                            </NavLink>
                        </button>
                    </li>
                    {messageAnalyticsItem}
                    {messengerItem}
                </ul>
            </div>
        </div>
    );

}

export default AppTopbar;