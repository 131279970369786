import React,{useEffect, useState, useMemo, useCallback, Fragment} from 'react'

import ApiLoanService from "../../../service/ApiLoanService";

import {Chips} from 'primereact/chips';
import {AutoComplete} from 'primereact/autocomplete';
import CircularSpinner from '../../../UI/CircularSpinner';

import "../../../assets/css/CustomTags.css";


const CustomTagsList = ({value, client_id}) => {
    const [tags, setTags] = useState([]);
    const [editTags, setEditTags] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [providerTags, setProviderTags] = useState([]);
    const [suggestedTags, setSuggestedTags] = useState([]);

    useEffect(() => {
        let mounted = true;
        if(value){
            if (mounted){
                setTags(value);
            }

        }else{
            fetchClient()
            .then(response => {
                if (mounted){
                    setTags(response.data.custom_tags)
                }

            })
        }
        return () => {
            mounted = false;
        };
     },[]);

     useEffect(() => {
        let mounted = true;

        if(editTags){
            memoFetchProviderTags
            .then((response) => {
                if (mounted){
                    setProviderTags(response.data.objects);
                }
            });
        }
         return () => {
             mounted = false;
         };
     }, [editTags, tags]);
    
    const assignTag = (e) => {
        const selectedTags = providerTags.filter( (prTag) => {
            return (e.value.indexOf(prTag.name) >= 0)
        })
        setTags(selectedTags);
    }

    const initApiLoanService = () => {
        const apiService = new ApiLoanService();
        return apiService
    }

    const fetchProviderTags = () => {
        const apiService = initApiLoanService();
        return apiService.getResources({url: '/all_custom_tags'})
    }

    const fetchClient = () => {
        const apiService = initApiLoanService();
        return apiService.getResources({url: '/audiences/' + client_id})
    }

    const addCustomTags = useCallback(() => {
        const apiService = initApiLoanService();
        const tagsList = tags.map((tag) => {
            return {id: tag.id}
        })
        return apiService.postResource({
            url: '/clients/' + client_id + '/custom_tags',
            data: {custom_tags: tagsList}
        })
    }, [client_id, tags])

    

    const suggestTags = (event) => {
        const prTagIds = tags.map((tag) => tag.id);
        const prTags = providerTags.filter((tag) => {
            return (prTagIds.indexOf(tag.id) < 0);
        });
        let results = prTags.filter((tag) => {
            return (tag.name.toLowerCase().search(event.query.toLowerCase()) >= 0);
        });
        setSuggestedTags(results);
    }

    const onClickButton = () => {
        if(editTags){
            setLoadingSave(true);
            setEditTags(false);
            addCustomTags()
            .then(response => {
                setLoadingSave(false);
            }).catch(error => {
                setEditTags(true);
            });
        }
        setEditTags(!editTags);
    }

    const memoFetchProviderTags = useMemo(fetchProviderTags, [])

    const tagValues = tags.map((tag) => {
        return tag.name;
    });

    const prTags = suggestedTags.map((providerTag) => {
        return providerTag.name;
    })

    const actionButton = loadingSave ? (<CircularSpinner/>) : 
    (<button className="p-button p-button-icon-only p-button-rounded" onClick={onClickButton}>
        <span className={"pi pi-" + (editTags ? 'save' : 'pencil') + " p-c p-button-icon-left"}></span>
    </button>);

    const componentTags = editTags ? 
    (<AutoComplete className='' multiple={true} dropdown={true} value={tagValues} onChange={assignTag}
        suggestions={prTags} completeMethod={suggestTags}
                   />) :
    (<Chips value={tagValues} disabled={true} className="show-tag client-tags-multiview" />)
    
    return (
        <Fragment>
            <div className="p-grid p-fluid">
                <div className="p-col-11 p-flex-row">
                    {componentTags}
                </div>
                <div className="p-col-1 p-flex-row">
                    {actionButton}
                </div>
            </div>
        </Fragment>
    );

};

export default CustomTagsList;