import React, {Component} from 'react';
import InputForm from './InputForm';
import ButtonForm from './ButtonForm';

class ChildForm extends Component {

    inputChangedChildHandler = (event, inputIdentifier, elementType) => {
        let event_value = [];

        event_value.push(inputIdentifier.split('.'));

        if (elementType !== 'dropdown'){
            event.persist()
            event.value = event.target.value;
        }

        event.child = event_value;
        this.props.changed(event,inputIdentifier);

    }


    render (){
        let inputChildElement = null;
        const formChildsArray = [];
        const inputChildElements = [];

        for (let key in this.props.children) {
            formChildsArray.push({
                id: [this.props.typeElement, '.', key].join(''),
                config: this.props.children[key]
            });
        }

        formChildsArray.map(child => {
            let childs = Object.keys(child.config).map(igKey => {
                return [...Array(child.config[igKey])].map((_, i) => {
                    let children = child.config[igKey];
                    let childDataKey = child.id.split('.');
                    let dataKey = [childDataKey[0],'.',childDataKey[1],'.',igKey].join('');

                    if (igKey !== 'buttons'){
                        inputChildElement =
                            <InputForm
                                key={dataKey}
                                elementType={children.elementType}
                                elementConfig={children.elementConfig}
                                value={children.value}
                                children={children.children}
                                typeElement={dataKey}
                                traduction={children.traduction}
                                options={this.props.options}
                                changed={(event) => (this.inputChangedChildHandler(event, dataKey, children.elementType)) }
                            />

                    } else {
                        inputChildElement =
                            <ButtonForm
                                key={dataKey}
                                buttons={children}
                                typeElement={dataKey}
                                childId={child.id}
                                clicked={this.props.clicked}
                            />
                    }

                    return inputChildElement;
                });

            });

            inputChildElements.push(childs);
            return inputChildElements;

        });

        return (

                <div className="card card-w-title">
                    <h1>{this.props.title}</h1>
                    <div className="p-col-12 form-group">
                        <div className="p-col-12 p-md-12">
                            {inputChildElements}
                        </div>

                    </div>
                </div>

        );

    }

}

export default ChildForm;