import React, {useState, useEffect, useRef} from 'react';

import "../../assets/css/Withdrawal.css";
import { Button } from 'primereact/button';
import CustomSpinner from "../../UI/CustomSpinner"
import { formatMoney } from '../../utils/utils';
import ApiLoan from "../../service/ApiLoanService";
import {InputText} from "primereact/inputtext";
import { Toast } from 'primereact/toast';


const Withdrawal = ({history}) => {

    const [loading, setLoading] = useState(false);
    const [loadingTransactionRequest, setLoadingTransactionRequest] = useState(false);
    const [account, setAccount] = useState({
        amount: 0,
        available_amount: 0,
        prontocobro_tax: 0
    });
    const [money, setMoney] = useState(0);
    const [errorWithdrawl, setErrorWithdrawl] = useState(false);

    const toast = useRef(null);

    useEffect(() => {
        const apiService = new ApiLoan();
        apiService.getResources({
            url: '/my_account'
        }).then(response => {
            
            const formattedAccount = {
                amount: response.data.amount,
                available_amount: response.data.available_amount,
                prontocobro_tax: response.data.prontocobro_tax
            }
            setAccount(formattedAccount);
            setMoney(response.data.available_amount);
            setLoading(false);
        });
    } ,[]);



    const showMessageToast = (message, severity) => {
        toast.current.show({severity: severity, summary: 'Retiro de Dinero', detail: message});
    };

    const generateTransactionRequest = () => {
        setLoadingTransactionRequest(true);
        const apiService = new ApiLoan();
        apiService.postResource({
            url: '/transaction_requests',
            data: {
                amount: Number(money)
            }
        }).then(response => {
            history.push( '/transactions' );
        }).catch(error => {
            if (typeof (error.response.data.message) === "object"){
                let errors = Object.entries(error.response.data.message);
                errors.map(e => {
                    return showMessageToast(e[1][0], 'error');
                });
            } else {
                showMessageToast(error.response.data.message, 'error');
            }

            setLoadingTransactionRequest(false)
        });

    }


    const prontocobroTaxAmount = (money * (account.prontocobro_tax / 100));
    const totalForWithdrawal = money - prontocobroTaxAmount;
    const spinnerIcon = (<img src={"/assets/layout/images/loading.gif"} alt={"Loading Transactions"}/>);
    const transactionRequestButton = (<Button
        className="withdrawal-button"
        label={"Retirar Dinero"}
        onClick={generateTransactionRequest}
    />)
    const transactionRequestComponent = loadingTransactionRequest ? spinnerIcon : transactionRequestButton;



    const setMoneyHandler = (e) => {
        let value = e.target.value;

        if (value > account.available_amount){
            setErrorWithdrawl(true);
        } else {
            if (errorWithdrawl){
                setErrorWithdrawl(false);
            }
            setMoney(value);
        }

    };
    const availableAmount = formatMoney(account.available_amount, 2, ',', '.', '$');
    let footerWithdrawl = 'De '+availableAmount + ' disponible'
    let footerWithdrawlClassName = "withdrawal-footer";

    if (errorWithdrawl){
        footerWithdrawl = "Puedes retirar hasta "+availableAmount;
        footerWithdrawlClassName = "withdrawal-footer invalid";
    }

    const summaryComponent = 
    (<div class="p-grid dashboard">
        <Toast ref={toast} />
        <div class="p-col-12 p-lg-6 p-lg-offset-3">
            <div className="card">
                <div className="withdrawal-amount">
                    <div className="withdrawal-description">Dinero Disponible</div>
                    <div className="withdrawal-digits">{formatMoney(account.available_amount, 2, ',', '.', '$')}</div>
                </div> 
            </div>
        </div>
        <div className="p-col-12 p-lg-6 p-lg-offset-3">
            <div className="card">
                <div className="input-money">
                    <div className="withdrawal-description">¿Cuánto quieres retirar?</div>

                    <div className="withdrawal-digits">
                        $<InputText className='withdrawal-digits' keyfilter={/[\d,\s.]/} value={money}
                                        onChange={(e) => setMoneyHandler(e)} />
                    </div>
                    <div className={footerWithdrawlClassName}>
                        {footerWithdrawl}
                    </div>
                </div>
            </div>
        </div>
        <div class="p-col-12 p-lg-6 p-lg-offset-3">
            <div className="card">
                <div className="withdrawal-amount">
                    <div className="withdrawal-description">Dinero total</div>
                    <div className="withdrawal-digits">{formatMoney(money, 2, ',', '.', '$')}</div>
                </div>
                <div className="withdrawal-amount">
                    <div className="withdrawal-description">Comisión ProntoCobro</div>
                    <div className="withdrawal-digits">{'-' + formatMoney(prontocobroTaxAmount, 2, ',', '.', '$')}</div>
                </div>
                <div className="withdrawal-amount total-amount">
                    <div className="withdrawal-description">Total</div>
                    <div className="withdrawal-digits">{formatMoney(totalForWithdrawal, 2, ',', '.', '$')}</div> 
                </div> 
            </div>
        </div>
        <div class="p-col-12 p-lg-6 p-lg-offset-3">
            <div className="withdrawal-actions">
                {transactionRequestComponent}
            </div>
        </div>
    </div>)

    const body = loading ? <CustomSpinner/> : summaryComponent;

    return (body)
}

export default Withdrawal;