import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

class StringToHtml extends Component {
    render(){
        let html = (
            <React.Fragment>
                <div className="p-col-12">
                    {ReactHtmlParser(this.props.value)}
                </div>
            </React.Fragment>
        );
        return html;
    }
}

export default StringToHtml;