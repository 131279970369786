import React, {useEffect, useState, useContext, Fragment, useRef} from 'react';
import { useHistory } from "react-router-dom";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import './WhatsappWeb.css';
import ApiLoan from "../../service/ApiLoanService";
import { CompanyContext } from "../../context/companyContext"
import CustomSpinner from "../../UI/CustomSpinner";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SweetAlert from "react-bootstrap-sweetalert";
import CircularSpinner from "../../UI/CircularSpinner";
import {Toast} from "primereact/toast";
import ReactTooltip from "react-tooltip";
import {NavLink} from "react-router-dom";

const WhatsappWeb = () => {
    const history = useHistory();
    const [windowLogout, setWindowLogout] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingQr, setLoadingQr] = useState(false);
    const [timeoutLoadingQr, setTimeoutLoadingQr] = useState(false);
    const [data, setData] = useState(null);
    const [qr, setQR] = useState(null);
    const [profilePicThumb, setProfilePicThumb] = useState("");
    const [statusOpenWa, setStatusOpenWa] = useState(null);
    const [statusOpenWaInfo, setStatusOpenWaInfo] = useState(null);
    const companyContext = useContext(CompanyContext);
    const [refresh, setRefresh] = useState(0)
    const [refreshLoading, setLoadingRefresh] = useState(0)
    const refToast = useRef(null);
    const [height, setHeight] = useState(window.innerWidth);
    const socket = companyContext.socket;
    let role = localStorage.getItem('role');

    const isMobile = () => {
        return window.innerWidth <= 750;
    };

    useEffect(() => {
        const updateWindowDimensions = () => {
        const newHeight = window.innerWidth;
        setHeight(newHeight);
    };

        window.addEventListener("resize", updateWindowDimensions);

        return () => window.removeEventListener("resize", updateWindowDimensions);

    }, []);

    const requestApiLoan = () => {
        const apiLoan = new ApiLoan();
        apiLoan.getResources({
            url: '/info/session/open_wa'
        }).then((response) => {
            const data = response?.data;
            if(response?.data?.msg){
                setTimeoutLoadingQr(false);
                setTimeout(() => {
                    setTimeoutLoadingQr(true);
                }, 120000);
                setLoading(true);
                setQR(response?.data?.msg);
            }else{
                if(data.pushname){
                    setLoading(true);
                    setData([data]);
                    setProfilePicThumb(data.profilePicThumb);
                }
            }
        });
    }

    const refreshComponent = () => {
        setLoading(false);
        setData(null);
        setQR(null);
        setStatusOpenWa(null);
        setRefresh(Math.random());
        return "refreshComponent";
    }

    const getRefreshWithTimeout = (time) => {
        return new Promise((resolve)=>{
            setTimeout(() => {
                resolve(refreshComponent());
            }, time);
        })
    }

    useEffect(() => {
        socket.on('update-status-open-wp', updatestatusOpenWa);
        return () => {
            socket.off('update-status-open-wp', updatestatusOpenWa);
        }
    },[companyContext.privateInbox]);

    useEffect(() => {
        requestApiLoan()
    },[history]);

    const updatestatusOpenWa = ({update_status_open_wp}) => {
        setStatusOpenWa(update_status_open_wp);
    }

    const state = (statusOpenWa === 'CONNECTED' || statusOpenWa === 'DISCONNECTED' ? statusOpenWa : null);

    useEffect(() => {
        if (statusOpenWa === 'CONNECTED') {
            setTimeout(() => {
                history.push( '/messenger' );
            }, 1000);
        }
        if (statusOpenWa !== 'CONNECTED') {
            setLoading(false);
            setLoadingRefresh(Math.random());
        } else { 
            setLoading(true);
            setLoadingRefresh(Math.random());
        }
        if (statusOpenWa !== 'DISCONNECTED') {
            setLoading(false);
            setLoadingRefresh(Math.random());
        } else { 
            setLoading(true);
            setLoadingRefresh(Math.random());
        }
        if (statusOpenWa === 'QR') {
            requestApiLoan();
            setLoadingQr(true);
            setRefresh(Math.random());
            setStatusOpenWa(null);
            setTimeout(() => {
                setLoadingQr(false);
                setRefresh(Math.random());
            }, 2000);
        }
    },[statusOpenWa]);

    const qrcode = () => {
        return(
        <div className="qr-backdrop">
            <div className="qr-containter">
                <div className="qr-content">
                    <p className='title-div-center'> <b>Escanea el código QR desde tu Teléfono:</b></p>
                    <div className='qr-separator' >
                    {height >= 1300 && (
                        <div className="text-div">
                        <h4 style={{color: '#7CB342'}}><b>¡Ya estás listo para comenzar!</b></h4>
                        <h4>1. Abre Whatsapp en tu teléfono</h4>
                        <h4>2. Presione Menú y seleccione</h4>
                        <h4>&nbsp;&nbsp;&nbsp;&nbsp;Dispositivos vinculados</h4>
                        <h4>3. Presione Vincular un dispositivo</h4>
                        <h4>4. Cuando se active la cámara, apunte su </h4> 
                        <h4>&nbsp;&nbsp;&nbsp;&nbsp;teléfono hacia la pantalla para escanear el</h4>
                        <h4>&nbsp;&nbsp;&nbsp;&nbsp;código QR</h4>
                        </div>
                    )}
                    </div>
                    <div className="img-div">
                        {!timeoutLoadingQr && (
                            !loadingQr ? qr ? <img className='img-grayscale'
                            key={refresh} alt='qr' src={qr} /> : <div className="center">
                            <CircularSpinner/> </div> : <div className="center">
                            <CircularSpinner/> </div>
                        )}
                        {timeoutLoadingQr && (
                            <div className='containter-center'>
                                <img className='img-grayscale-show'
                                key={refresh} alt='qr' src={qr} />
                                <FontAwesomeIcon className='overlay-button-reset' onClick={refreshSession} style={{cursor: 'pointer'}} icon="fa-solid fa-rotate" color={'#7CB342'} size='2x'/>
                            </div>
                        )}
                    </div>
                
                    <i className="custom-qr-close" onClick={() => confirmLogoutPip()}>
                        <NavLink to={"/logout"} >
                            <i style={{fontSize: '1.5rem'}} className="pi pi-power-off"></i>
                        </NavLink>
                    </i>
                </div>
            </div>
        </div>
        );
    };

    const warningQr = () => {
        return(
        <div className="qr-backdrop">
            <div className="qr-containter">
                <div>
                    <i className="custom-close" onClick={() => confirmLogoutPip()}>
                        <NavLink to={"/logout"} >
                            <i style={{fontSize: '1.5rem'}} className="pi pi-power-off"></i>
                        </NavLink>
                    </i>
                    <div className='containter-center'>
                        <FontAwesomeIcon icon="fa-solid fa-triangle-exclamation" color={'orange'} size='7x'/>
                    </div>
                    <div className='containter-center'>
                        <p>
                            La sesión de su cuenta de WhatsApp se cerró. Para volver a trabajar con Pip! notifique al supervisor o administrador de la cuenta y vuelva a iniciar sesión escaneando el código QR del teléfono.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        );
    };

    useEffect(() => {
        if(state){
            async function getRefreshWithTimeoutAsync(){
                const result = await getRefreshWithTimeout(1000);
                if (result) {
                    requestApiLoan();
                }
            }
            getRefreshWithTimeoutAsync();
        }
    },[state]);

    const refreshSession = () => {
        const apiLoan = new ApiLoan();
        let provider_id = localStorage.getItem('provider_id');
        apiLoan.postResource({
            url: '/status/refresh/open_wa',
            data: { data: 'REFRESH', provider_id: provider_id}
        }).catch((error) => {
            if(error?.response?.status !== 200){
                refToast.current.show({severity: 'error', summary: 'Refrescar Sesión', detail: 'No se puede refrescar'});
            }
        });
        setWindowLogout(false);
    };

    const logoutSession = (status) => {
        if(status){
            const apiLoan = new ApiLoan();
            const info_session = data[0].me;
            apiLoan.postResource({
                url: '/status/update/open_wa',
                data: { data: 'DISCONNECTED', me: info_session}
            }).catch((error) => {
                if(error?.response?.status !== 200){
                    refToast.current.show({severity: 'error', summary: 'Cerrar Sesión', detail: 'No se puede cerrar sesión'});
                }
            });
            setWindowLogout(false);
        }
    };

    const exportContacts = (status) => {
        if(status){
            const apiLoan = new ApiLoan();
            refToast.current.show({severity: 'success', summary: 'Exportar Contactos', detail: 'Cargando Contactos'});
            apiLoan.postResource({
                url: '/clients/contacts/ow/bulk'
            }).catch((error) => {
                if(error?.response?.status !== 200){
                    refToast.current.show({severity: 'error', summary: 'Exportar Contactos', detail: 'No se puede exportar los contactos'});
                }
            });
            setWindowLogout(false);
        }
    };

    const confirmLogoutPip = () => {
        <NavLink to={"/logout"} >
        </NavLink>
    };

    const confirmLogout = () => {
        setWindowLogout(
            <Fragment>
                <SweetAlert
                    info
                    showCancel
                    cancelBtnText={<i class="pi pi-times">&nbsp;No</i>}
                    confirmBtnText={<i class="pi pi-check">&nbsp;Si</i>}
                    title="¡Está seguro!"
                    onConfirm={() => logoutSession(true)}
                    onCancel={() => setWindowLogout(null)}
                    confirmBtnCssClass={
                        "p-button p-component p-button-text-only"
                    }
                    cancelBtnCssClass={
                        "p-button p-component p-button-danger p-button-text-only"
                    }
                >
                    ¿Que quieres cerrar sesión en Whatsapp Web?
                </SweetAlert>
            </Fragment>
        );
    };

    const confirmRefresh = () => {
        setWindowLogout(
            <Fragment>
                <SweetAlert
                    info
                    showCancel
                    cancelBtnText={<i class="pi pi-times">&nbsp;No</i>}
                    confirmBtnText={<i class="pi pi-check">&nbsp;Si</i>}
                    title="¡Está seguro!"
                    onConfirm={() => refreshSession()}
                    onCancel={() => setWindowLogout(null)}
                    confirmBtnCssClass={
                        "p-button p-component p-button-text-only"
                    }
                    cancelBtnCssClass={
                        "p-button p-component p-button-danger p-button-text-only"
                    }
                >
                    ¿Que quieres refrescar la sesión en Whatsapp Web?
                </SweetAlert>
            </Fragment>
        );
    };

    const confirmContacts = () => {
        const apiLoan = new ApiLoan();
        apiLoan.postResource({
            url: '/status/bulk',
            data: {
                'task': 'clients_contacts_ow_bulk'
            }
        }).then(response => {
            if (response.data.status){
                refToast.current.show({severity: 'info', summary: 'Exportar Contactos', detail: 'Carga de Contactos en Proceso'});
            } else{
                setWindowLogout(
                    <Fragment>
                        <SweetAlert
                            info
                            showCancel
                            cancelBtnText={<i class="pi pi-times">&nbsp;No</i>}
                            confirmBtnText={<i class="pi pi-check">&nbsp;Si</i>}
                            title="¡Está seguro!"
                            onConfirm={() => exportContacts(true)}
                            onCancel={() => setWindowLogout(null)}
                            confirmBtnCssClass={
                                "p-button p-component p-button-text-only"
                            }
                            cancelBtnCssClass={
                                "p-button p-component p-button-danger p-button-text-only"
                            }
                        >
                            ¿Que quieres exportar los contactos de Whatsapp Web?
                        </SweetAlert>
                    </Fragment>
                );
            }
        }).catch((error) => {
            if(error?.response?.status !== 200){
                refToast.current.show({severity: 'error', summary: 'Exportar Contactos', detail: 'No se puede exportar los contactos'});
            }
        });;
    };

    const actions = (rowData) => {
        return ( rowData.pushname !== undefined && (
            <div>
                <button data-tip data-for='confirmRefresh' className="p-link" onClick={() => confirmRefresh()}>
                <FontAwesomeIcon icon={"rotate"} style={{background: 'orange'}} color={'white'} size={"xs"} className={"btn-actions btn-fontawesome"}/>
            </button>
            {!isMobile() &&
                <ReactTooltip id="confirmRefresh" place="top" effect="solid">
                    Refrescar sesión
                </ReactTooltip>
            }
            <button data-tip data-for='confirmContacts' className="p-link" onClick={() => confirmContacts()}>
                <FontAwesomeIcon icon={"upload"} style={{background: 'green'}} color={'white'} size={"xs"} className={"btn-actions btn-fontawesome"}/>
            </button>
            {!isMobile() &&
                <ReactTooltip id="confirmContacts" place="top" effect="solid">
                    Importar Contactos
                </ReactTooltip>
            }
            <button data-tip data-for='confirmLogout' className="p-link" onClick={() => confirmLogout()}>
                <FontAwesomeIcon icon={"times"} style={{background: 'red'}} color={'white'} size={"xs"} className={"btn-actions btn-fontawesome"}/>
            </button>
            {!isMobile() &&
                <ReactTooltip id="confirmLogout" place="top" effect="solid">
                    Cerrar sesión
                </ReactTooltip>
            }
            </div>
            )
        )
    }

    const connected = (rowData) => {
        return rowData.pushname !== undefined ? <Button className="p-button-raised p-button-rounded p-button-success" disabled> Conectado </Button> : '-';
    }

    const profile = (rowData) => {
        return (
            <Fragment>
                <img className="photo" alt='profile' src={rowData.phone !== undefined ? decodeURIComponent(profilePicThumb).split('pp?e=').length!==1?decodeURIComponent(profilePicThumb).split('pp?e=')[1]:'assets/layout/images/profile.png': rowData.pushname !== undefined ? profilePicThumb ? profilePicThumb : 'assets/layout/images/profile.png' : 'assets/layout/images/profile.png'} onError={(e) => e.target.src='assets/layout/images/profile.png'}/>
            </Fragment>
        )
    }

    const platform = (rowData) => {
        return <span>{rowData.platform !== undefined ? rowData.platform.charAt(0).toUpperCase() + rowData.platform.slice(1) : '-'}</span>;
    }

    const name = (rowData) => {
        return <span>{rowData.pushname !== undefined ? rowData.pushname : '-'}</span>;
    }

    const line = (rowData) => {
        return <span>{rowData.me !== undefined ? rowData.me.user : '-'}</span>;
    }

    const info = () => {
        return (
            <Fragment>
                <Toast ref={refToast}/>
                {windowLogout}
                <div className="card div">
                    <h3 className='center'>Whatsapp Web</h3>
                    <h6 className='center'>Información de Sesión</h6>
                    <div className="datatable-filter-wa-mobile">
                        <div className="card">
                            <DataTable className="p-datatable-customers" value={data}>
                                <Column field="profilePicThumb" header="Perfil" body={profile}></Column>
                                <Column field="pushname" header="Nombre" body={name}></Column>
                                <Column field="me.user" header="Línea" body={line}></Column>
                                <Column field="platform" header="Plataforma" body={platform}></Column>
                                <Column field="connected" header="Información" body={connected}></Column>
                                <Column field="connected" header="Accion" body={actions}></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }

    useEffect(() => {
        switch (statusOpenWa) {
            case 'REFRESH':
                setStatusOpenWaInfo('Por favor espere, Refrescando...');
                break;
            case 'AUTH':
                setStatusOpenWaInfo('Por favor espere, Conectando...');
                break;
            case 'STARTUP':
                setStatusOpenWaInfo('Por favor espere...');
                break;
            case 'CONNECTED':
                setStatusOpenWaInfo('Conectado');
                break
            case 'DISCONNECTED':
                setStatusOpenWaInfo('Desconectando');
                break
            case 'CHANGE NUMBER':
                setStatusOpenWaInfo('Lo sentimos pero se conecto otro número que no estaba registrado antes. Vuelva a intentarlo con otro número');
            break
          }
    },[statusOpenWa]);

    return(
        <div key={refreshLoading}>
            {loading ? !qr ? data ? info() : role === '4' ? warningQr() : qrcode() : role === '4' ? warningQr() : qrcode() : <CustomSpinner status={statusOpenWaInfo}/>}
        </div>
    )
}

export default WhatsappWeb;