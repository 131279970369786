import React, {useState, useEffect, useRef, useMemo} from 'react';

import { Button } from 'primereact/button';
import ApiLoan from '../../service/ApiLoanService';
import {Toast} from "primereact/toast";

import InputField from '../Forms/InputField';
import {switchDayOfWeek} from '../../shared/daysWeek'
import {Card} from "primereact/card";
import WelcomeMessageForm from "../WelcomeMessages/WelcomeMessageForm";


const AutoReplyForm = (props) => {
    const [data, setData] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [templatesLoading, setTemplatesLoading] = useState(true);
    const [errorAutoReplies, setErrorAutoReplies] = useState([]);


    useEffect(() => {
        const apiService = new ApiLoan();
        apiService.getResources({
            url: '/templates',
            results_per_page: 500
        }).then(response => {
            const objects = response.data.objects;

            let templatesFiltered = objects.filter(x => x.status === 'APPROVED' || !x.whatsapp_template)

            templatesFiltered = templatesFiltered.map(obj => {
                return {
                    'label': obj.title,
                    'value': obj.id
                }
            })

            setTemplates(templatesFiltered);
            setTemplatesLoading(false);
        })

    }, []);

    useEffect(() => {
        const apiService = new ApiLoan();

        apiService.getResources({
            'url': '/auto_replies'
        }).then(response => {
            let objects = response.data.objects;
            if (objects.length === 0){
                refToast.current.show({severity: 'error', summary: 'Respuestas Automáticas',
                    detail: 'No hay plantillas disponibles.'});
            }
            objects.map(obj => {
                let startObj = obj.start.split(':');
                let endObj = obj.end.split(':');
                const start = new Date();
                const end = new Date();
                start.setHours(startObj[0]);
                start.setMinutes(startObj[1]);
                start.setSeconds(0);
                end.setHours(endObj[0]);
                end.setMinutes(endObj[1]);
                end.setSeconds(0);
                obj.start = start;
                obj.end = end;
                delete obj.template;
                return obj;
            });
            setData(objects);
        }).catch(error => {
            refToast.current.show({severity: 'error', summary: 'Respuestas Automáticas',
                detail: 'Error al cargar las plantillas.'});
        })

    }, []);

    const showDay = (day) => {
        return (
            <div>
                {switchDayOfWeek(day)}
            </div>
        );
    }

    const showDate =  (row) => {
        const startDate = <InputField type={"calendar"} value={row.start} onChange={(event) => handleChange(event, 'start', row)}
                                      traduction={"Desde"} readOnlyInput timeOnly showTime />
        const endDate = <InputField type={"calendar"} value={row.end} onChange={(event) => handleChange(event, 'end', row)}
                                    traduction={"Hasta"} readOnlyInput timeOnly showTime />

        return (
            <div className={"p-d-flex"}>
                <div className={"p-mr-2"}>{startDate}</div>
                <div className={"p-mr-2"}>{endDate}</div>
            </div>
        )
    }
    const showActive = (row) => {
        const activeField = <InputField type={'switch'} value={row.active}
                                        onChange={(event) => {handleChange(event, 'active', row)}} />

        return (
            <div className={"p-d-flex"}>
                <h5 className={"p-mt-3"}>Habilitado</h5>
                <div className={"p-ml-2 p-mt-2"}>{activeField}</div>
            </div>
        )
    }

    const showTemplate = (row) => {
        const templateDropdown = <InputField type={'dropdown'} value={row.template_id} traduction={'Plantilla'}
                                             options={templates} label={'label'}
                                             onChange={(event) => {handleChange(event, 'template_id', row)}} />
        return (

              <div className={"p-ml-1"}>
                {templateDropdown}
              </div>

        );
    }

    const hours = () =>  {
        let options = [];

        for (let i = 0; i <= 72; i+=4){
            let value = i.toString()
            let label = value + ' horas';
            if (value === '0'){
                label = 'Siempre';
            }

            options.push({
                'value': value,
                'label': label
            });
        }
        return options;
    }

    const memoTimeRangeOptions = useMemo(hours, []);

    const showTimeRange = (row) => {
        let option_value = row.hour_range !== null ? row.hour_range.toString() : '0';
        const hoursDropdown = <InputField type={'dropdown'} value={option_value} traduction={'Repetir cada'}
                                             options={memoTimeRangeOptions} label={'label'}
                                             onChange={(event) => {handleChange(event, 'hour_range', row)}} />
        return (
            <div className={"p-ml-1"}>
                {hoursDropdown}
            </div>
        );
    }

    const handleChange = (event, field, row) => {
        setData((prevState) => {
            const newState = prevState.filter(x => x.id === row.id)[0];
            const index = prevState.findIndex(x => x.id === row.id);
            newState[field] = event.value;
            prevState[index] = newState

            return [
                ...prevState
            ]
        });

        setErrorAutoReplies([]);
    }

    const showScheduler = () => {
        return data.map((row, index) => {
            return (
                   <div className={"p-d-flex p-flex-wrap p-fluid"} key={index}>
                       <div className={"p-field p-col-12"} style={{ width: '80px'}}>
                           {showDay(row.day)}
                       </div>
                       <div className={"p-field p-col-12 p-md-3 p-lg-3"}>
                           {showDate(row)}
                       </div>
                       <div className={"p-field p-col-12 p-md-3 p-lg-3"}>
                           {showTemplate(row)}
                       </div>
                       <div className={"p-field p-col-12 p-md-3 p-lg-3"}>
                           {showTimeRange(row)}
                       </div>
                       <div className={"p-field p-col-12 p-md-2 p-lg-2"}>
                           {showActive(row)}
                       </div>
                       <div>
                           {showError(row.day)}
                       </div>
                   </div>
           );
        });

    }

    const showError = (day) => {
        let error = errorAutoReplies.length !== 0 ? errorAutoReplies.filter(x => x.day === day)[0] : undefined;

        if (error !== undefined){
            let error_messages = error.errors;
            let error_keys = Object.keys(error_messages)

            error_keys.map(k => {
                return refToast.current.show({severity: 'error', summary: 'Respuestas Automáticas',
                    detail: error_messages[k]});
            })
        }
    }

    const handleSubmit = (event) => {
        const apiService = new ApiLoan();

        const formData = {
            data
        }
        apiService.patchResource({
            'url': 'auto_replies',
            'data': formData
        }).then(response => {
            if (response.status === 200){
                refToast.current.show({severity: 'success', summary: 'Respuestas Automáticas',
                    detail: 'Se actualizó correctamente.'});
            }
        }).catch(error => {
            let message_error = error.response.data.message;
            setErrorAutoReplies(message_error)

            refToast.current.show({severity: 'error', summary: 'Respuestas Automáticas',
                detail: 'Error al guardar.'});
        });
    }

    const refToast = useRef(null);
    const welcomeMessageForm = !templatesLoading ? <WelcomeMessageForm templates={templates}/> : null;
    return (
        <div>
            <Card title={'Respuestas Automáticas'}>
                <Toast ref={refToast}/>
                {showScheduler()}
                <div className={"p-grid p-fluid"}>
                    <div className="p-field p-col-12">
                        <Button label={'Guardar'} onClick={(e) => handleSubmit(e)}  />
                    </div>
                </div>
            </Card>
            {welcomeMessageForm}
        </div>
    )



}

export default AutoReplyForm;