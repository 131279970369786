import React, {Component} from 'react'
import ApiLoan from '../../service/ApiLoanService';
import Attribute from './Attribute';
import AssociatedAttribute from './AssociatedAttributes';
import ResourceAccordion from './ResourceAccordion';
import {Checkbox} from 'primereact/checkbox';
import { Card } from 'primereact/card';
import CheckboxForm from '../../UI/CheckboxForm';
import CustomComponent from '../../UI/CustomComponent';
import MoneyField from '../../UI/MoneyField';
import DateField from '../../UI/DateField';
import {switchDayOfWeek} from "../../shared/daysWeek";
import {calculateNextExecution} from "../../shared/utility"

class Resource extends Component {

    _isMounted = false;

    constructor(props){
        super();
        this.getObjectFromFields = this.getObjectFromFields.bind(this);
        this.state = {
            loading: true,
            fields: props.fields,
            resource: this.getObjectFromFields(props.fields)
        };
        this.apiService = new ApiLoan();
    }


    getObjectFromFields(fields){
        let object = {};

        fields.forEach(function(field) {
            object[field.name] = '';
        });

        return object
    }


    componentDidMount(){
        this._isMounted = true;

        this.apiService.getResource({
            url: this.props.url,
            resource_id: this.props.resource_id
        }).then(response => {
            if (this._isMounted) {
                let fetched_resource = {};
                for(let field in response.data){
                    if (this.state.resource.hasOwnProperty(field)){
                        fetched_resource[field]= response.data[field];
                    }
                }
                this.setState({
                    resource: fetched_resource,
                    loading: false
                });
            }
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    generateFields(fields){
        return fields.map((field) => {
            let fieldType;
            let component_field;
            if (!('accordion' in field)){
                if(field.component){
                    component_field = (<CustomComponent name={field.component.name} component_props={field.component.props} value={this.state.resource[field.name]} key={field.name}/>)
                } else {
                    if (field.checkbox){
                        fieldType = field.checkbox['updateable'] ?  <CheckboxForm url={this.props.url} resource_id={this.props.resource_id} checked={this.state.resource[field.name]}/> : <Checkbox checked={this.state.resource[field.name]}  disabled={true} />
                    }
                    else if (field.daysWeek) {
                        fieldType = switchDayOfWeek(this.state.resource[field.name])
                    }
                    else if (field.cron) {
                        fieldType = calculateNextExecution(this.state.resource[field.name])
                    }
                    else {
                        fieldType = this.state.resource[field.name];
                        if(field.format && field.format === 'money'){
                            fieldType = <MoneyField>{this.state.resource[field.name]}</MoneyField>
                        }else if(field.format && field.format === 'date'){
                            fieldType = <DateField>{this.state.resource[field.name]}</DateField>
                        }
                    }
                    component_field = (
                        <Attribute name={field.name} traduction={field.traduction} key={field.name}>
                            {fieldType}
                        </Attribute>
                    );
                }
                
            } else {
                let urlResource = this.props.url + this.props.resource_id + '/' + field.name;
                if(field.accordion){
                    component_field = (<ResourceAccordion fieldUrl={urlResource} title={field.traduction} accordionTitle={field.accordionTitle} colsHeader={field.colsHeader} key={field.name} />);
                }else{
                    component_field = (<AssociatedAttribute url={urlResource} fields={field.fields} key={field.name}/>);
                }     
            }
            if(field.card){
                component_field = (<div className="p-col-12 jo" key={field.name}>
                                        <Card title={field.card.title}>
                                            <div className="p-grid">
                                                {component_field}
                                            </div>
                                        </Card>
                                    </div>);
            }
            return component_field
        });
    }


    render(){
        let dependent_fields = null;
        let independent_fields = null;

        if (!this.state.loading){
            dependent_fields = this.state.fields.filter((field)=> !(field.card));
            independent_fields = this.state.fields.filter((field)=> (field.card));
            dependent_fields = this.generateFields(dependent_fields);
            independent_fields = this.generateFields(independent_fields);
        }


        return (
            <div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <Card title={this.props.title}>
                            <div className="p-grid">
                                {dependent_fields}
                            </div>
                        </Card>
                    </div>
                    {independent_fields}
                </div>
            </div>
        );
    }

}

export default Resource;