import React, {useState, Fragment, useRef} from 'react';
import {Button} from "primereact/button";
import {Menu} from "primereact/menu";
import DialogGeneric from "../../../UI/DialogGeneric";


const ClientPaymentButtons = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [modalInfo, setModalInfo] = useState(null);

    let PaymentItems = [
        {label: 'Realizar Pago', icon: 'pi pi-fw pi-plus', command:(e) => { makePayment(e) }},
        {label: 'Revertir Pago', icon: 'pi pi-fw pi-trash', command:(e) => { revertPayment(e) }},
    ];

    const makePayment = (event) => {
        const loan_id = props.selectedLoan;

        const value_dialog = {
            'url': '/installment/generate_payment',
            'method': 'post',
            'header': 'Realizar un Pago',
            'submit_label': 'Realizar Pago',
            'sumaryToast': 'Realizar Pago',
            'fields': [
                {
                    name: 'installment_id',
                    elementType: 'dropdown',
                    elementConfig: {
                        options: null,
                        placeholder: 'Seleccione una cuota',
                        traduction: 'Seleccione una cuota',
                    },
                    value: '',
                    requestResource: {
                        request:{
                            url: '/installments/not/paid/'+loan_id,
                            params: {}
                        },
                        format: {
                            value: 'id',
                            label: 'number'
                        }
                    },
                    elementClass: 'p-col-10'
                },
                {
                    name: 'total_debt',
                    elementType: 'installmentTotalDebt',
                    elementConfig: {
                    },
                    value: '',
                    dependence_of: ['installment_id'],
                    elementClass: 'p-col-10'
                },
                {
                    name: 'amount',
                    elementType: 'text',
                    elementConfig: {
                        keyfilter: 'pnum',
                        traduction: 'Ingrese monto'
                    },
                    dependence_of: ['total_debt'],
                    value: '',
                    elementClass: 'p-col-10'
                },
                {
                    name: 'payment_date',
                    elementType: 'calendar',
                    elementConfig: {
                        readonlyInput: true,
                        dateFormat: 'dd/mm/yy',
                        maxDate: new Date(),
                        minDate: null,
                        traduction: 'Ingrese fecha de pago',
                    },
                    value: null,
                    elementClass: 'p-col-10'
                },

            ],
        }
        setShowModal(true);

        setModalInfo(<DialogGeneric visible={true} setVisible={setShowModal} {...value_dialog} closeModal={true}/>);

    }

    const revertPayment = (event) => {
        const loan_id = props.selectedLoan;

        const value_dialog = {
            'url': '/installment/revert_payments',
            'method': 'post',
            'header': 'Revertir Pagos',
            'submit_label': 'Revertir Pagos',
            'sumaryToast': 'Revertir Pago',
            'fields': [
                {
                    name: 'installment_id',
                    elementType: 'dropdown',
                    elementConfig: {
                        options: null,
                        placeholder: 'Seleccione una cuota',
                    },
                    value: '',
                    traduction: 'Cuotas',
                    requestResource: {
                        request:{
                            url: '/installments/payments/'+loan_id,
                            params: {}
                        },
                        format: {
                            value: 'id',
                            label: 'number'
                        }
                    }
                },
                {
                    name: 'total_paid',
                    elementType: 'installmentTotalPaid',
                    elementConfig: {
                    },
                    value: '',
                    dependence_of: ['installment_id']
                },

            ],
        }
        setShowModal(true);

        setModalInfo(<DialogGeneric visible={true} setVisible={setShowModal} {...value_dialog} closeModal={true}/>);

    }
    const refMenu = useRef(null);
    const buttons = (
        <div className="p-col-4 p-offset-6">
            <div>
                <Menu
                    model={PaymentItems}
                    ref={refMenu}
                    popup={true}
                />
                <Button
                    label="Pagos"
                    icon="pi pi-chevron-down"
                    iconPos="right"
                    className="p-button-success"
                    onClick={event => refMenu.current.toggle(event)}
                />
            </div>
        </div>

    );
    const render_modal = showModal ? modalInfo : null;

    return (
        <Fragment>
            {buttons}
            {render_modal}
        </Fragment>);
}

export default ClientPaymentButtons;