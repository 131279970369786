import React from 'react';
import './ToolbarButton.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function ToolbarButton(props) {
    const { icon, name, onClick, color, shake, children } = props;

    return (
      <span data-tip={name} className={"toolbar-button fa-layers fa-fw"} style={{color:color}} onClick={onClick}>
        <FontAwesomeIcon icon={icon} size='xs' style={{width: '2em', height: '2em'}} shake={shake} />
          {children}
      </span>
    );
}
