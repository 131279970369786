import update from "immutability-helper";

export const updateObject = (oldObject, updatedValues) => {
    return {
        ...oldObject,
        ...updatedValues
    }
};

const acceptedMimeTypes = {
    'document': [
        'text/csv',
        'text/plain',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ],
    'image': [
        'image/png',
        'image/jpeg'
    ],
    'audio': [
        'video/mpeg'
    ],
    'video': [
        'video/webm',
        'video/mp4'
    ]
}

export const convertMimetypeType = (mimeType) => {
    let selectMessageType = null
    let messageTypes = Object.keys(acceptedMimeTypes);
    for(let i=0; i< messageTypes.length; i++){
      let messageType = messageTypes[i];
      acceptedMimeTypes[messageType].map((element) =>{
        if (mimeType === element) selectMessageType = messageType
      })
    }
    return selectMessageType
};

export const messageErrorObject = (oldObject, messageFieldError) => {
    for (let message in messageFieldError){
        if (typeof messageFieldError[message] === 'object'){
            messageFieldError[message].map((msg, index) => {
                if (typeof(msg) === 'string'){
                    let errorForm = update(oldObject, {
                        [message]: {
                            elementConfig: {
                                error: {$set: msg}
                            }
                        }
                    });
                    oldObject = errorForm;
                } else {
                    if (Object.entries(msg).length !== 0){
                        Object.keys(msg).map((key,indexKey) => {
                            if (oldObject[message]['value'] !== undefined){
                                if (oldObject[message]['value'][index] !== undefined){
                                    let find_child = oldObject[message]['value'][index].findIndex(x => x.name === key);
                                    if (typeof(msg[key][indexKey]) !== 'object'){
                                        let errorForm = update(oldObject, {
                                            [message]: {
                                                value: {
                                                    [index]: {
                                                        [find_child]: {
                                                            elementConfig: {
                                                                error: {$set: msg[key][indexKey]}
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        });
                                        oldObject = errorForm;
                                    } else {
                                        let childObject = msg[key][indexKey];
                                        let errorObject = [];
                                        Object.keys(childObject).map((keyObject) => {

                                            let error = {
                                                [keyObject]: childObject[keyObject][0]
                                            };
                                            errorObject = [
                                                ...errorObject,
                                                error
                                            ]
                                        });
                                        let errorForm = update(oldObject, {
                                            [message]: {
                                                value: {
                                                    [index]: {
                                                        [find_child]: {
                                                            elementConfig: {
                                                                error: {$set: errorObject}
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        });
                                        oldObject = errorForm;

                                    }
                                } else {

                                    if (typeof(msg[key][indexKey]) !== 'object'){
                                        let errors = {};

                                        Object.keys(msg).map((keyObject) => {
                                            errors[keyObject] = msg[keyObject][0];
                                        });

                                        let errorForm = update(oldObject, {
                                            [message]: {
                                                elementConfig: {
                                                    error: {$set: errors}
                                                }
                                            }
                                        });
                                        oldObject = errorForm;
                                    }
                                }

                            }
                            return oldObject;
                        });
                    }
                }
            });
        } else if (typeof messageFieldError[message] === 'string'){
            return null;
        }
    }

    return {
        ...oldObject
    }
};


export const messageProviderErrorObject = (oldProviderObject, oldProviderPaymentServiceError, messageFieldError) => {
    for (let message in messageFieldError){
        if (typeof messageFieldError[message] === 'object'){
            messageFieldError[message].map(msg => {
                if (typeof(msg) === 'string'){
                    let errorForm = update(oldProviderObject, {
                        [message]: {
                            elementConfig: {
                                error: {$set: msg}
                            }
                        }
                    });
                    oldProviderObject = errorForm;

                } else {

                    if (Object.entries(msg).length !== 0){
                        Object.keys(msg).map((key, indexKey) => {
                            let field_error = {};
                            field_error[key] = msg[key][0];
                            let errorForm = update(oldProviderPaymentServiceError, {
                                errorProviderPaymentService: {
                                    $push: [field_error]
                                }
                            });
                            oldProviderPaymentServiceError = errorForm;

                            return oldProviderPaymentServiceError;
                        });
                    }
                }
            });
        }
    }

    return {
        ...oldProviderObject,
        ...oldProviderPaymentServiceError
    }
};

export const calculateNextExecution = (cronExpression) => {
    const currentDate = new Date();
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const [minutes, hours] = cronExpression.split(' ');

    const nextExecution = new Date(currentDate);
    nextExecution.setHours(parseInt(hours, 10));
    nextExecution.setMinutes(parseInt(minutes, 10));
    nextExecution.setSeconds(0);

    if (nextExecution <= currentDate) {
      nextExecution.setDate(nextExecution.getDate() + 1);
    }

    const nextExecutionString = `${daysOfWeek[nextExecution.getDay()]} ${months[nextExecution.getMonth()]} ${nextExecution.getDate()} ${nextExecution.getFullYear()} ${nextExecution.getHours().toString().padStart(2, '0')}:${nextExecution.getMinutes().toString().padStart(2, '0')}:00 GMT${nextExecution.getTimezoneOffset() > 0 ? '-' : '+'}${Math.abs(nextExecution.getTimezoneOffset() / 60)}`;

    return nextExecutionString;
  };
