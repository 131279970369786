import React, {useState, useEffect}  from 'react';

import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import { Card } from 'primereact/card';
import CustomSpinner from "../../UI/CustomSpinner";

import DateField from '../../UI/DateField';

import ApiLoan from "../../service/ApiLoanService";

import InstallmentView from '../Installments/InstallmentView';

const PaymentOrderStatuses = ({installmentId}) => {

    const [installment, setInstallment] = useState({
        "due_date": null,
        "amount": null,
        "interest": null
    });
    const [payment_statuses, setPaymentStatuses] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [first, setFirst] = useState(0);

    useEffect(() => {
        const apiService = new ApiLoan();
        apiService.getResource({
            url: '/installments/',
            resource_id: installmentId
        }).then(response => {
            setInstallment({
                due_date: response.data.due_date,
                amount: response.data.amount,
                interest: null
            })
        })
    }, [installmentId]);

    useEffect(() => {
        const apiService = new ApiLoan();
        apiService.getResources({
            url: '/pay_orders',
            filters: [{"name": "installment_id", "op": "eq", "val": installmentId}],
            order_by: [{"field": "created", "direction": "desc"}]

        }).then(response => {
            let {objects} = response.data 
            objects.map((payOrder => {
                return {
                    created: payOrder.payment_status.created,
                    description: payOrder.payment_status.description,
                    payment_status_type: payOrder.payment_status.payment_status_type.slug,
                    debit_date: payOrder.debit_date
                }
            }));
            setPaymentStatuses(objects);
            setTotalResults(response.data.num_results);
            setFirst(0);
        })
    }, [installmentId]);


    const onPage = (event) => {
        const apiService = new ApiLoan();
        const { page } = event;

        return apiService.getResources({
            url: '/pay_orders',
            filters: [{"name": "installment_id", "op": "eq", "val": installmentId}],
            page: page + 1,
            order_by: [{"field": "created", "direction": "desc"}]
        }).then(response => {
            let {objects} = response.data
            objects.map((payOrder => {
                return {
                    created: payOrder.payment_status.created,
                    description: payOrder.payment_status.description,
                    payment_status_type: payOrder.payment_status.payment_status_type.slug,
                    debit_date: payOrder.debit_date
                }
            }));
            setPaymentStatuses(objects);
            setFirst(event.first);
        });
    }

    const sanitized_payment_statuses = payment_statuses.map(payment => {
        return {
            created: payment.created,
            description: payment.payment_status.description,
            payment_status_type: payment.payment_status.payment_status_type.slug,
            debit_date: payment.debit_date === null ? '' : payment.debit_date
        }

    })

    const setPaymentStatusColor = (rowData, Column) => {
        
        const {payment_status_type} = rowData;
        let style = {}

        switch(payment_status_type){
            case 'new':
                style.color = '#003ed2'
                break;
            case 'done':
                style.color = '#ca4434' 
                break;
            case 'rejected':
                style.color = '#d20000'
                break;
            case 'expired':
                style.color = '#790a0a'
                break;
            case 'pending':
                style.color = '#b98c03'
                break;
            default:
                break;
        }


        return <span style={style}>{rowData.description}</span>
    }

    const setDateFormat = (rowData, column) => {
        const {created} = rowData;
        return (<DateField>{created}</DateField>); 
    } 

    const payOrderStatuses = (<Card>
                                <InstallmentView dueDate={installment.due_date} amount={installment.amount} interestAmount={installment.interest}/>
                                <DataTable value={sanitized_payment_statuses} paginator totalRecords={totalResults} rows={10} lazy
                                           onPage={(e) => onPage(e)} first={first}  >
                                    <Column field="created" header="Fecha" body={setDateFormat} />
                                    <Column field="description" header="Descripción" body={setPaymentStatusColor} />
                                    <Column field="debit_date" header="Fecha Débito" />
                                </DataTable>
                            </Card>)


    const spinner = (<Card>
                        <div className="p-fluid">
                            <div className="p-grid">
                                <div className="p-col-11">
                                    <CustomSpinner/>
                                </div>
                            </div>
                        </div>
                    </Card>);

    return (<React.Fragment>
                {(payment_statuses && payOrderStatuses) || spinner}
            </React.Fragment>);
}


export default PaymentOrderStatuses;