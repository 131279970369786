import React, { useEffect, useState } from "react";
import ApiLoan from "../../../service/ApiLoanService";
import { MultiSelect } from "primereact/multiselect";

import "../../../assets/css/MultiSelectFilter.css";

const MultiSelectFilter = ({filter, dt, field, value, selectedItemsLabel, maxSelectedLabels}) => {
	const [resource, setResource] = useState([]);
	const [options, setOptions] = useState([]);
	const [loading, setLoading] = useState(true);


	useEffect(() => {
		let ignore = false;
		let dataOptions = [];
		let filter_params = filter.params;
		getDropdownResources(dataOptions, filter_params);
		return () => {
			ignore = true;
		};
	}, [filter]);

	const getDropdownResources = (dataOptions, filter_params) => {
		const apiService = new ApiLoan();

		filter_params['results_per_page'] = 40
		apiService.getResources(filter_params).then(response => {
			let response_data = response.data;
			if (response_data.objects !== undefined) {
				response_data = response.data.objects;
			}
			for (let key in response_data) {
				dataOptions.push({
					value: response_data[key][filter.value],
					label: response_data[key][filter.label]
				});
			}

			if (response.data.page === response.data.total_pages){
				setOptions(dataOptions);
				setLoading(false);
			} else {
				filter_params['page'] = filter_params.page + 1;
				getDropdownResources(dataOptions, filter_params);
			}
		})

	}

	const onMultiSelectChange = event => {
		dt.filter(event.value, field, "any");
		setResource(event.value);
	};

	const itemTemplate = option => {
		return (
			<div className="p-clearfix">
				<span style={{ fontSize: "1em", float: "right", marginTop: "4px" }}>
					{option.label}
				</span>
			</div>
		);
	};

	const selectItemTemplate = value => {
		if (value) {
			return (
				<div className="my-multiselected-item-token">
					<span>{value}</span>
				</div>
			);
		} else {
			return <span className="my-multiselected-empty-token">Buscar...</span>;
		}
	};

	let resourceFilter = null;

	if (!loading) {
		let values = resource.length !== 0 ? resource : value;
		const selectedItemsLabelFields = selectedItemsLabel !== undefined ? selectedItemsLabel : '{0} items seleccionados'
		const maxSelectedLabelsFields = maxSelectedLabels !== undefined ? maxSelectedLabels : 4;

		resourceFilter = (
			<MultiSelect
				value={values}
				options={options}
				onChange={onMultiSelectChange}
				style={{
					minWidth: "2.5em",
					pMultiselectItems: { paddingLeft: "0px" }
				}}
				filter={true}
				placeholder="Buscar..."
				itemTemplate={itemTemplate}
				selectedItemTemplate={selectItemTemplate}
				className="p-column-filter multiselect-filter-datatable"
				selectedItemsLabel={selectedItemsLabelFields}
				maxSelectedLabels={maxSelectedLabelsFields}
				emptyFilterMessage={"Sin resultados"}
			/>
		);
	}
	return resourceFilter;
};

export default MultiSelectFilter;
