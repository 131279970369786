import React, {useEffect, useState, Fragment, useRef} from "react";

import {InputText} from "primereact/inputtext";
import {InputSwitch} from "primereact/inputswitch";


const WhatsappGatewayService = (props) => {
    const [loading, setLoading] = useState(true);
    const [getGateway, setGateway] = useState('');

    useEffect(() => {
        if (props.gateway !== getGateway){
            cleanValueHandler(props.value)
            setGateway(props.gateway)
            setLoading(false)
        }

    }, [props.gateway, props.value]);

    useEffect(() => {
        if (props.gateway !== getGateway && !loading){
            setLoading(false);
        }
    }, [props.gateway]);

    const canParseJson = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    const inputChangeHandler = (event, field) => {
        let field_key = Object.keys(field)[0];

        let value = props.value;

        if  (canParseJson(value)) {
            value = JSON.parse(props.value);
        }

        value[field_key] = event.target.value;

        props.onChange({value: value});
    };

    const cleanValueHandler = (value) => {
        props.onChange({value: value});
    };

    const getGatewayForm = gateway => {
        let form = null;
        let value = props.value;


        let elementClass = "";

        if  (canParseJson(props.value)) {
            value = JSON.parse(props.value);
        }

        switch (gateway) {
            case 'CLOUD-API':
                if (Object.keys(value).length === 0){
                    value = {
                        api_key: '',
                        waba_id: '',
                        phone_id: '',
                        app_id: ''
                    }
                    cleanValueHandler(value);
                }

                form = (
                    <Fragment>
                        <div className="p-col-12 form-group">
                            <div className="p-grid">
                                <div className={elementClass || "p-col-12 p-md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={value.api_key}
                                            onChange={(e) => inputChangeHandler(e, {api_key: value.api_key})}
                                            className={props.error && 'p-error'}
                                        />
                                        <label htmlFor="input">Api Key</label>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 form-group">
                            <div className="p-grid">
                                <div className={elementClass || "p-col-12 p-md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={value.waba_id}
                                            onChange={(e) => inputChangeHandler(e, {waba_id: value.waba_id})}
                                            className={props.error && 'p-error'}
                                        />
                                        <label htmlFor="input">Waba ID</label>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 form-group">
                            <div className="p-grid">
                                <div className={elementClass || "p-col-12 p-md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={value.phone_id}
                                            onChange={(e) => inputChangeHandler(e, {phone_id: value.phone_id})}
                                            className={props.error && 'p-error'}
                                        />
                                        <label htmlFor="input">Phone ID</label>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 form-group">
                            <div className="p-grid">
                                <div className={elementClass || "p-col-12 p-md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={value.app_id === undefined ? '' : value.app_id}
                                            onChange={(e) => inputChangeHandler(e, {app_id: value.app_id})}
                                            className={props.error && 'p-error'}
                                        />
                                        <label htmlFor="input">APP ID</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                );
                break;
            case 'DIALOG_360':
                if (Object.keys(value).length === 0){
                    value = {
                        api_key: '',
                        waba_id: '',
                        namespace: ''
                    }
                    cleanValueHandler(value);
                }

                form = (
                    <Fragment>
                        <div className="p-col-12 form-group">
                            <div className="p-grid">
                                <div className={elementClass || "p-col-12 p-md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={value.api_key}
                                            onChange={(e) => inputChangeHandler(e, {api_key: value.api_key})}
                                            className={props.error && 'p-error'}
                                        />
                                        <label htmlFor="input">Api Key</label>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 form-group">
                            <div className="p-grid">
                                <div className={elementClass || "p-col-12 p-md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={value.waba_id}
                                            onChange={(e) => inputChangeHandler(e, {waba_id: value.waba_id})}
                                            className={props.error && 'p-error'}
                                        />
                                        <label htmlFor="input">Waba ID</label>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 form-group">
                            <div className="p-grid">
                                <div className={elementClass || "p-col-12 p-md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={value.namespace}
                                            onChange={(e) => inputChangeHandler(e, {namespace: value.namespace})}
                                            className={props.error && 'p-error'}
                                        />
                                        <label htmlFor="input">Namespace</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                );
                break;
            case 'OPEN-WA':
                if (Object.keys(value).length === 0){
                    value = {
                        license: '',
                        beta: true
                    }
                    cleanValueHandler(value);
                }

                form = (
                    <Fragment>
                        <div className="p-col-12 form-group">
                            <div className="p-grid">
                                <div className={elementClass || "p-col-12 p-md-8"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={value.license}
                                            onChange={(e) => inputChangeHandler(e, {license: value.license})}
                                            className={props.error && 'p-error'}
                                        />
                                        <label htmlFor="input">Licencia</label>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="p-col-12 form-group">
                            <div className="p-grid">
                                <div className={elementClass || "p-col-12 p-md-8"}>
                                    <label htmlFor="input">Beta</label>
                                    <span className="p-float-label">
                                        <InputSwitch id="beta"  disabled={true} checked={true} onChange={(e) => inputChangeHandler(e, {beta: value.beta})} />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                );

                break;
            case 'WABOXAPP':
                form = null;
                break;
            default:
                break;

        }
        return form;
    };

    if (!loading) {
        if (props.gateway === getGateway){
            return getGatewayForm(getGateway);
        } else {
            return null
        }

    } else {
        return null;
    }
};

export default WhatsappGatewayService;