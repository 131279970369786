import React, { Component, Fragment } from 'react';
import {EMAIL, SMS, VOICE, LETTER, WHATSAPP} from "../../../shared/channelTypes";
import {InputText} from "primereact/inputtext";
import {Message} from "primereact/message";
import { Editor } from 'primereact/editor';
import CKEditor from "react-ckeditor-component";
import TemplateWhatsapp from "../../Templates/TemplateWhatsapp";
import {Button} from "primereact/button";
import ApiLoanService from "../../../service/ApiLoanService";

class NotificationBody extends Component {

    state = {
        whatsapp_enabled: false,
        template_title: '',
        search_title: false,
        tags: []
    }

    inputChangeHandler = (event, field) => {
        let values = {...this.props.value};
        values[Object.keys(field)[0]] = event.target.value;
        this.props.onChange({value: values, field});
    };

    inputChangeTemplateHandler = (field) => {
        let values = {...this.props.value};
        values['body'] = field.body;
        values['template_uuid'] = field.template_uuid;

        this.setState({
            template_title: field.template_title
        })
        this.props.onChange({value: values, field});
    };

    inputEditorCKChangeHandler = (event, field) => {
        let values = {...this.props.value};
        values[Object.keys(field)[0]] = event.editor.getData();
        this.props.onChange({value: values, field});
    };

    inputEditorChangeHandler = (event, field) => {
        let values = {...this.props.value};
        values[Object.keys(field)[0]] = event.textValue;
        this.props.onChange({value: values, field});
    };

    renderHeaderEditor = () => {
        return (
            <span className="ql-formats">
            </span>
        );
    };

    getCkEditor = (body) => {
        return (
            <CKEditor
                content={body}
                events={{
                    "change": (e) => this.inputEditorCKChangeHandler(e, {body: body})
                }}
                config={{
                    allowedContent: true,
                    language: 'es',
                    toolbarGroups: [
                        { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
                        { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
                        { name: 'links', groups: [ 'links' ] },
                        { name: 'insert', groups: [ 'insert' ] },
                        { name: 'forms', groups: [ 'forms' ] },
                        { name: 'tools', groups: [ 'tools' ] },
                        { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
                        { name: 'others', groups: [ 'others' ] },
                        '/',
                        { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
                        { name: 'styles', groups: [ 'styles' ] },
                        { name: 'colors', groups: [ 'colors' ] },
                        { name: 'about', groups: [ 'about' ] }
                    ],
                    removeButtons: 'Subscript,Superscript,PasteFromWord,Scayt,About,Image'

                }}
            />
        );
    };

    getWhatsappForm = (e) => {
        e.preventDefault();
        this.setState({whatsapp_enabled: !this.state.whatsapp_enabled})
    }

    setActive = () => {
        this.setState({whatsapp_enabled: !this.state.whatsapp_enabled})
    }

    getWhatsappTitle = (body) => {
        const apiService = new ApiLoanService()
        apiService.getResources({
            url: '/templates',
            filters: [
                { name: "whatsapp_template", op: "eq", val: true },
                { name: "body", op: "eq", val: body}
            ]
        }).then(response => {
            if (response.data.objects.length === 1){
                let title = response.data.objects[0].title
                this.setState({
                    template_title: title
                });
            }
        });
    }

    getChannelForm = (channel_id) => {
        let form = null;
        let errorMessage = undefined;
        let errorMessageSubject = undefined;
        let errorMessageBody = undefined;
        let elementClass = "";

        let headerEditor = this.renderHeaderEditor();
        if (this.props.error !== undefined){
            errorMessage = true;
            if (Object.keys(this.props.error).length > 0){
                errorMessageBody = this.props.error.body !== undefined ? this.props.error.body : undefined;
                errorMessageSubject = this.props.error.subject !== undefined ? this.props.error.subject : undefined;
            }
        }

        if (errorMessage === undefined){
            elementClass = "p-col-12 p-md-12"
        }

        let subject = '';
        let body = '';
        let template_uuid = '';

        if (this.props.value !== undefined) {
            body = this.props.value['body'] !== undefined ? this.props.value['body'] : '';
        }

        switch(channel_id) {
            case EMAIL:

                if (this.props.value !== undefined){
                    subject = this.props.value['subject'] !== undefined ? this.props.value['subject'] : '';
                }

                form = (
                    <Fragment key={'email'}>
                        <div className="p-col-12 form-group">
                            <div className="p-grid">
                                <div className={elementClass || "p-col-12 p-md-9"}>
                                    <span className="p-float-label">
                                        <InputText
                                            value={subject}
                                            onChange={(e) => this.inputChangeHandler(e, {subject: subject})}
                                        />
                                        <label htmlFor="input">Asunto</label>
                                    </span>
                                </div>
                                {errorMessage !== undefined && errorMessageSubject !== undefined &&
                                (<div className="p-col-12 p-md-3">
                                    <Message severity="error" text={errorMessageSubject} style={{ float: 'right', marginBotton: '30px', paddingLeft: '.5em' }}/>
                                </div>)
                                }
                            </div>
                        </div>
                        <div className="p-col-12 form-group">
                            <div className="p-grid">
                                <div className={elementClass || "p-col-12 p-md-9"}>
                                    <span className="p-float-label">
                                        {this.getCkEditor(body)}
                                    </span>
                                </div>
                                {errorMessage !== undefined && errorMessageBody !== undefined &&
                                (<div className="p-col-12 p-md-3">
                                    <Message severity="error" text={errorMessageBody} style={{ float: 'right', marginBotton: '30px', paddingLeft: '.5em' }}/>
                                </div>)
                                }
                            </div>
                        </div>
                    </Fragment>
                );
                break;
            case SMS:
                form = (
                    <div className="p-col-12 form-group" key={'sms'}>
                        <div className="p-grid">
                            <div className={elementClass || "p-col-12 p-md-9"}>
                                <span className="p-float-label">
                                    <Editor
                                        headerTemplate={headerEditor}
                                        value={body}
                                        onTextChange={(e) => this.inputEditorChangeHandler(e, {body: body})}
                                    />
                                </span>
                            </div>
                            {errorMessage !== undefined && errorMessageBody !== undefined &&
                            (<div className="p-col-12 p-md-3">
                                <Message severity="error" text={errorMessageBody} style={{ float: 'right', marginBotton: '30px', paddingLeft: '.5em' }}/>
                            </div>)
                            }
                        </div>
                    </div>
                );
                break;
            case VOICE:
                form = (
                    <div className="p-col-12 form-group" key={'voice'}>
                        <div className="p-grid">
                            <div className={elementClass || "p-col-12 p-md-9"}>
                                <span className="p-float-label">
                                    <Editor
                                        headerTemplate={headerEditor}
                                        value={body}
                                        onTextChange={(e) => this.inputEditorChangeHandler(e, {body: body})}
                                    />
                                </span>
                            </div>
                            {errorMessage !== undefined && errorMessageBody !== undefined &&
                            (<div className="p-col-12 p-md-3">
                                <Message severity="error" text={errorMessageBody} style={{ float: 'right', marginBotton: '30px', paddingLeft: '.5em' }}/>
                            </div>)
                            }
                        </div>
                    </div>
                );
                break;
            case LETTER:
                form = (
                    <div className="p-col-12 form-group" key={'letter'}>
                        <div className="p-grid">
                            <div className={elementClass || "p-col-12 p-md-9"}>
                                <span className="p-float-label">
                                    {this.getCkEditor(body)}
                                </span>
                            </div>
                            {errorMessage !== undefined && errorMessageBody !== undefined &&
                            (<div className="p-col-12 p-md-3">
                                <Message severity="error" text={errorMessageBody} style={{ float: 'right', marginBotton: '30px', paddingLeft: '.5em' }}/>
                            </div>)
                            }
                        </div>
                    </div>
                );
                break;
            case WHATSAPP:
                if (body !== '' && this.state.template_title === ''){
                    this.getWhatsappTitle(body);
                }

                if (this.props.value !== undefined){
                    template_uuid = this.props.value['template_uuid'] !== undefined ? this.props.value['template_uuid'] : '';
                }

                let label_button = this.state.template_title ===  '' ? 'Seleccione plantilla de whatsapp' :
                    'Plantilla seleccionada: '+ this.state.template_title;

                form = (
                    <Fragment key={'whatsapp'}>
                        <div className="p-col-12 form-group">
                            <div className="p-grid">
                                <div className={elementClass || "p-col-12 p-md-9"}>
                                    <div className="p-field p-col">
                                        <span className="p-float-label">
                                            <Button label={label_button} className="p-button-text"
                                                    onClick={(e) => this.getWhatsappForm(e)}/>
                                        </span>
                                    </div>
                                    <TemplateWhatsapp active={this.state.whatsapp_enabled} setActive={this.setActive}
                                                      value={body} channel_id={this.props.channel_id}
                                                      tags={this.props.notification_custom_tags}
                                                      onChange={(e) => this.inputChangeTemplateHandler(e)}/>
                                </div>
                                {errorMessage !== undefined && errorMessageBody !== undefined &&
                                    (<div className="p-col-12 p-md-3">
                                        <Message severity="error" text={errorMessageBody} style={{ float: 'right', marginBotton: '30px', paddingLeft: '.5em' }}/>
                                    </div>)
                                }
                            </div>
                        </div>
                    </Fragment>
                );
                break;
            default:
                break;
        }

        return form;
    };

    render(){
        return this.getChannelForm(this.props.channel_id);
    }
}

export default NotificationBody;