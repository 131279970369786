import React, {Component} from "react";

import axios from 'axios';
import '../../assets/css/PaymentProvider.css';
import InputForm from '../../UI/InputForm';
import Logs from '../Logs/Logs';
import CsvExample from '../BulkDataCsv/CsvExample';
import {TabMenu} from "primereact/tabmenu";
import * as roles  from '../../shared/roles';

class PayOrderBulk extends Component {
    state = {
        url: null,
        title: 'Subir Órdenes de Pago',
        payOrderBulkForm: {
            filename: {
                elementType: 'file_upload',
                elementConfig: {
                    allowMultiple: false,
                    maxFiles: 1,
                    acceptedFileTypes: ['text/csv', 'application/vnd.ms-excel']
                }
            }
        },
        itemsMenu: [
            {label: 'Subir Órdenes de Pago', icon: 'pi pi-plus', slug: 'upload'},
        ],
        filenameExample: 'ejemplo_ordenes_de_pago.csv',
        imgSrc: null,
        typeFile: 'payment_order_load',
        logTitle: 'Historial de Subidas',
        count: 0,
        loading: true,
        csv_type: 'PAY_ORDER_CSV_SETTINGS'
    };

    componentDidMount(){
        let role = roles.switchRole();
        let imgSrc = 'assets/layout/images/upload/pay_orders.png';
        let csv_type = 'PAY_ORDER_CSV_SETTINGS';
        let url = axios.defaults.baseURL + '/payment/order';

        if (role === 'payment_order_creator'){
            imgSrc = 'assets/layout/images/upload/debit_pay_orders.png'
            csv_type = 'DEBIT_PAY_ORDER_CSV_SETTINGS';
            url = axios.defaults.baseURL + '/payment/order/debit'
        }

        this.setState({
            url: url,
            imgSrc: imgSrc,
            csv_type: csv_type,
            loading: false
        });

    };


    render(){
        const formElementsArray = [];
        if (this.state.loading){
            return null;
        }
        let payOrderBulkForm = this.state.payOrderBulkForm;

        for (let key in payOrderBulkForm) {
            formElementsArray.push({
                id: key,
                config: payOrderBulkForm[key]
            });
        }

        const example = (<CsvExample CSV_TYPE={this.state.csv_type}/>);

        let form = (
            <div className="ui-g-12 ui-md-12">
                <form>
                    {formElementsArray.map(formElement => (
                        <InputForm
                            key={formElement.id}
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            value={formElement.config.value}
                            typeElement={formElement.id}
                            title={formElement.config.title}
                            url={this.state.url}
                        />
                    ))}
                </form>
            </div>

        );

        return (
            <div className="ui-g ui-fluid">
                <div className="ui-g-12 ui-lg-12">
                    <div className="card card-w-title">
                        <h1>{this.state.title}</h1>
                        <div className='p-grid'>
                            <div className='p-col-12'>
                                <div className='p-grid'>
                                    <div className="p-col-12 p-col-12">
                                        <TabMenu model={this.state.itemsMenu} activeItem={this.state.activeItem}/>
                                    </div>
                                    <div className='p-col-5 p-md-2'>
                                        {example}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ui-g form-group">
                            {form}
                        </div>
                    </div>
                </div>
                <div className="ui-g-12 ui-lg-12">
                    <div className="card card-w-title">
                        <div className='p-grid'>
                            <div className='p-col-12'>
                                <Logs title={this.state.logTitle} name={"type"} op={"eq"} val={this.state.typeFile}
                                      key={this.state.count} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PayOrderBulk;