import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function ConversationPreview(props) {

    const {data, mimetype, unread, messageType, sent, read, delivered, type } = props;
    const color = unread ? '#77b8ff' : '#607D8B';
    const ackIcon = delivered ? 'check-double' : sent ? read ? 'check-double' : 'check' : 'check';

    const ack = (
        (type === 'sent' && (!['note', 'note_reminder', 'scheduled_message'].includes(messageType?.name))) && (
            <small>
                {sent && (
                <FontAwesomeIcon icon={ackIcon}
                                       color={read ? '#77b8ff' : '#607D8B'}
                                       size={"4x"}
                                       style={{width: '0.3em', height: '0.3em'}}/>
                                       )}
            </small>
        )
    )

    const switchConversationPreview = () => {
        switch (messageType?.name) {
            case 'note':
                return <FontAwesomeIcon icon={"sticky-note"} color={color} size='xs' style={{width: '1.6em', height: '1.6em'}}/>
            case 'note_reminder':
                return <FontAwesomeIcon icon={"bell"} color={color} size='xs' style={{width: '1.6em', height: '1.6em'}}/>
            case 'scheduled_message':
                return <FontAwesomeIcon icon={"robot"} color={color} size='xs' style={{width: '1.6em', height: '1.6em'}}/>
            case 'chatbot':
                return <FontAwesomeIcon icon={"robot"} color={color} size='xs' style={{width: '1.6em', height: '1.6em'}}/>
            case 'notification':
                return <FontAwesomeIcon icon={"paper-plane"} color={color} size='xs' style={{width: '1.6em', height: '1.6em'}}/>
            case 'autoreply':
            case 'welcome_message':
                return <FontAwesomeIcon icon={"robot"} color={color} size='xs' style={{width: '1.6em', height: '1.6em'}}/>
            case 'ciphertext':
                return <FontAwesomeIcon icon={"exclamation-circle"} color={color} size='xs' style={{width: '1.6em', height: '1.6em'}}/>
            default:
                switch(mimetype) {
                    case 'audio/mpeg':
                        return <FontAwesomeIcon icon={"file-audio"} color={color} size='xs' style={{width: '1.6em', height: '1.6em'}}/>
                    case 'audio/ogg; codecs=opus':
                    case 'audio/ogg':
                    case 'video/mpeg':
                        return <FontAwesomeIcon icon={"file-audio"} color={color} size='xs' style={{width: '1.6em', height: '1.6em'}}/>
                    case 'image/webp':
                        return <FontAwesomeIcon icon={"sticky-note"} color={color} size='xs' style={{width: '1.6em', height: '1.6em'}}/>
                    case 'image/png':
                    case 'image/jpeg':
                        return <FontAwesomeIcon icon={"file-image"} color={color} size='xs' style={{width: '1.6em', height: '1.6em'}}/>
                    case 'video/mp4':
                    case 'video/webm':
                        return <FontAwesomeIcon icon={"file-video"} color={color} size='xs' style={{width: '1.6em', height: '1.6em'}}/>
                    case 'application/pdf':
                        return <FontAwesomeIcon icon={"file-pdf"} color={color} size='xs' style={{width: '1.6em', height: '1.6em'}}/>
                    case 'application/msword':
                    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                        return <FontAwesomeIcon icon={"file-word"} color={color} size='xs' style={{width: '1.6em', height: '1.6em'}}/>
                    case 'text/csv':
                    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                    case 'application/vnd.ms-excel':
                        return <FontAwesomeIcon icon={"file-excel"} color={color} size='xs' style={{width: '1.6em', height: '1.6em'}}/>
                    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                        return <FontAwesomeIcon icon={"file-powerpoint"} color={color} size='xs' style={{width: '1.6em', height: '1.6em'}}/>
                    case 'application/maps':
                        return <FontAwesomeIcon icon={"map-marked-alt"} color={color} size='xs' style={{width: '1.6em', height: '1.6em'}}/>
                    case 'text/plain':
                        return <FontAwesomeIcon icon={"file-alt"} color={color} size='xs' style={{width: '1.6em', height: '1.6em'}}/>
                    case 'application/contact':
                        return <FontAwesomeIcon icon={"address-card"} color={color} size='xs' style={{width: '1.6em', height: '1.6em'}}/>
                    default:
                        return data;
                }
        }
    }

    return (
            <Fragment>
                <div style={{height:'5px'}}/>
                <div style={{display:'flex'}}>
                    {ack}
                    <p>{ switchConversationPreview() }</p>
                </div>
            </Fragment>
    );
}