import React, {Component} from "react";
import ApiLoan from '../../../service/ApiLoanService';
import InputForm from "../../../UI/InputForm";
import { Button } from 'primereact/button';
import {withRouter} from "react-router-dom";

import {showToast} from "../../../store/actions";
import {showSuccessMessage, showErrorMessage} from "../../../shared/messagesToast";
import connect from "react-redux/es/connect/connect";
import {messageErrorObject} from "../../../shared/utility";


class Profile extends Component {

    _isMounted = false;

    state = {
        apiService: new ApiLoan(),
        uri: '/profile',
        title: 'Editar Perfil',
        profileForm: {
            name: {
                elementType: 'text',
                elementConfig: {
                },
                value: '',
                traduction: 'Nombre Completo',
                order: 0
            },
            email: {
                elementType: 'text',
                elementConfig: {
                    keyfilter: 'email'
                },
                value: '',
                traduction: 'Email',
                order: 1
            },
            inbox_available: {
                elementType: 'switch',
                elementConfig: {
                    onLabel: "SI",
                    offLabel: "NO",
                },
                value: true,
                traduction: 'Recibir mensajes por auto-asignador',
                order: 2
            },
            chatbot_available: {
                elementType: 'switch',
                elementConfig: {
                    onLabel: "SI",
                    offLabel: "NO",
                },
                value: true,
                traduction: 'Recibir mensajes por bot',
                order: 3
            },
            notification_available: {
                elementType: 'switch',
                elementConfig: {
                    onLabel: "SI",
                    offLabel: "NO",
                },
                value: true,
                traduction: 'Recibir notificaciones de nuevo mensaje',
                order: 4
            },
            sound_available: {
                elementType: 'switch',
                elementConfig: {
                    onLabel: "SI",
                    offLabel: "NO",
                },
                value: true,
                traduction: 'Recibir sonido de nuevo mensaje',
                order: 5
            },
            password: {
                elementType: 'password',
                elementConfig: {
                    feedback: false,
                    autoComplete: 'off'
                },
                value: '',
                traduction: 'Contraseña',
                order: 6
            },
            confirm_password: {
                elementType: 'password',
                elementConfig: {
                    feedback: false,
                    autoComplete: 'off'
                },
                value: '',
                traduction: 'Confirmar Contraseña',
                order: 7
            },
        },
        loading: true,
    };

    componentDidMount(){
        this._isMounted = true;

        this.state.apiService.getResources({
            url: this.state.uri
        }).then(response => {
            if (this._isMounted) {
                let fetched_resource = {};
                let profileField = {
                    ...this.state.profileForm
                };

                for(let field in response.data){
                    if (profileField.hasOwnProperty(field)){
                        if (response.data[field] === null){
                            response.data[field] = '';
                        }
                        profileField[field].value = response.data[field];
                        fetched_resource[field] = profileField[field];
                    }
                }
                fetched_resource['password'] = profileField['password'];
                fetched_resource['confirm_password'] = profileField['confirm_password'];
                this.setState({
                    profileForm: fetched_resource,
                    loading: false
                });
            }
        }).catch(error => {
            showErrorMessage(this, "Perfil", 'Error en la carga del perfil');
            this.props.history.push( '/' );
        });

    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    profileHandler = (event) => {
        event.preventDefault();
        this.setState( { loading: true } );

        const formData = {};

        for (let formElementIdentifier in this.state.profileForm) {
            formData[formElementIdentifier] = this.state.profileForm[formElementIdentifier].value;
        }

        this.state.apiService.postResource({
            url: this.state.uri,
            data: formData
        }).then(response => {
            this.setState( { loading: false } );
            showSuccessMessage(this, "Perfil", "Se edito satisfactoriamente");
            this.props.history.push( '/' );
        }).catch(error => {
            let messageError = error.response.data.message;

            let profileForm = {
                ...this.state.profileForm
            };

            let errorMessages = messageErrorObject(profileForm, messageError);

            if (errorMessages !== null){
                this.setState({
                    profileForm: errorMessages,
                    loading: false
                });
            } else {
                this.setState( { loading: false } );
                showErrorMessage(this, "Perfil", messageError);
            }
        });
    };

    inputChangedHandler = (event, inputIdentifier) => {
        let inputFormChanged = this.state.profileForm;

        const updatedProfileForm = {
            ...inputFormChanged
        };

        const updatedFormElement = {
            ...updatedProfileForm[inputIdentifier]
        };

        updatedFormElement.value = event.target.value;

        if (updatedFormElement.elementConfig.error !== undefined){
            updatedFormElement.elementConfig.error = null;
        }

        updatedProfileForm[inputIdentifier] = updatedFormElement;

        this.setState({profileForm: updatedProfileForm});

    };

    render(){
        const formElementsArray = [];
        let profileForm = this.state.profileForm;
        let submitForm = this.profileHandler;
        let submitFormButton = 'Guardar';

        for (let key in profileForm) {
            formElementsArray.push({
                id: key,
                config: profileForm[key]
            });
        }
        formElementsArray.sort(function (x, y) {
            return x.config.order - y.config.order
        })

        let form = (
            <form onSubmit={submitForm}>
                {formElementsArray.map(formElement => (
                    <InputForm
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        typeElement={formElement.id}
                        traduction={formElement.config.traduction}
                        title={formElement.config.title}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)}
                    />
                ))}

                <div className="p-col-12 p-md-9">
                    <Button label={submitFormButton} style={{marginBottom:'10px'}} />
                </div>

            </form>
        );

        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12 p-md-12">
                    <div className="card card-w-title">
                        <h1>{this.state.title}</h1>
                        <div className="p-col-12 form-group">
                            {form}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = {
    showToast
};

export default withRouter(connect(null, mapDispatchToProps)(Profile));