import React, {Component} from "react";

import axios from 'axios';

import {Button} from "primereact/button";
import {OverlayPanel} from "primereact/overlaypanel";

import InputForm from '../../../UI/InputForm';

import '../../../assets/css/PaymentProvider.css';
import Logs from '../../Logs/Logs';
import CsvExample from '../../BulkDataCsv/CsvExample';

class ProviderPayment extends Component {
    state = {
        url: null,
        title: 'Subir Pagos',
        providerPaymentForm: {
            filename: {
                elementType: 'file_upload',
                elementConfig: {
                    allowMultiple: false,
                    maxFiles: 1,
                    acceptedFileTypes: ['text/csv', 'application/vnd.ms-excel']
                }
            }
        },
        logTitle: 'Historial de Subidas',
        revertUrl: '/payments/revert'
    };

    componentDidMount(){
      this.setState({
          url: axios.defaults.baseURL + '/clients/payments'
      });
    };

    render(){
        const formElementsArray = [];
        let providerPaymentForm = this.state.providerPaymentForm;

        for (let key in providerPaymentForm) {
            formElementsArray.push({
                id: key,
                config: providerPaymentForm[key]
            });
        }


        const example = (<CsvExample CSV_TYPE='PAYMENTS_CSV_SETTINGS'/>);

        let img = (
            <div>
                <Button label="Ejemplo" onClick={(event) => this.overlayPanel1.toggle(event)} />
                <OverlayPanel ref={el => this.overlayPanel1 = el} showCloseIcon={true}>
                    <img alt="debtors" src="assets/layout/images//upload/payments.png"  />
                </OverlayPanel>
            </div>
        );

        let form = (
            <div className="ui-g-12 ui-md-12">
                <form>
                    {formElementsArray.map(formElement => (
                        <InputForm
                            key={formElement.id}
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            value={formElement.config.value}
                            typeElement={formElement.id}
                            title={formElement.config.title}
                            url={this.state.url}
                        />
                    ))}
                </form>
            </div>

        );

        return (
            <div className="ui-g ui-fluid">
                <div className="ui-g-12 ui-lg-12">
                    <div className="card card-w-title">
                        <h1>{this.state.title}</h1>
                        <div className='p-grid'>
                            <div className='p-col-12'>
                                <div className='p-grid'>
                                    <div className='p-col-5 p-md-2 p-lg-1'>
                                        {example}
                                    </div>
                                    <div className='p-col-5 p-md-2 p-lg-1'>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ui-g form-group">
                            {form}
                        </div>
                    </div>
                </div>
                <div className="ui-g-12 ui-lg-12">
                    <div className="card card-w-title">
                        <div className='p-grid'>
                            <div className='p-col-12'>
                              <Logs title={this.state.logTitle} name={"type"} op={"eq"} val={"payments_load"}
                                    revertUrl={this.state.revertUrl}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProviderPayment;