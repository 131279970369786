import React from 'react';

import '../assets/css/CustomSpinner.css'

const CustomSpinner = (props) => {
    return  (<div className="custom-spinner-backdrop" style={props.style}>
                <div className="custom-spinner-containter">
                    <div className="custom-spinner-content">
                        <div style={{textAlign: 'center'}}>
                            <img src={`${process.env.REACT_APP_PAGE}/images/loading.gif`}/>
                        </div>
                        {props.status && (
                        <span style={{fontSize: '20px',color: '#7CB342',position: 'relative', textAlign: 'center'}}><br></br>{props.status}</span>
                        )}
                    </div>
                </div>
            </div>)
}

export default CustomSpinner;