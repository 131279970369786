import React, {Fragment, useEffect, useRef, useState} from 'react';

import Resources from '../Resources/Resources';
import { Toast } from 'primereact/toast';
import DialogGeneric from "../../UI/DialogGeneric";
import ApiLoan from "../../service/ApiLoanService";


const InstallmentOrders = (props) => {
    const [modal, setModal] = useState(false);

    useEffect(() => {
        if (props.location.state !== undefined && props.location.state !== null){
            showSuccess(props.location.state.message);
        }
    },[]);

    const toast = useRef(null);

    const exportButton = (props) => {
        setModal(true);
    }

    const showSuccess = (detail) => {
        let summary_message = props.sumaryToast !== undefined ? props.sumaryToast : 'Resumen de Pagos';
        toast.current.show({severity: 'success', summary: summary_message, detail: detail});
    }

    const showError = (detail) => {
        let summary_message = props.sumaryToast !== undefined ? props.sumaryToast : 'Resumen de Pagos';
        toast.current.show({severity: 'error', summary: summary_message, detail: detail});
    }

    const regenerateOrders = (e) => {
        let apiService = new ApiLoan();

        apiService.patchResource({
            url: '/pay_orders/forward'
        }).then(response => {
            showSuccess(response.data.message);
        }).catch(error => {
            showError(error.response.data.message);
        });
    }


    const resource = {
        title: 'Resumen de Pagos',
        url: '/installments/pay_orders',
        colsHeader: [
            { field: 'document_number', header: 'DNI', filter: true, relation_object: 'document_number', parent_field: "client", filterMatchMode: 'contains' },
            { field: 'firstname', header: 'Nombre', filter: true, relation_object: 'firstname', parent_field: "client", filterMatchMode: 'contains' },
            { field: 'lastname', header: 'Apellido', filter: true, relation_object: 'lastname', parent_field: "client", filterMatchMode: 'contains' },
            { field: 'remote_loan_id', header: 'Operación', filter: true, truncate_word: true, relation_object: 'remote_loan_id', parent_field: "loan", filterMatchMode: 'contains' },
            { field: 'amount', header: 'Monto' },
            { field: 'number', header: 'Número Cuota' },
            { field: 'gateway', header: 'Plataforma de Pago', filter: true, relation_object: 'description', parent_field: "payment_method", filterMatchMode: 'contains' },
            {
                field: 'pay_order_creation',
                header: 'Estado Débito',
                checkbox_chips: {
                    label: {
                        'true': 'ACTIVO',
                        'false': 'INACTIVO'
                    }
                }
            },
            { field: 'payment_status', header: 'Estado Barrido'},
            { field: 'pay_order_created', header: 'Fecha Barrido', date: true},
            { field: 'total_attempts', header: 'Barridos'},
            { field: 'debit_date', header: 'Fecha Débito'},
            { field: 'action', header: 'Acciones' }
        ],
        action:{
            add: '/upload',
            addExportButton: {
                eventButton: exportButton
            },
            addGenericButton: {
                button: false,
                eventButton: regenerateOrders,
                label: 'Regenerar Órdenes',
                icon: 'pi pi-copy',
                alert: {
                    alertBody: '¿Está seguro que quiere realizar esta acción?'
                }
            }
        },
        customTableHeaderButtons: [
            {
                label: 'Cargar por csv', command: () => { window.location = '#/pay_orders/upload' }
            },
            {
                label: 'Por cuota', command: () => { window.location = '#/pay_orders/new' }
            },
            {
                label: 'Individual', command: () => { window.location = '#/pay_orders/single' }
            },
        ],
        customActions: [
            { field: 'pay_order_debtor', uri: '/debtor/'},
            { field: 'cancel_pay_order', uri: '/pay_order/cancel'}

        ],
        urlTable: [
            '/pay_orders',
            '/pay_orders/upload',
            '^\\/debtor\\/\\d+$',
        ]
    }

    const value_dialog = {
        'url': '/pay_orders/report',
        'method': 'post',
        'header': 'Exportar Órdenes de Pago',
        'submit_label': 'Descargar',
        'sumaryToast': 'Órdenes de Pago',
        'fields': [
            {
                name: 'range',
                elementType: 'calendar',
                elementConfig: {
                    selectionMode:'range',
                    readonlyInput: true,
                    dateFormat: 'dd/mm/yy',
                    maxDate: new Date(),
                    minDate: null,
                    traduction: 'Filtrar por Fecha',
                },
                value: null,
                elementClass: 'p-col-12 p-md-12 p-mt-2',
                limitRange: {
                    days: 90
                }
            },
            {
                name: 'report_type',
                elementType: 'hidden',
                value: 'installment_pay_orders',
                elementClass: 'input-hidden'
            }
        ],
    }

    let renderModal = modal ? <DialogGeneric visible={modal} setVisible={setModal} {...value_dialog}/> : null;

    return (
        <Fragment>
            <Toast ref={toast} />
            {renderModal}
            <Resources title={resource.title} url={resource.url} colsHeader={resource.colsHeader}
                       customTableHeaderButtons={resource.customTableHeaderButtons}
                       customActions={resource.customActions}
                       action={resource.action} urlTable={resource.urlTable}/>
        </Fragment>
    );

}

export default InstallmentOrders;