import React, {Component} from "react";

import axios from 'axios';
import InputForm from '../../UI/InputForm';
import TransactionLogs from "../Logs/TransactionLogs";

class UploadCoelsaFile extends Component {
    state = {
        url: null,
        title: 'Subir archivo Coelsa',
        coelsaUploadForm: {
            file: {
                elementType: 'file_upload',
                elementConfig: {
                    allowMultiple: false,
                    maxFiles: 1,
                    acceptedFileTypes: ['413'],
                    fileValidateTypeDetectType: (source, type) => new Promise((resolve, reject) => {
                        let mime = source.name.split('.')[1]
                        if (mime === '413'){
                            type = '413';
                            resolve(type);
                        }
                        reject(type);
                    })
                }
            }
        },
        loading: true
    };

    componentDidMount(){
        this.setState({
            url: axios.defaults.baseURL + '/coelsa/upload/received/file'
        });

    };

    render(){
        const formElementsArray = [];
        let uploadCoelsaForm = this.state.coelsaUploadForm;

        for (let key in uploadCoelsaForm) {
            formElementsArray.push({
                id: key,
                config: uploadCoelsaForm[key]
            });
        }

        let form = (
            <div className="ui-g-12 ui-md-12">
                <form>
                    {formElementsArray.map(formElement => (
                        <InputForm
                            key={formElement.id}
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            value={formElement.config.value}
                            typeElement={formElement.id}
                            title={formElement.config.title}
                            url={this.state.url}
                        />
                    ))}
                </form>
            </div>

        );

        return (
            <div className="ui-g ui-fluid">
                <div className="ui-g-12 ui-lg-12">
                    <div className="card card-w-title">
                        <h1>{this.state.title}</h1>
                        <div className="ui-g form-group">
                            {form}
                        </div>
                    </div>
                </div>
                <TransactionLogs title={'Archivos Respuesta Coelsa'} name={"description"} op={"eq"} val={"Read Coelsa file."}/>
            </div>
        );
    }
}

export default UploadCoelsaFile;