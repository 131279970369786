import React, {useState, useEffect, useRef, Fragment} from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import ApiLoan from "../../service/ApiLoanService";
import {Toast} from "primereact/toast";
import {InputSwitch} from "primereact/inputswitch";
import './BotConditionsForm.css';

const BotConditionsForm = (props) => {
    const [conditions, setConditions] = useState([]);
    const [maxConditions, setMaxConditions] = useState(5);
    const [conditionTypes, setConditionTypes] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(true)
    const refToast = useRef(null);

    useEffect(() => {
        let apiService = new ApiLoan();
        apiService.getResources({
            url: '/chat_bot_condition_types'
        }).then(response => {
            setConditionTypes(response.data.objects)
        })
    }, []);

    useEffect(() => {
        if (conditions.map((item) => item.chat_bot_condition_type_id).includes(null)){
            setDisableSubmit(true)
        }
        else if (conditions.map((item) => item.value).includes(null)
            || conditions.map((item) => item.value).includes('')){
            setDisableSubmit(true)
        }
        else if (conditions.map((item) => item.root_questionnaire_id).includes(null)){
            setDisableSubmit(true)
        } else {
            setDisableSubmit(false)
        }
    }, [conditions])


    useEffect(() => {
        let _new_data = []
        if (props.formData.length === 0){
            _new_data.push({
                chat_bot_condition_type_id: null,
                chat_bot_id: null,
                root_questionnaire_id: null,
                value: null,
                active: false,
                more_conditions: false,
            });
        } else {
            _new_data = props.formData.map((item, index) => {
                return {
                    ...item,
                    more_conditions: index < props.formData.length - 1,
                }
            });
        }
        setConditions(_new_data)
    }, []);


    const handleSubmit = (event) => {
        event.preventDefault();
        props.setFormData({index: props.index, conditions: conditions});
        props.hideModalBotConditions();
    }

    const labelSubmit = 'Modificar';

    const updateFieldCondition = (item, index, value) => {
        setConditions((prevState) => {
            let values = [...prevState];
            values[index][item] = value;
            return values;
        });
    }

    const updateMoreConditionHandler = (value, index) => {
        if (value){
            setConditions((prevState) => {
                let new_values = [...prevState];
                new_values.at(index).more_conditions = true

                new_values.push({
                    chat_bot_condition_type_id: null,
                    chat_bot_id: null,
                    root_questionnaire_id: null,
                    value: null,
                    active: false,
                    more_conditions: false,
                });
                return new_values;

            });

            setDisableSubmit(true)
        } else {
            if (conditions.length > 1){
                setConditions((prevState) => {
                    let new_values = [...prevState];
                    new_values.splice(index + 1, 1);
                    new_values.at(-1).more_conditions = false;
                    return new_values;
                });
            }
        }
    }

    const addNewConditionField = (more_conditions, index) => {
        return (
            <div className="p-field p-col-12">
                <label htmlFor="new_condition">Nueva Condición</label>
                <span className="p-float-label">
                    <InputSwitch id="new_condition"
                                 checked={more_conditions}
                                 onChange={(e) => updateMoreConditionHandler(e.value, index)}
                                 disabled={index === maxConditions - 1}
                    />
                </span>
            </div>
        )
    }


    const getForm = () => {
        return conditions.map((item, index) => {
            return (
                <Fragment key={index}>
                    <div className={'p-mt-2 p-mb-3 condition-number'}>
                        Condición {index + 1}
                    </div>
                    <div className="p-formgrid p-grid">
                        <div className="p-field p-col">
                            <span className="p-float-label">
                                <Dropdown id="dropdown" value={item.chat_bot_condition_type_id} options={conditionTypes}
                                          optionLabel={"description"} optionValue={"id"}
                                          onChange={(e) => updateFieldCondition('chat_bot_condition_type_id', index, e.value)}/>
                                <label htmlFor="dropdown">Mensaje</label>
                            </span>
                        </div>
                        <div className="p-field p-col">
                            <span className="p-float-label">
                                <InputText id="inputtext" value={item.value || ''}
                                           onChange={(e) => updateFieldCondition('value', index, e.target.value)}/>
                            <label htmlFor="inputtext">Defina texto o palabra</label>
                        </span>
                        </div>
                        <div className="p-field p-col">
                            <span className="p-float-label">
                                <Dropdown id="questionnaires" value={item.root_questionnaire_id} optionLabel="name"
                                          optionValue="id"
                                          options={props.questionnairesOptions}
                                          onChange={(e) => updateFieldCondition('root_questionnaire_id', index, e.value)}/>
                                <label htmlFor="questionnaires">Seleccione cuestionario</label>
                            </span>
                        </div>
                        <div className="p-field p-col-2 p-d-inline-flex">
                            <div className="p-field p-col">
                                <label htmlFor="active">Habilitado</label>
                                <span className="p-float-label">
                                    <InputSwitch id="active" checked={item.active} onChange={(e) => updateFieldCondition('active', index, e.value)}/>
                                </span>
                            </div>
                            {addNewConditionField(item.more_conditions, index)}
                        </div>
                    </div>
                </Fragment>
            )
        });
    }
    let form = (
        <div className="p-grid p-fluid p-mt-3 p-mb-3 viewpoint-height">
            <div className="p-field p-col p-mt-3">
                <div className="">
                    <Toast ref={refToast}/>
                    {getForm()}
                </div>
                <div className="p-formgrid p-grid">
                    <div className="p-field p-col-12">
                        <Button label={labelSubmit} icon={'pi pi-plus'} className="p-ml-auto"
                                onClick={(e) => handleSubmit(e)}
                                disabled={disableSubmit}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
    return form;

}

export default BotConditionsForm;
