import React from 'react'
import Resource from '../Resources/Resource'

const ProviderScheduledView = (props) => {

    const resource = {
        title: 'Configuración Mensajes Programados',
        id: props.match.params.id,
        url: '/provider_scheduled/',
        fields: [
            { name:'method', traduction:'Método'},
            { name:'url', traduction:'Url'},
            { name:'body', traduction:'JSON'},
            { name:'active', traduction:'Activo', checkbox: true},
            { name:'authorization', traduction: 'Autenticacion'},
            { name:'frecuency', traduction: 'Próxima Frecuencia', cron: true},
            { name:'count', traduction: 'Cantidad'},
            {
                name: 'provider_scheduled_period',
                accordion: false,
                fields: [
                    {name:'description', traduction: 'Periodo'}
                ],
            },
            {
                name: 'provider_scheduled_action',
                accordion: false,
                fields: [
                    {name:'description', traduction: 'Acción'}
                ],
            },
        ]
    }

    return (
        <Resource title={resource.title} url={resource.url} resource_id={resource.id} fields={resource.fields}/>
    );

}

export default ProviderScheduledView;