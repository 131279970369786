import React, {useEffect, useState} from 'react';
import './Toolbar.css';
import Avatar from 'react-avatar';
import { removeNumbersAndSpecialCharacters } from '../../../utils/utils';

export default function Toolbar(props) {
    const { title, leftItems, rightItems, centerItems, className, zIndex, hideShowLeftItems } = props;
    let toolbarClassname = className !== undefined ? className : 'toolbar';
    
    const isMobile = () => {
        return window.innerWidth <= 750;
    };

    if (centerItems !== undefined){
        return (
            <div style={{zIndex: '2'}} className={toolbarClassname}>
                <div className="center-items">
                    { centerItems }
                </div>
            </div>
        );
    } else {
        return (
            <div style={{zIndex: '2'}} className={toolbarClassname}>
                <div className={title ? "left-items-title" : "left-items"}>
                {title ? <Avatar style={{position: 'relative', top: '5px'}} className="conversation-photo" maxInitials={1} name={removeNumbersAndSpecialCharacters(title)} round={true} size="50"/> : null}
                {!isMobile() ? <h1 style={{top: isMobile() ? '30px' : '', position: 'relative'}} className="toolbar-title">{ title }</h1> : null}
                {!hideShowLeftItems ? leftItems : null}
                </div>
                <div className={title ? "right-items-title" : "right-items"}>
                    {
                        rightItems.map((x, index) => {
                            return <div key={index} >{x}</div>
                        })
                    }
                </div>
            </div>
        );
    }


}
