import React, {Component} from 'react';
import { Accordion, AccordionTab } from 'primereact/accordion';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Checkbox} from 'primereact/checkbox';
import CheckboxForm from "../../UI/CheckboxForm";
import ApiLoan from '../../service/ApiLoanService';

import {showToast} from "../../store/actions";
import {showErrorMessage} from "../../shared/messagesToast";
import connect from "react-redux/es/connect/connect";
import {withRouter} from "react-router-dom";

class ResourceAccordion extends Component {

    constructor() {
        super();
        this.state = {
            data: null,
            cols: null,
            loading: true,
            first: 0,
            rows: 10,
            totalRecords: 0,
            page: 0,
        };

        this.apiService = new ApiLoan();
        this.onPage = this.onPage.bind(this);
    }


    componentDidMount(){
        this.apiService.getResource({
            url: this.props.fieldUrl
        }).then(response => {
            let fetched_resource = {};

            if (response.data.objects === undefined){
                fetched_resource = response.data;
            } else {
                fetched_resource = response.data.objects;
            }

            this.setState({
                data: fetched_resource,
                cols: this.props.colsHeader,
                totalRecords: response.data.num_results,
                loading: false
            });
        }).catch(err => {
            showErrorMessage(this, "Error", 'No se ha podido cargar, reintente nuevamente');
            this.props.history.push( '/' );
        });
    };

    addCheckbox = (rowData, column) => {
        return <Checkbox checked={rowData[column.field]}  />;
    };

    addFormCheckbox = (rowData, column) => {
        return (<CheckboxForm url={this.props.fieldUrl} resource_id={rowData['id']} checked={rowData[column.field]}/>);
    };

    onPage(event) {

        let params = {};
        let page = (event.hasOwnProperty('page') || this.state.page)  && event.page;

        const startIndex = event.first;

        this.setState({
            loading: true,
            page: startIndex
        });

        params['url'] = this.props.fieldUrl;

        params['page'] = page + 1;

        this.apiService.getResources(params)
            .then(response => {
                this.setState({
                    data: response.data.objects.slice(0, this.state.rows),
                    totalRecords: response.data.num_results,
                    first: startIndex,
                    loading: false
                });
            }).catch(error => {
                this.setState({loading: false});
                showErrorMessage(this, "Página " + this.state.page, 'Se produjo un error. Intente nuevamente en unos minutos');
            });
    }


    render(){
        let cols = this.state.cols;
        let values = [];

        let footer = "La cantidad de registros es: " + this.state.totalRecords;
        let dynamicColumns = null;

        if (!this.state.loading) {

            dynamicColumns = cols.map((col, i) => {
                let column = null;

                if (col.checkbox) {
                    column = <Column key={col.field} body={col.checkbox['upgradeable'] ? this.addFormCheckbox : this.addCheckbox} field={col.field} header={col.header}/>;
                } else {
                    column = <Column key={col.field} field={col.field} header={col.header}/>;
                }
                return column;
            });


            if (!Array.isArray(this.state.data)) {
                values = values.concat(this.state.data);
            } else {
                values = values.concat(this.state.data);
            }
        }

        return (
            <Accordion>
                <AccordionTab header={this.props.accordionTitle}>
                    <DataTable value={values} selectionMode="single" responsive={true} loading={this.state.loading}
                               paginator={true} first={this.state.first} totalRecords={this.state.totalRecords}
                               rows={this.state.rows} footer={footer} lazy={true} onPage={this.onPage}>
                        {dynamicColumns}
                    </DataTable>
                </AccordionTab>
            </Accordion>
        );

    };

}

const mapDispatchToProps = {
    showToast
};

export default withRouter(connect(null, mapDispatchToProps)(ResourceAccordion));