import React, { useRef, useState, useEffect, Fragment } from 'react';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './MessageMediaPreview.css'

export const MessageMediaPreview = (props) => {
    
    useEffect(() => {
        if (props.files.length == 0) props.setOnFiles(false); 
    }, [props.files]);

    const closeFile = () =>{
        props.setOnFiles(false)
        props.setFiles([])
    }

    const actions = (rowData) => {
        return ( rowData.name !== undefined && (
            <div style={{textAlign: 'right'}}>
            <button className="p-link" onClick={() => props.setFiles(props.files.filter((element => element.name !== rowData.name)))}>
                <FontAwesomeIcon icon={"times"} style={{background: 'red'}} color={'white'} size={"xs"} className={"btn-actions btn-fontawesome"}/>
            </button>
            </div>
            )
        )
    }

    const name = (rowData) => {
        return (
            <div style={{textAlign: 'center'}}>
                <span><b>{rowData.name}</b></span>
            </div>
        )
    }

    const size = (rowData) => {
        return (
                <div style={{textAlign: 'right'}}>
                    <span className="custom-tags dos"
                    style={{
                        marginRight: '0.1em',
                        marginLeft: '0.4em',
                        marginBottom: '0.1em',
                    }}><span><b>{rowData.size/1000 + ' KB'}</b></span>
                    </span>
                </div>
        )
    }

    const type = (rowData) => {
        switch(rowData.type) {
            case 'audio/mpeg':
            case 'audio/ogg; codecs=opus':
            case 'audio/ogg':
            case 'video/mpeg':
                return (
                    <div>
                        <FontAwesomeIcon icon={"file-audio"} color={'#607D8B'} size={"4x"} style={{width: '0.8em', height: '0.8em'}}/>
                    </div>
                    )
            case 'image/webp':
            case 'image/png':
            case 'image/jpeg':
                return (
                    <div>
                        <FontAwesomeIcon icon={"file-image"} color={'#607D8B'} size={"4x"} style={{width: '0.8em', height: '0.8em'}}/>
                    </div>
                    )
            case 'video/mp4':
            case 'video/webm':
                return (
                    <div>
                        <FontAwesomeIcon icon={"file-video"} color={'#607D8B'} size={"4x"} style={{width: '0.8em', height: '0.8em'}}/>
                    </div>
                    )
            case 'application/pdf':
                    return (
                        <div>
                            <FontAwesomeIcon icon={"file-pdf"} color={'#ff4040'} size={"4x"} style={{width: '0.8em', height: '0.8em'}}/>
                        </div>
                    )
            case 'text/plain':
                    return (
                        <div>
                            <FontAwesomeIcon icon={"file-alt"} color={'#607D8B'} size={"4x"} style={{width: '0.8em', height: '0.8em'}}/>
                        </div>
                    )
           case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                   return (
                       <div>
                            <FontAwesomeIcon icon={"file-powerpoint"} color={'#f59622'} size={"4x"} style={{width: '0.8em', height: '0.8em'}}/>
                       </div>
                   )
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/msword':
                    return (
                        <div>
                            <FontAwesomeIcon icon={"file-word"} color={'#00b8ff'} size={"4x"} style={{width: '0.8em', height: '0.8em'}}/>
                        </div>
                    )
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.ms-excel':
            case 'text/csv':
                    return (
                        <div>
                            <FontAwesomeIcon icon={"file-excel"} color={'#00b347'} size={"4x"} style={{width: '0.8em', height: '0.8em'}}/>
                        </div>
                    )
            default:
                return (
                    <div>
                        <FontAwesomeIcon icon={"file"} color={'#607D8B'} size={"4x"} style={{width: '0.8em', height: '0.8em'}}/>
                    </div>
                )
        }
    }

    return (
        <Fragment>
            <div>
            <p className='file-right'><FontAwesomeIcon onClick={() => closeFile()} icon={"times"} color={'#000'} size={"xs"} className={"btn-actions btn-fontawesome"}/></p>
            <div className="datatable-filter-wa-mobile datatable-custom">
                <DataTable className="p-datatable-customers" value={props.files}>
                    <Column field="type" body={type}></Column>
                    <Column field="name" body={name}></Column>
                    <Column field="size" body={size}></Column>
                    <Column field="action" body={actions}></Column>
                </DataTable>
            </div>
            </div>
        </Fragment>
    );
}