import React, {Fragment} from 'react';
import TransactionsList from './TransactionsList';
import "../../assets/css/Transactions.css";
import {Toolbar} from 'primereact/toolbar';
import {Button} from "primereact/button";
import {Link} from 'react-router-dom';

const Transactions = () => {

    const rightContents = (
        <Fragment>
            <Link to={'/coelsa/transactions'} >
                <Button icon="pi pi-calendar" className="p-button-rounded" />
            </Link>
        </Fragment>
    );
    return (
    <div className="p-grid dashboard">
        <div className="p-col-12 p-lg-6 p-lg-offset-3 viewpoint-height">
            <div className="transaction-container">
                <Toolbar style={{'backgroundColor':'#616161'}} right={rightContents} />
                <TransactionsList/>
            </div>
        </div>
    </div>)
}

export default Transactions;