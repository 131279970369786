import React, {useState, useEffect, Fragment} from "react";
import {InputText} from "primereact/inputtext";
import {Message} from "primereact/message";
import {Checkbox} from "primereact/checkbox";

const ProviderPaymentService = (props) => {
    const [prontocobroTax, setProntocobroTax] = useState("");
    const [settlementDays, setSettlementDays] = useState("");

    const [gateways, setGateways] = useState({});
    const [coelsaCheckbox, setCoelsaCheckbox] = useState(true);
    const [rapipagoCheckbox, setRapipagoCheckbox] = useState(false);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let coelsa_active = false;
        let coelsa_value = '';

        let rapipago_active = false;
        let rapipago_value = '';
        if (props.value.gateways !== undefined){
            coelsa_active = props.value.gateways.coelsa !== undefined ? props.value.gateways.coelsa.active : false;
            coelsa_value = props.value.gateways.coelsa !== undefined ? props.value.gateways.coelsa.value : '';

            rapipago_active = props.value.gateways.rapipago !== undefined ? props.value.gateways.rapipago.active : false;
            rapipago_value = props.value.gateways.rapipago !== undefined ? props.value.gateways.rapipago.value : '';
        }

        setGateways({
            coelsa: {
                active: coelsa_active,
                value: coelsa_value
            },
            rapipago: {
                active: rapipago_active,
                value: rapipago_value
            }
        });

        setCoelsaCheckbox(props.value.gateways !== undefined ? coelsa_active : coelsaCheckbox);
        setRapipagoCheckbox(props.value.gateways !== undefined ? rapipago_active : rapipagoCheckbox);

        setLoading(false);

    }, []);

    useEffect(() => {
        if (props.value.prontocobro_tax !== undefined){
            setProntocobroTax(props.value.prontocobro_tax);
        }
    }, [props.value.prontocobro_tax]);

    useEffect(() => {
        if (props.value.settlement_days !== undefined){
            setSettlementDays(props.value.settlement_days);
        }
    }, [props.value.settlement_days]);

    const inputChangeHandler = (event, field) => {
        let values = {...props.value};
        values[Object.keys(field)[0]] = event.target.value;
        props.onChange({value: values, field});
    };

    const inputChangeGatewayHandler = (event, field) => {
        let values = {...props.value};

        let gateway = {...gateways};

        gateway[Object.keys(field)[0]].value = event.target.value;

        if (Object.keys(field)[0] === 'coelsa'){
            gateway[Object.keys(field)[0]].active = coelsaCheckbox;
        } else if (Object.keys(field)[0] === 'rapipago'){
            gateway[Object.keys(field)[0]].active = rapipagoCheckbox;
        }

        values['gateways'] = gateway;
        setGateways(gateway);
        props.onChange({value: values, field});

    };

    const setGatewayHandler = (event) => {
        let values = {...props.value};
        let gateway = {...gateways};
        let field = event.value;
        gateway[field].active = event.checked;

        values['gateways'] = gateway;
        props.errors.errorProviderPaymentService = [];

        if (!event.checked){
            gateway[field].value = '';
        }

        setGateways(gateway);

        if (event.value === 'coelsa'){
            setCoelsaCheckbox(event.checked)
        } else if (event.value === 'rapipago'){
            setRapipagoCheckbox(event.checked);
        }

        props.onChange({value: values, field});

    };

    const getForm = () => {
        let form = null;

        if (props.payment_service){
            let elementClass = "";
            let errorMessage = undefined;
            let errorMessageProntocobroTax = undefined;
            let errorMessageSettlementDays = undefined;
            let errorMessageGateways = undefined;

            if (props.errors.errorProviderPaymentService !== undefined){
                if (Object.keys(props.errors.errorProviderPaymentService).length > 0){
                    errorMessage = true;

                    errorMessageProntocobroTax = props.errors.errorProviderPaymentService.filter(x => x.prontocobro_tax)[0];
                    errorMessageProntocobroTax = errorMessageProntocobroTax !== undefined ? errorMessageProntocobroTax['prontocobro_tax'] : undefined;

                    errorMessageSettlementDays = props.errors.errorProviderPaymentService.filter(x => x.settlement_days)[0];
                    errorMessageSettlementDays = errorMessageSettlementDays !== undefined ? errorMessageSettlementDays['settlement_days'] : undefined;

                    errorMessageGateways = props.errors.errorProviderPaymentService.filter(x => x.gateways)[0];
                    errorMessageGateways = errorMessageGateways !== undefined ? errorMessageGateways['gateways'] : undefined;
                }
            }
            if (errorMessage === undefined){
                elementClass = "p-col-12 p-md-12"
            }

            form = (
                <Fragment>
                    <div className="p-col-12 form-group">
                        <div className="p-grid">
                            <div className={elementClass || "p-col-12 p-md-9"}>
                                <span className="p-float-label">
                                    <InputText
                                        value={prontocobroTax}
                                        keyfilter="num"
                                        onChange={(e) => inputChangeHandler(e, {prontocobro_tax: prontocobroTax})}
                                    />
                                    <label htmlFor="input">Tasa de Porcentaje de Prontocobro</label>
                                </span>
                            </div>
                            {errorMessage !== undefined && errorMessageProntocobroTax !== undefined &&
                            (<div className="p-col-12 p-md-3">
                                <Message severity="error" text={errorMessageProntocobroTax} style={{ float: 'right', marginBotton: '30px', paddingLeft: '.5em' }}/>
                            </div>)
                            }
                        </div>
                    </div>
                    <div className="p-col-12 form-group">
                        <div className="p-grid">
                            <div className={elementClass || "p-col-12 p-md-9"}>
                                <span className="p-float-label">
                                    <InputText
                                        value={settlementDays}
                                        keyfilter="pint"
                                        onChange={(e) => inputChangeHandler(e, {settlement_days: settlementDays})}
                                    />
                                    <label htmlFor="input">Días para liquidar</label>
                                </span>
                            </div>
                            {errorMessage !== undefined && errorMessageSettlementDays !== undefined &&
                            (<div className="p-col-12 p-md-3">
                                <Message severity="error" text={errorMessageSettlementDays} style={{ float: 'right', marginBotton: '30px', paddingLeft: '.5em' }}/>
                            </div>)
                            }
                        </div>
                    </div>

                    <h1>Gateways de Pago</h1>
                    <div className="p-grid form-group">
                        {errorMessageGateways !== undefined && !coelsaCheckbox && !rapipagoCheckbox &&
                        (<div className="p-col-12 p-md-8">
                            <Message severity="error" text={errorMessageGateways}
                                     style={{float: 'right', marginBotton: '30px', paddingLeft: '.5em'}}/>
                        </div>)
                        }
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-md-2">
                            <Checkbox
                                value="coelsa"
                                checked={coelsaCheckbox}
                                onChange={(e) => setGatewayHandler(e)}
                            />
                            <label htmlFor="checkbox" className="p-checkbox-label">Coelsa</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <span className="p-float-label">
                                <InputText
                                    value={gateways.coelsa.value}
                                    disabled={!coelsaCheckbox}
                                    keyfilter="num"
                                    onChange={(e) => inputChangeGatewayHandler(e, {coelsa: gateways.coelsa})}
                                />
                                <label htmlFor="input">Porcentaje Coelsa</label>
                            </span>
                        </div>
                        {errorMessage !== undefined && errorMessageGateways !== undefined && coelsaCheckbox && gateways.coelsa.value === ''  &&
                        (<div className="p-col-12 p-md-3">
                            <Message severity="error" text={errorMessageGateways} style={{ float: 'right', marginBotton: '30px', paddingLeft: '.5em' }}/>
                        </div>)
                        }
                    </div>
                    <div className="p-grid form-group">
                        <div className="p-col-12 p-md-2">
                            <Checkbox
                                value="rapipago"
                                checked={rapipagoCheckbox}
                                onChange={(e) => setGatewayHandler(e)}
                            />
                            <label htmlFor="checkbox" className="p-checkbox-label">Rapipago</label>
                        </div>
                        <div className="p-col-12 p-md-6">
                            <span className="p-float-label">
                                <InputText
                                    value={gateways.rapipago.value}
                                    disabled={!rapipagoCheckbox}
                                    keyfilter="num"
                                    onChange={(e) => inputChangeGatewayHandler(e, {rapipago: gateways.rapipago})}
                                />
                                <label htmlFor="input">Porcentaje Rapipago</label>
                            </span>
                        </div>
                        {errorMessage !== undefined && errorMessageGateways !== undefined && rapipagoCheckbox && gateways.rapipago.value === '' &&
                        (<div className="p-col-12 p-md-3">
                            <Message severity="error" text={errorMessageGateways} style={{ float: 'right', marginBotton: '30px', paddingLeft: '.5em' }}/>
                        </div>)
                        }
                    </div>
                </Fragment>
            );
        }
        return form;
    };

    if (!loading){
        return getForm();
    } else {
        return null;
    }


};

export default ProviderPaymentService;