import React, {useState, useEffect, useContext, Fragment} from 'react';
import {MessageNoteContext} from "../../../context/messageNoteContext";
import {Dialog} from "primereact/dialog";
import ApiLoan from "../../../service/ApiLoanService";
import ConversationView from "../MessageList/ConversationView";
import BasicDropDown from "../../../UI/BasicDropDown";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Toolbar} from "primereact/toolbar";
import Avatar from "react-avatar";
import './MessageNoteModal.css';
import { removeNumbersAndSpecialCharacters } from '../../../utils/utils';

const MessageNoteModal = (props) => {
    const [visibleDialog, setVisibleDialog] = useState(true);
    const {selectedMessageNote, setFoundMessage, selectedClient, clientFullName, selectedContact,
        updateSelectedMessageNote} = useContext(MessageNoteContext)
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        if (selectedMessageNote === props.selectedMessage && messages.length === 0){
            const apiService = new ApiLoan();
            apiService.getResources({
                url: '/messages/notes/find',
                filters: {
                    selected_message: selectedMessageNote,
                    selected_client: selectedClient
                },
                results_per_page: 10,
                page: 1
            }).then(response => {
                setMessages(response.data)
                setFoundMessage(true)
            });
        }
    }, []);

    useEffect(() => {
        if (!visibleDialog && selectedMessageNote !== null){
            updateSelectedMessageNote({
                reset: true
            });
        }
    }, [visibleDialog]);

    const setNewMessage = (event) => {
        event.preventDefault();
        return null;
    }

    const isMobile = () => {
        return window.innerWidth <= 750;
    };

    const contactListDropDown = selectedContact !== null ? (
        <Fragment>
            {clientFullName ? <Avatar style={{position: 'relative', top: '5px'}} className="conversation-photo" maxInitials={1} name={removeNumbersAndSpecialCharacters(clientFullName)} round={true} size="50"/> : null}
            {!isMobile() ? <h1 style={{top: isMobile() ? '30px' : '', position: 'relative'}} className="toolbar-title">{ clientFullName }</h1> : null}
            <BasicDropDown key='contactList' button={
                <FontAwesomeIcon icon={"caret-down"} color={'#000'} size={"xs"} className={"btn-actions btn-fontawesome"}/>
            }>
                <div>
                    {selectedContact?.value}
                </div>
            </BasicDropDown>
        </Fragment>
    ) : null;


    const header = (
        <div>
            <Toolbar left={contactListDropDown} right={null} className={'message-note-modal-toolbar'}/>
        </div>
    );

    return (
        <Dialog header={header} visible={visibleDialog} className={'message-note-modal'} style={{ width: isMobile() ? '100vw' : '50vw' }}
                onHide={() => setVisibleDialog(false)}>
            <div>
                <div className={'message-note-modal-main'}>
                    <ConversationView key={selectedClient} client={selectedClient} currentClient={selectedClient}
                                      newMessage={null} setNewMessage={(e) => setNewMessage(e)}
                                      messages={messages} page={1} messagesPerPage={40}
                                      contactSelected={props.contactSelected} findMessageModal={true} />
                </div>
            </div>
        </Dialog>
    );

}

export default MessageNoteModal;