import React  from 'react';

import Attribute from '../Resources/Attribute';
import DateField from '../../UI/DateField';
import MoneyField from '../../UI/MoneyField';

const InstallmentView = ({dueDate, amount, interestAmount}) => {

    const interestField = interestAmount && (<Attribute name={"interestAmount"} traduction={"Intereses"} key={"interestAmount"}>
                                                <MoneyField>{interestAmount}</MoneyField>
                                            </Attribute>);

    return (<div className="p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <div className="p-grid">
                            <Attribute name={"dueDate"} traduction={"Vencimiento"} key={"dueDate"}>
                                <DateField>{dueDate}</DateField>
                            </Attribute>
                            <Attribute name={"amount"} traduction={"Monto"} key={"amount"}>
                                <MoneyField>{amount}</MoneyField>
                            </Attribute>
                            {interestField}
                        </div>
                    </div>
                </div>
            </div>);
}


export default InstallmentView;