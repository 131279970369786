import React, { Component } from 'react';
import ResourceFormGeneric from '../../Forms/ResourceFormGeneric';

class ProviderPaymentInformationForm extends Component {

    constructor(){
        super();
        this.setFields = this.setFields.bind(this);
        this.onChangeHandler = this.onChangeHandler.bind(this);
    }

    state = {
        uri: '/clients',
        resource_id: localStorage.getItem('provider_id'),
        title: 'Información de Pago',
        fields: [
            {
                name: 'payment_information',
                elementType: 'html-editor',
                elementConfig: {
                    onChange: this.onChangeHandler
                },
                value: '',
                traduction: 'Información de Pago'
            },
        ],
        loading: false
    };

    onChangeHandler = (event) => {
        return event
    }

    setFields = (fields) => {
        this.setState({fields: fields})
    }

    render(){

        const form = (<ResourceFormGeneric 
                            setFields={this.setFields}
                            fields={this.state.fields}
                            url={this.state.uri}
                            resource_id={this.state.resource_id}
                            resource_traduction={this.state.title}/>);

        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12 p-lg-12">
                    <div className="card card-w-title">
                        <h1>{this.state.title}</h1>
                        {!this.state.loading && form}
                    </div>
                </div>
            </div>
        );
    }
}

export default ProviderPaymentInformationForm;
