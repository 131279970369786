import React, {useState, useEffect, useRef, useMemo} from 'react';

import { Button } from 'primereact/button';
import ApiLoan from '../../service/ApiLoanService';
import {Toast} from "primereact/toast";

import InputField from '../Forms/InputField';
import {switchDayOfWeek} from '../../shared/daysWeek'
import {Card} from "primereact/card";


const WelcomeMessageForm = (props) => {
    const [data, setData] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {
        setTemplates(props.templates);
    }, []);

    useEffect(() => {
        const apiService = new ApiLoan();

        apiService.getResources({
            'url': '/welcome_messages'
        }).then(response => {

            let objects = response.data.objects;

            if (objects.length === 0){
                refToast.current.show({severity: 'error', summary: 'Mensaje Bienvenida',
                    detail: 'No hay mensajes de bienvenida disponibles.'});
            }
            setData(objects[0]);
            setLoading(false);

        }).catch(error => {
            refToast.current.show({severity: 'error', summary: 'Mensaje Bienvenida',
                detail: 'Error al cargar mensaje de bienvenida.'});
        })

    }, []);

    const showTemplate = () => {
        const templateDropdown = <InputField type={'dropdown'} value={data.template_id} traduction={'Plantilla'}
                                             options={templates} label={'label'}
                                             onChange={(event) => {handleChange(event, 'template_id', data)}} />
        return (
            <div className={"p-ml-1"}>
                {templateDropdown}
            </div>
        );
    }

    const showActive = () => {
        const activeField = <InputField type={'switch'} value={data.active}
                                        onChange={(event) => {handleChange(event, 'active', data)}} />

        return (
            <div className={"p-d-flex"}>
                <h5 className={"p-mt-3"}>Habilitado</h5>
                <div className={"p-ml-2 p-mt-2"}>{activeField}</div>
            </div>
        )
    }

    const handleChange = (event, field, row) => {
        setData((prevState) => {

            const newState = prevState;
            newState[field] = event.value;
            prevState = newState

            return {
                ...prevState
            }

        });
    }

    const form = () => {
        return (
            <div className={"p-d-flex p-flex-wrap p-fluid"}>
                <div className={"p-field p-col-12 p-md-6 p-lg-6"}>
                    {showTemplate()}
                </div>
                <div className={"p-field p-col-12 p-md-6 p-lg-6"}>
                    {showActive()}
                </div>
            </div>
        );

    }

    const handleSubmit = (event) => {
        const apiService = new ApiLoan();

        const formData = {
            data
        }

        apiService.patchResource({
            'url': 'welcome_messages',
            'data': formData
        }).then(response => {
            if (response.status === 200){
                refToast.current.show({severity: 'success', summary: 'Mensaje de Bienvenida',
                    detail: 'Se actualizó correctamente.'});
            }
        }).catch(error => {
            let error_messages = error.response.data.message;
            let error_keys = Object.keys(error_messages)

            error_keys.map(k => {
                return refToast.current.show({severity: 'error', summary: 'Mensaje de Bienvenida',
                    detail: error_messages[k]});
            });
        });
    }

    const refToast = useRef(null);
    const showForm = !loading ? form() : null;
    return (
        <div className={"p-mt-4"}>
            <Card title={'Mensaje de Bienvenida'}>
                <Toast ref={refToast}/>
                {showForm}
                <div className={"p-grid p-fluid"}>
                    <div className="p-field p-col-12">
                        <Button label={'Guardar'} onClick={(e) => handleSubmit(e)}  />
                    </div>
                </div>
            </Card>
        </div>
    )



}

export default WelcomeMessageForm;