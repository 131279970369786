import ApiLoan from '../service/ApiLoanService';

export const markAsSeen = (status=true, client_id) => {
    const apiService = new ApiLoan();
    apiService
    .postResource({
        url: '/conversations/' + client_id + '/seen',
        data: {
          unread_messages: !status
        }
    })
}

export const markAsPinup = (status=true, client_id) => {
    const apiService = new ApiLoan();
    apiService
    .postResource({
        url: '/conversations/' + client_id + '/pinup',
        data: {
          pinup_messages: status
        }
    })
}


export const markAsArchived = (status=true, client_id) => {
    const apiService = new ApiLoan();
    apiService
    .postResource({
        url: '/conversations/' + client_id + '/archived',
        data: {
          archived: status
        }
    })
}

export const markAsArchivedAndClean = (status=true, conversation=false, custom_tags=false, client_id) => {
    const apiService = new ApiLoan();
    apiService
    .postResource({
        url: '/conversations/' + client_id + '/clean',
        data: {
          archived: status,
          clean_conversation: conversation,
          clean_custom_tags: custom_tags,
        }
    })
}

export const toggleAssign = (client_id, user_id, toggle_assign) => {
    const apiService = new ApiLoan();
    return apiService
        .postResource({
            url: '/conversations/' + client_id + '/assign',
            data: {
                user_id: user_id,
                toggle_assign: toggle_assign
            }
        })
}