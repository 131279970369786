import React from "react";

import Resources from "../Resources/Resources";

import * as roles  from '../../shared/roles';

const Audiences = () => {

	let role = roles.switchRole();
	let can_remove_audience = false;
	let title = 'Audiencia';
	if (role === 'audience'){
		can_remove_audience = true;
	}
	if (role === 'payment_order_creator' || role === 'texter' || role === 'customer_support'){
		title = 'Clientes'
	}

	const resource = {
		title: title,
		url: "/audiences",
		colsHeader: [
			{
				field: "lastname",
				header: "Apellido",
				sortable: true,
				filter: true,
				filterMatchMode: "contains"
			},
			{
				field: "firstname",
				header: "Nombre",
				sortable: true,
				filter: true,
				filterMatchMode: "contains"
			},
			{
				field: "document_number",
				header: "Identificador",
				sortable: true,
				filter: true
			},
			{
				field: "custom_tags__name",
				header: "Tags",
				filter: true,
				multiselect_dropdown: {
					params: {
						url: "/custom_tags",
						page: 1,
						filters: [
							{
								name: "active",
								op: "equals",
								val: true
							}
						],
					},
					value: "name",
					label: "name",
					selectedItemsLabel: '{0} tags seleccionados',
				},
				relation: "custom_tags__name",
				parent_field: "custom_tags"
			},
			{ field: "action", header: "Acciones" }
		],
		action: {
			add: '/new',
			show: "/audience/",
			edit: '/edit',
			//delete: can_remove_audience
		},
		customActions: [
			{ field: 'contacts'}
		],
		urlTable: [
			"/audiences",
			'/audiences/new',
			"^\\/audience\\/\\d+$",
			'^\\/audience\\/\\d+\\/edit$',
		]
	};

	const filteredColsHeader = resource.colsHeader.filter((col) => {
		if(col.field === 'custom_tags__name' && role === 'texter'){
			return false;
		}
		return true;
	});

	if (role === 'customer_support'){
		delete resource.action.add;
	}

	return (
		<Resources
			title={resource.title}
			url={resource.url}
			colsHeader={filteredColsHeader}
			action={resource.action}
			customActions={resource.customActions}
			urlTable={resource.urlTable}
		/>
	);
};

export default Audiences;
