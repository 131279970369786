import React, {Component} from "react";
import axios from 'axios';

import '../../../assets/css/PaymentProvider.css';

import InputForm from '../../../UI/InputForm';
import Logs from '../../Logs/Logs';
import CsvExample from '../../BulkDataCsv/CsvExample';
import ApiLoan from "../../../service/ApiLoanService";
import { Toast } from 'primereact/toast';


class ClientBulk extends Component {
    state = {
        url: null,
        title: 'Subir ' + (['texter', 'audience', 'supervisor'].includes(this.props.allowed) || process.env.REACT_APP_PAGE === 'pip-app' ? 'Clientes' : 'Deudores'),
        clientBulkForm: null,
        logTitle: 'Historial de Subidas',
        revertUrl: null,
        loading: true
    };

    componentDidMount(){
        let apiService = new ApiLoan();
        apiService.postResource({
            url: '/status/bulk',
            data: {
                'task': 'debtors_bulk'
            }
        }).then(response => {
            let disable_form = response.data.status;
            let client_bulk_form = {
                filename: {
                    elementType: 'file_upload',
                    elementConfig: {
                        allowMultiple: false,
                        maxFiles: 1,
                        acceptedFileTypes: ['text/csv', 'application/vnd.ms-excel',
                            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'],
                        disabled: disable_form
                    }
                }
            }
            this.setState({
                url: axios.defaults.baseURL + '/clients/bulk',
                clientBulkForm: client_bulk_form,
                revertUrl: disable_form ? null : '/clients/revert'
            });
        }).catch(error => {
            this.showError();
        })

    };

    showError() {
        this.toast.show({severity:'error', summary: 'Subir Clientes', detail:'Error al verificar si existe la tarea',
            life: 3000});
    }

    render(){
        const formElementsArray = [];
        let clientBulkForm = this.state.clientBulkForm;

        for (let key in clientBulkForm) {
            formElementsArray.push({
                id: key,
                config: clientBulkForm[key]
            });
        }

        const example = (<CsvExample CSV_TYPE='CLIENTS_BULK_CSV_SETTINGS'/>);

        let form = (
            <div className="ui-g-12 ui-md-12">
                <form>
                    {formElementsArray.map(formElement => (
                        <InputForm
                            key={formElement.id}
                            elementType={formElement.config.elementType}
                            elementConfig={formElement.config.elementConfig}
                            value={formElement.config.value}
                            typeElement={formElement.id}
                            title={formElement.config.title}
                            url={this.state.url}
                        />
                    ))}
                </form>
            </div>

        );

        return (
            <div className="ui-g ui-fluid">
                <Toast ref={(el) => this.toast = el} />
                <div className="ui-g-12 ui-lg-12">
                    <div className="card card-w-title">
                        <h1>{this.state.title}</h1>
                        <div className='p-grid'>
                            <div className='p-col-12'>
                                <div className='p-grid'>
                                    <div className='p-col-5 p-md-2 p-lg-1'>
                                        {example}
                                    </div>
                                    <div className='p-col-5 p-md-2 p-lg-1'>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="ui-g form-group">
                            {form}
                        </div>
                    </div>
                </div>
                <div className="ui-g-12 ui-lg-12">
                    <div className="card card-w-title">
                        <div className='p-grid'>
                            <div className='p-col-12'>
                                <Logs title={this.state.logTitle} name={"type"} op={"eq"} val={"debtors_load"}
                                      revertUrl={this.state.revertUrl}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ClientBulk;