import React, { Component } from 'react';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import MoneyField from '../../UI/MoneyField';
import DateField from '../../UI/DateField';

class Balance extends Component {
    
    state = {
        operations: this.props.operations
    }

    resource = {
        colsHeader: [
            { field: 'number', header: 'Nº Referencia'},
            { field: 'due_date', header: 'Fecha Vencimiento'},
            { field: 'credit', header: 'Debe'},
            { field: 'debit', header: 'Haber'},
            { field: 'balance', header: 'Saldo'},
        ],
    };

    responseAdapter = (operations) => {
        return operations.map(operation => {
            return {
                number: operation.number,
                due_date: (<div><DateField>{operation.date}</DateField></div>),
                credit: (operation.operation === '+') ? (<div><MoneyField>{operation.amount}</MoneyField></div>) : '',
                debit: (operation.operation === '-') ? (<div><MoneyField>{operation.amount}</MoneyField></div>) : '',
                balance: (<div><MoneyField>{operation.balance}</MoneyField></div>)
            };
        });

    };

    render(){
        const cols = this.props.colsHeader.map(col => {
            return (<Column key={col.field} field={col.field} header={col.header}/>);
        });

        return (<DataTable value={this.responseAdapter(this.props.operations)} responsive={true}>
                    {cols}
                </DataTable>);
    }

}



export default Balance;
