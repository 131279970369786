import React, { Component } from 'react';
import InputForm from '../../../UI/InputForm';
import { Button } from 'primereact/button';
import ApiLoan from '../../../service/ApiLoanService';
import {withRouter} from "react-router-dom";

import {showToast} from "../../../store/actions";
import {showSuccessMessage, showErrorMessage} from "../../../shared/messagesToast";
import connect from "react-redux/es/connect/connect";
import {messageErrorObject} from "../../../shared/utility";

import update from "immutability-helper";

class Rate extends Component {

    _isMounted = false;

    state = {
        apiService: new ApiLoan(),
        url: null,
        uri: '/rates',
        title: 'Tarifa',
        rateForm: {
            channel_id: {
                elementType: 'dropdown',
                elementConfig: {
                    options: null,
                    placeholder: 'Seleccione un tipo de canal'
                },
                value: '',
                traduction: 'Canal'
            },
            price: {
                elementType: 'text',
                elementConfig: {
                    keyfilter: 'money'
                },
                value: '',
                traduction: 'Precio'
            }
        },
        loading: true,
        rateFormEdit: null
    };


    componentDidMount(){
        this._isMounted = true;

        if (this.props.action !== 'edit'){
            let channelOptions = [];

            let rateForm = {
                ...this.state.rateForm
            };

            this.state.apiService.getResource({
                url: '/channels'
            }).then(response => {
                if (this._isMounted) {
                    const response_data = response.data.objects;

                    for (let key in response_data) {
                        channelOptions.push({
                            'value':  response_data[key].id,
                            'label': response_data[key].description
                        });
                    }

                    let channelsForm = update(rateForm, {
                        channel_id: {
                            elementConfig: {
                                options: {$set: channelOptions}
                            }
                        }
                    });

                    rateForm = channelsForm;

                    this.setState({
                        rateForm: rateForm
                    });
                }
            }).catch(error => {
                showErrorMessage(this, "Tarifa", 'Error en la carga de canales');
                this.props.history.push( '/rates' );
            });
        } else {
            this.state.apiService.getResource({
                url: this.props.url,
                resource_id: this.props.resource_id
            }).then(response => {
                if (this._isMounted) {
                    let fetched_resource = {};

                    this.props.fields.map(rateField => {
                        for(let field in response.data){
                            if (rateField.hasOwnProperty(field)){
                                if (response.data[field] === null){
                                    response.data[field] = '';
                                }
                                rateField[field].value = response.data[field];
                                fetched_resource[field] = rateField[field];
                            }
                        }
                        return fetched_resource;
                    });
                    this.setState({
                        rateFormEdit: fetched_resource,
                        title: this.props.title,
                        loading: false
                    });
                }
            }).catch(error => {
                showErrorMessage(this, "Tarifa", 'Error en la carga de la tarifa');
                this.props.history.push( '/rates' );
            });

        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    rateHandler = (event) => {
        event.preventDefault();
        this.setState( { loading: true } );

        const formData = {};

        for (let formElementIdentifier in this.state.rateForm) {
            formData[formElementIdentifier] = this.state.rateForm[formElementIdentifier].value;
        }

        this.state.apiService.postResource({
            url: this.state.uri,
            data: formData
        }).then(response => {
            this.setState( { loading: false } );
            showSuccessMessage(this, "Tarifa", "Se creó satisfactoriamente");
            this.props.history.push( '/rates' );
        }).catch(error => {
            let messageError = error.response.data.message;

            let rateForm = {
                ...this.state.rateForm
            };

            let errorMessages = messageErrorObject(rateForm, messageError);

            if (errorMessages !== null){
                this.setState({
                    rateForm: errorMessages,
                    loading: false
                });
            } else {
                this.setState( { loading: false } );
                showErrorMessage(this, "Tarifa", messageError);
            }
        });
    };

    rateEditHandler = (event) => {
        event.preventDefault();
        this.setState( { loading: true } );

        const formDataEdit = {};

        for (let formElementIdentifier in this.state.rateFormEdit) {
            formDataEdit[formElementIdentifier] = this.state.rateFormEdit[formElementIdentifier].value;
        }

        this.state.apiService.patchResource({
            url: this.state.uri,
            resource_id: this.props.resource_id,
            data: formDataEdit
        }).then(response => {
            this.setState( { loading: false } );
            showSuccessMessage(this, "Tarifa", "Se editó satisfactoriamente");
            this.props.history.push( '/rates' );
        }).catch(error => {
            let messageError = error.response.data.message;

            let rateFormEdit = {
                ...this.state.rateFormEdit
            };

            let errorMessages = messageErrorObject(rateFormEdit, messageError);

            if (errorMessages !== null){
                this.setState({
                    rateFormEdit: errorMessages,
                    loading: false
                });
            } else {
                this.setState( { loading: false } );
                showErrorMessage(this, "Tarifa", messageError);
            }
        });
    }

    inputChangedHandler = (event, inputIdentifier) => {
        let inputFormChanged = this.props.action !== 'edit' ? this.state.rateForm : this.state.rateFormEdit;

        const updatedRateForm = {
            ...inputFormChanged
        };

        const updatedFormElement = {
            ...updatedRateForm[inputIdentifier]
        };
        updatedFormElement.value = event.target.value;
        updatedRateForm[inputIdentifier] = updatedFormElement;

        if (updatedFormElement.elementConfig.error !== undefined){
            updatedFormElement.elementConfig.error = null;
        }

        if (this.props.action !== 'edit'){
            this.setState({rateForm: updatedRateForm});
        } else {
            this.setState({rateFormEdit: updatedRateForm});
        }
    }

    render(){
        const formElementsArray = [];
        let rateForm = this.props.action !== 'edit' ? this.state.rateForm : this.state.rateFormEdit;
        let submitForm = this.props.action !== 'edit' ? this.rateHandler : this.rateEditHandler;
        let submitFormButton = this.props.action !== 'edit' ? 'Guardar' : 'Actualizar';

        for (let key in rateForm) {
            formElementsArray.push({
                id: key,
                config: rateForm[key]
            });
        }

        let form = (
            <form onSubmit={submitForm}>
                {formElementsArray.map(formElement => (
                    <InputForm
                        key={formElement.id}
                        elementType={formElement.config.elementType}
                        elementConfig={formElement.config.elementConfig}
                        value={formElement.config.value}
                        typeElement={formElement.id}
                        traduction={formElement.config.traduction}
                        title={formElement.config.title}
                        changed={(event) => this.inputChangedHandler(event, formElement.id)}
                    />

                ))}

                <div className="p-col-12 p-md-9">
                    <Button label={submitFormButton} style={{marginBottom:'10px'}} />
                </div>

            </form>
        );

        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12 p-lg-12">
                    <div className="card card-w-title">
                        <h1>{this.state.title}</h1>
                        <div className="p-col-12 form-group">
                            {form}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapDispatchToProps = {
    showToast
};

export default withRouter(connect(null, mapDispatchToProps)(Rate));