import React, {useState} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import SearchItem from './SearchItem';
import {InputText} from 'primereact/components/inputtext/InputText';

import ApiLoan from "../../service/ApiLoanService";


const SearchList = ({resource, fields, filterParams, onChange, inputValue}) => {
    const apiService = new ApiLoan();
    const [showList, setShowList] = useState(false);
    const [resources, setResources] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);

    const changeShowList = (event, isShown, type, onChange) => {

        if(type === 'blur'){
            if(event.target.tagName === "INPUT"){
                return;
            }
        }
        setShowList(isShown);
    }

    const onSelectElement = (element) => {
        onChange({
            id: element.id,
            remote_loan_id: element.remote_loan_id
        });
        setShowList(false);
    }

    const getResources = (page, requestParams) => {
        const params = {...filterParams, ...requestParams}
        return apiService.getResources({
            url: '/' + resource,
            page: page,
            ...params
        }).then(response => {
            const listResources = response.data.objects
            setHasMore(response.data.page < response.data.total_pages);
            setResources(resources.concat(listResources));
            setCurrentPage(response.data.page)
        });
    }

    let currentResources = resources;
    let currentHasMore = hasMore;
    let currentCurrentPage = currentPage;
    let requestParams = {};

    const resourcesList = currentResources.map((resource => {
        return (<SearchItem fields={fields}
                            element={resource}
                            onClick={() => { onSelectElement(resource) }}
                            key={resource.id}/>)
    })) 

    const inputText = (<InputText value={inputValue !== null ? inputValue : ''}
                                    className="search-input p-inputtext-sm"
                                    readOnly={true}/>);

    const body = showList ? (<div
                                tabIndex="0"
                                onBlur={(event) => {changeShowList(event, false, 'blur')}}
                                onFocus={(event) => {changeShowList(event, true, 'focus')}}>
                                {inputText}
                                <div className="search-list-container">
                                    <InfiniteScroll
                                        pageStart={currentCurrentPage}
                                        loadMore={(page) => getResources(page, requestParams)}
                                        hasMore={currentHasMore}
                                        useWindow={false}
                                        loader={<div key={'loading'}>Cargando...</div>}>
                                        <ul className={'search-list'}>
                                            {resourcesList}
                                        </ul>
                                    </InfiniteScroll>
                                </div>
                            </div>) :
                            (<div
                                onBlur={(event) => {changeShowList(event, false, 'blur')}}
                                onFocus={(event) => {changeShowList(event, true, 'focus')}}>
                                {inputText}
                            </div>)
                            

    return (body)

}

export default SearchList;