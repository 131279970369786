import React, { Component, Fragment } from "react";

import Attribute from "../../Resources/Attribute";
import MoneyField from "../../../UI/MoneyField";
import DateField from "../../../UI/DateField";

class ClientLoan extends Component {
  state = {
    loading: true
  };

  render() {

    const fields = [
        {
          name: "amount",
          traduction: "Monto Total",
          format: "money",
          value: this.props.loan["amount"]
        },
        {
          name: "loan_total_due",
          traduction: "Deuda vencida Total",
          format: "money",
          classNameValue: "due-loan",
          value: this.props.loan["loan_total_due"]
        },
        {
          name: "amount_borrowed",
          traduction: "Monto Prestado",
          format: "money",
          value: this.props.loan["amount_borrowed"]
        },
        {
          name: "description",
          traduction: "Detalle",
          value: this.props.loan["description"]
        },
        {
          name: "interest",
          traduction: "Interés por atraso",
          format: "money",
          value: this.props.loan["interest"]
        },
        {
            name: "cbu",
            traduction: "CBU",
            value: this.props.loan["cbu"],
            edit: true,
            formData: {
                'url': '/loans/cbu/edit',
                'data': {
                    'loan_id': this.props.loan['id']
                }
            },
            elementConfig: {
                keyfilter: 'pint',
                maxLength: 22
            },
            sumaryToast: 'Cbu'
        },
        { name: "bank", traduction: "Banco", value: this.props.loan["bank"] },
        {
          name: "paid_installments",
          traduction: "Cuotas Pagadas",
          value: this.props.loan["paid_installments"]
        },
        {
          name: "installments_due",
          traduction: "Cuotas Vencidas",
          value: this.props.loan["installments_due"]
        },
        {
          name: "clean_installment",
          traduction: "Cuota Pura",
          value: this.props.loan["clean_installment"]
        },
        {
          name: "remote_loan_id",
          traduction: "ID Remoto",
          value: this.props.loan["remote_loan_id"]
        },
        {
          name: "created",
          traduction: "Creado",
          format: "date",
          value: this.props.loan["created"]
        },
        {
          name: "default_status",
          traduction: "Estado de Mora",
          value: this.props.loan["default_status"]
        }
    ];

    let fieldsWithValue = fields.map((field,index) => {
      let value;

      switch (field.format) {
        case "money":
          value = <MoneyField>{field.value}</MoneyField>;
          break;
        case "date":
          value = <DateField>{field.value}</DateField>;
          break;
        default:
          value = field.value;
          break;
      }

    let classNameValue = field.classNameValue !== undefined ? field.classNameValue : '';
      return (
        <div className={"p-grid"} key={index}>
          <Attribute
            classNameLabel={"p-col-12 p-md-3"}
            classNameValue={"p-col-12 p-md-9 " + classNameValue}
            traduction={field.traduction}
            edit={field.edit}
            formData={field.formData}
            elementConfig={field.elementConfig}
            sumaryToast={field.sumaryToast}
          >
            {value}
          </Attribute>
        </div>
      );
    });

    return <Fragment>{fieldsWithValue}</Fragment>;
  }
}

export default ClientLoan;
