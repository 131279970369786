import React, {Fragment, useContext, useEffect, useMemo, useRef, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Linkify from 'react-linkify';
import moment from 'moment';
import './MessagePreview.css';
import {MessageContext} from "../../../context/messageContext";
import { Dialog } from 'primereact/dialog';
import {phone} from "phone";
import {Menu} from "primereact/menu";

export default function MessagePreview(props) {

    const {data, index, audioRefs, handlePlay} = props;
    const date = moment(data.created_timezone).format('YYYY-MM-DD');
    const limitDate = moment(new Date(Date.now())).subtract(6, 'days').format("YYYY-MM-DD");
    const mimetype = data.mimetype;
    const messageParent = data.parent;
    const isReaction = data.reaction;
    const huaweicheck = mimetype === null ? '' : data.message?.split('huawei').length > 1;
    const linkExpired = moment(limitDate).isAfter(date);
    const pushname = mimetype !== null ? data.message?.split('\n').length > 1 ? data.emoji?.split('\n')[0] : '' : '';
    const {updateSelectedMessageContext, updateSelectedPhoneContact} = useContext(MessageContext)
    const referrals = data?.referrals !== undefined ? JSON.parse(data?.referrals) : null;
    const captions = data?.captions !== undefined ? JSON.parse(data?.captions) : null;
    const [selectImage, setSelectImage] = useState('')
    const [hideDialog, setHideDialog] = useState(false)
    const menu = useRef(null);

    const [selectedPhoneContact, setSelectedPhoneContact] = useState({
        value: null,
        active: false
    });

    useEffect(() => {
        if (selectedPhoneContact.active && selectedPhoneContact.value !== null){
            let contact = {
                phone_number: '',
                country_prefix: '54'
            }

            let match_country_prefix = selectedPhoneContact.value.match(/^\+549/);
            if (match_country_prefix){
                contact['phone_number'] = selectedPhoneContact.value.split(match_country_prefix[0])[1]
            } else {
                contact['phone_number'] = selectedPhoneContact.value
            }

            updateSelectedPhoneContact(contact);
            setSelectedPhoneContact(prevState => {
                return {
                    ...prevState,
                    value: null,
                    active: false
                }
            });
        }
    }, [selectedPhoneContact.active, selectedPhoneContact.value])

    const removePushnameUrl = (data, check) => {
        return check ? data.split('\n').length > 1 ? mimetype === 'application/contact' ? data.split('\n')[1] + ' ' + data.split('\n')[2] : data.split('\n')[1] : data : data;
    }

    const onFilename = (data, ext) => {
        if (data){
            let url = removePushnameUrl(data, true).split('/')
            let urlLength = removePushnameUrl(data, true).split('/').length
            if (url[urlLength-1].split('.').length > 1){
                return url[urlLength-1]
            }else {
                return url[urlLength-1] + '.' + ext
            }
        }
    }

    const DialogImage = () => {
        return (
            <Dialog style={{ width: 'auto', height: 'auto' }} visible={hideDialog} onHide={(e) => setHideDialog(false)}>
                <img style={{ width: '100%', height: '100%' }} src={selectImage} alt={'message_image'}></img>
            </Dialog>
        )
    }

    const onDialogImagen = (event, image) => {
        event.preventDefault()
        setHideDialog(true);
        setSelectImage(image);
    }

    const linkDecorator = (href, text, key) => (
        <a href={href} key={key} target="_blank" rel="noopener noreferrer">
            {text}
        </a>
    );

    const expiredInput = (icon) => {
        return (
            <div>
                <p className="p-text-left" style={{color:"#4a4141"}}>
                    <small>(Timeout)</small>
                </p>
                <FontAwesomeIcon icon={icon} color={'#607D8B'} size={"4x"}
                                 style={{width: '2.0em', height: '2.0em'}}/>
            </div>
        );
    }

    const nameGroup = () => {
        return (
            <p className="p-text-left" style={{color:"#4a4141"}}>
                {pushname}
            </p>
        );
    }

    const getContext = () => {
        let context = null;
        if (messageParent !== null && !isReaction){
            let author_name = data.message_out ? 'Tú' : data.author_name;
            let context_body = null;

            switch(messageParent.mime_type) {
                case 'audio/mpeg':
                case 'audio/ogg; codecs=opus':
                case 'audio/ogg':
                    context_body = (
                        <FontAwesomeIcon icon={"file-audio"} color={'#607D8B'} size={"2x"} className={'p-mt-2 p-mr-2'}/>
                    )
                    break;
                case 'image/webp':
                case 'image/png':
                case 'image/jpeg':
                    context_body = (
                        <img src={removePushnameUrl(messageParent.body, true)} alt={'message'} className={'context-file'}/>
                    )
                    break;
                case 'video/mp4':
                    context_body = (
                        <video controls>
                            <source src={removePushnameUrl(messageParent.body, true)} type="video/mp4"
                                    className={'context-file'}/>
                        </video>
                    )
                    break;
                case 'application/pdf':
                    context_body = (
                        <FontAwesomeIcon icon={"file-pdf"} color={'#607D8B'} size={"2x"} className={'p-mt-2 p-mr-2'}/>
                    )
                    break;
                case 'text/plain':
                    context_body = (
                        <FontAwesomeIcon icon={"file-alt"} color={'#607D8B'} size={"2x"} className={'p-mt-2 p-mr-2'}/>
                    )
                    break;
                case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                    context_body = (
                        <FontAwesomeIcon icon={"file-powerpoint"} color={'#607D8B'} size={"2x"}
                                         className={'p-mt-2 p-mr-2'}/>
                    )
                    break;
                case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                case 'application/msword':
                    context_body = (
                        <FontAwesomeIcon icon={"file-word"} color={'#607D8B'} size={"2x"} className={'p-mt-2 p-mr-2'}/>
                    )
                    break;
                case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                case 'application/vnd.ms-excel':
                case 'text/csv':
                    context_body = (
                        <FontAwesomeIcon icon={"file-excel"} color={'#607D8B'} size={"2x"} className={'p-mt-2 p-mr-2'}/>
                    )
                    break;
                case 'application/contact':
                    context_body = (
                        <FontAwesomeIcon icon={"contact-card"} color={'#607D8B'} size={"2x"} className={'p-mt-2 p-mr-2'}/>
                    )
                    break;
                case 'application/maps':
                    context_body = (
                        <FontAwesomeIcon icon={"map-location"} color={'#607D8B'} size={"2x"} className={'p-mt-2 p-mr-2'}/>
                    )
                    break;
                default:
                    context_body = (
                        <Linkify componentDecorator={linkDecorator}>
                            <div className={'message-body'}>
                                <span className={'message-body-text'}>{ data.emoji_parent }</span>
                            </div>
                        </Linkify>
                    )
                    break;
            }

            let mime_type_context_form = null;
            let message_context_file = null;
            let mime_type_context = getMimetypeContext(messageParent.mime_type);
            if (mime_type_context !== null) {
                mime_type_context_form = (
                    <div className={'message-context-body-text'}>
                        {mime_type_context}
                    </div>
                );
                message_context_file = (
                    <div className={'message-context-file'}>
                        <div className={'message-context-file-body'}>
                            <div className={'message-context-body-text'}>
                                {context_body}
                            </div>
                        </div>
                    </div>
                )
            } else {
                mime_type_context_form = (
                    <div className={'message-context-body-text'}>
                        {context_body}
                    </div>
                )
            }

            let message_preview_context = data.message_out ? 'message-preview-context-in' : 'message-preview-context-out';
            if (data.message_type.name === 'scheduled_message_sent'){
                message_preview_context += ' message-preview-context-scheduled-message';
            }
            context = (
                <div className={message_preview_context} onClick={(e) => updateSelectedMessageContext(data.parent.id)}>
                    <span className={'message-context-line'}></span>
                    <div className={'message-context'}>
                        <div className={'message-context-body'}>
                            <div className={'message-context-body-title'}>
                                <span>{author_name}</span>
                            </div>
                            {mime_type_context_form}
                        </div>
                    </div>
                    {message_context_file}
                </div>
            )
        }
        return context;
    }

    const getMimetypeContext = (mime_type) => {
        switch(mime_type) {
            case 'audio/mpeg':
            case 'audio/ogg; codecs=opus':
            case 'audio/ogg':
                return (
                    <span>
                        <FontAwesomeIcon icon={"microphone"} color={'#607D8B'} size={"sm"} className={'p-mr-1'} />
                        Audio
                    </span>
                )
            case 'image/webp':
            case 'image/png':
            case 'image/jpeg':
                return (
                    <span>
                        <FontAwesomeIcon icon={"camera"} color={'#607D8B'} size={"sm"} className={'p-mr-1'}/>
                        Foto
                    </span>
                )
            case 'video/mp4':
                return (
                    <span>
                        <FontAwesomeIcon icon={"video"} color={'#607D8B'} size={"sm"} className={'p-mr-1'} />
                        Video
                    </span>
                )

            case 'application/pdf':
                return (
                    <span>
                        <FontAwesomeIcon icon={"file-pdf"} color={'#607D8B'} size={"sm"} className={'p-mr-1'} />
                        PDF
                    </span>
                )

            case 'text/plain':
                return (
                    <span>
                        <FontAwesomeIcon icon={"file-alt"} color={'#607D8B'} size={"sm"} className={'p-mr-1'} />
                        Archivo
                    </span>
                )
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                return (
                    <span>
                        <FontAwesomeIcon icon={"file-powerpoint"} color={'#607D8B'} size={"sm"} className={'p-mr-1'} />
                        PPT
                    </span>
                )

            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/msword':
                return (
                    <span>
                        <FontAwesomeIcon icon={"file-word"} color={'#607D8B'} size={"sm"} className={'p-mr-1'}/>
                        Word
                    </span>
                )

            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.ms-excel':
            case 'text/csv':
                return (
                    <span>
                        <FontAwesomeIcon icon={"file-excel"} color={'#607D8B'} size={"sm"} className={'p-mr-1'}/>
                        CSV
                    </span>
                )
            case 'application/contact':
                return (
                    <span>
                        <FontAwesomeIcon icon={"address-card"} color={'#607D8B'} size={"sm"} className={'p-mr-1'}/>
                        Contacto
                    </span>
                )

            case 'application/maps':
                return (
                    <span>
                        <FontAwesomeIcon icon={"map-marked-alt"} color={'#607D8B'} size={"sm"} className={'p-mr-1'} />
                        Ubicación
                    </span>
                )
            default:
                return null;
        }
    }

    const messageForwarded = data.forwarded ? (
        <div className={'message-forwarded'}>
            <span className={'message-forwarded-icon'}>
                <FontAwesomeIcon icon={"share"} color={'#607D8B'} size={"sm"} className={'p-mt-2 p-mr-2'}/>
            </span>
            <span className={'message-forwarded-text'}>
                Reenviado
            </span>
        </div>
    ) : null;

    const messageReferral = referrals ? (
        <div className='message-referrals'>
            <div >
                <span>
                    <FontAwesomeIcon icon={"rectangle-ad"} color={'#607D8B'} size={"sm"} className={'p-mt-2 p-mr-2'}/>
                </span>
                <span>
                    Mensaje a partir de un anuncio
                </span>
            </div>
            <div className='message-referrals-bubble'>
                <div>
                    <a href={referrals.source_url} target="_blank" rel="noopener noreferrer" style={{position: 'relative', cursor: 'pointer'}}>
                        {referrals.source_url}
                    </a>
                    <p>
                        {referrals.body}
                    </p>
                </div>
            </div>
        </div>
    ) : null;

    const messageCaption = captions ? (
        <div className={'message-caption'}>
            <span>
                {captions.caption}
            </span>
        </div>
    ) : null;

    const formatBold = (text) => {
        return <b>{text.replace(/\*(.*?)\*/g, "$1")}</b>
    }
    const formatItalic = (text) => {
        return <i>{text.replace(/_(.*?)_/g, "$1")}</i>
    }

    const initChatOptions = [
        {
            label: 'Iniciar Chat',
            icon: 'pi pi-reply',
            command: (e) => initChat(e),
        }
    ]

    const initChat = (e) => {
        setSelectedPhoneContact((prevState) => {
            return {
                ...prevState,
                active: true
            }
        })
    }

    const toggleMenu = (e) => {
        e.preventDefault()

        setSelectedPhoneContact((prevState) => {
            return {
                ...prevState,
                value: e.target.textContent
            }
        })
        menu.current.toggle(e);
    }

    const parsePhoneNumber = (text, regex_phone_number) => {
        let valid = phone(regex_phone_number[0], {country: 'AR'}).isValid;

        if (regex_phone_number[0].length < 10){
            valid = false;
        }
        let phone_number_button = valid ? (
            <Fragment>
                <Menu
                    model={initChatOptions} popup ref={menu}
                    id={`popup-menu-contact`}
                />
                <button onClick={(e) => toggleMenu(e)} className={'p-link lightBlue conversation-button-link'}>
                    {regex_phone_number[0]}
                </button>
            </Fragment>
        ) : regex_phone_number[0];

        if (valid){
            let phone_number = text.split(' ');
            let phone_number_index = phone_number.findIndex(x => x === regex_phone_number[0])
            phone_number[phone_number_index] = phone_number_button

            return (
                <Fragment>
                    {phone_number.map((x, index) => {
                        if (index !== phone_number_index){
                            x = x.concat(' ')
                        }
                        return (
                            <Fragment key={index}>
                                {x}
                            </Fragment>
                        )
                    })
                    }
                </Fragment>
            )
        } else {
            return text;
        }

    }

    const formatBody = (body) => {
        let text = body;

        if(text.match(/\*(.*?)\*/g)){
            text = formatBold(text)
        }
        else if (text.match(/_(.*?)_/g)){
            text = formatItalic(text)
        }
        else if (text.match(/\d+/)){
            let has_phone_number = text.match(/\d+/);

            if (has_phone_number){
                text = parsePhoneNumber(text, has_phone_number)
            }
        }
        return text
    }

    const SwitchMimetype = () => useMemo(() => {
        let messageFileBubble = data.message_out ? 'message-file-bubble-in' : 'message-file-bubble-out';

        switch(mimetype) {
            case 'audio/mpeg':
            case 'audio/ogg; codecs=opus':
            case 'audio/ogg':
            case 'video/mpeg':
                return (
                    <div>
                        {nameGroup()}
                        <audio ref={(ref) => (audioRefs.current[index] = ref)}
                               src={removePushnameUrl(data.message, true)}
                               controls
                               onPlay={() => {handlePlay(index)}}
                        />
                        {messageCaption}
                    </div>
                    )
            case 'image/webp':
            case 'image/png':
            case 'image/jpeg':
                if (!huaweicheck && linkExpired){
                    return expiredInput('file-image')
                } else {
                    return (
                        <div>
                            {messageForwarded}
                            {nameGroup()}
                            <div className='message-image-video-bubble'>
                                <a href={'#'} onClick={(event)=>onDialogImagen(event,removePushnameUrl(data.message, true))} target="_blank" rel="noopener noreferrer">
                                    <img src={removePushnameUrl(data.message, true)} alt={'message'} className={'message-media'}/>
                                </a>
                                {messageCaption}
                            </div>
                        </div>
                    );
                }
            case 'video/mp4':
            case 'video/webm':

                if (!huaweicheck && linkExpired){
                    return expiredInput('file-video')
                } else {
                    return (
                        <div>
                            {nameGroup()}
                            <div className='message-image-video-bubble'>
                            <video  style={{ display: 'block' }} controls >
                                <source src={removePushnameUrl(data.message, true)} type="video/mp4" className={'message-media'}/>
                            </video>
                            {messageCaption}
                            </div>
                        </div>
                    )
                }
            case 'application/pdf':
                if (!huaweicheck && linkExpired){
                    return expiredInput('file-pdf')
                } else {
                    return (
                        <div>
                            {messageForwarded}
                            {nameGroup()}
                            <div className='message-file-bubble'>
                                <div className={messageFileBubble}>
                                    <a href={removePushnameUrl(data.message, true)} target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={"file-pdf"} color={'#ff4040'} size={"4x"} style={{ width: '0.8em', height: '0.8em' }} />
                                    </a>
                                    <span>{captions?.filename ? captions?.filename : onFilename(data.message, 'pdf')}</span>
                                </div>
                                {messageCaption}
                            </div>
                        </div>
                    )
                }
            case 'text/plain':
                if (!huaweicheck && linkExpired){
                    return expiredInput('file-alt')
                } else {
                    return (
                        <div>
                            {nameGroup()}
                            <div className={messageFileBubble}>
                                <a href={removePushnameUrl(data.message, true)} target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={"file-alt"} color={'#607D8B'} size={"4x"} style={{width: '0.8em', height: '0.8em'}}/>
                                </a>
                                {messageCaption}
                                <span>{captions?.filename ? captions?.filename : onFilename(data.message, 'txt')}</span>
                            </div>
                        </div>
                    )
                }
           case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
               if (!huaweicheck && linkExpired){
                   return expiredInput('file-powerpoint')
               } else {
                   return (
                       <div>
                           {nameGroup()}
                            <div className={messageFileBubble}>
                                <a href={removePushnameUrl(data.message, true)} target="_blank" rel="noopener noreferrer" download>
                                    <FontAwesomeIcon icon={"file-powerpoint"} color={'#f59622'} size={"4x"} style={{width: '0.8em', height: '0.8em'}}/>
                                </a>
                                <span>{captions?.filename ? captions?.filename : onFilename(data.message, 'pptx')}</span>
                            </div>
                           {messageCaption}
                       </div>
                   )
               }
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            case 'application/msword':
                if (!huaweicheck && linkExpired){
                    return expiredInput('file-word')
                } else {
                    return (
                        <div>
                            <div className={messageFileBubble}>
                                <a href={removePushnameUrl(data.message, true)} target="_blank" rel="noopener noreferrer" download>
                                    <FontAwesomeIcon icon={"file-word"} color={'#00b8ff'} size={"4x"} style={{width: '0.8em', height: '0.8em'}}/>
                                </a>
                                <span>{captions?.filename ? captions?.filename : onFilename(data.message, 'docx')}</span>
                            </div>
                            {messageCaption}
                        </div>
                    )
                }
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.ms-excel':
            case 'text/csv':
                if (!huaweicheck && linkExpired){
                    return expiredInput('file-excel')
                } else {
                    return (
                        <div>
                            {nameGroup()}
                            <div className={messageFileBubble}>
                                <a href={removePushnameUrl(data.message, true)} target="_blank" rel="noopener noreferrer" download>
                                    <FontAwesomeIcon icon={"file-excel"} color={'#00b347'} size={"4x"} style={{width: '0.8em', height: '0.8em'}}/>
                                </a>
                                <span>{captions?.filename ? captions?.filename : onFilename(data.message, 'xls')}</span>
                            </div>
                            {messageCaption}
                        </div>
                    )
                }
            case 'application/contact':
                let phone_contacts = removePushnameUrl(data.message, true).split('Tel:')
                if (phone_contacts[0] === ''){
                    phone_contacts.shift()
                }

                phone_contacts = phone_contacts.map(x => {
                  return x.trim().replace(/[^\d\+]/g,'');
                });


                let contact_buttons = phone_contacts.map((x, index) => {
                    return (
                        <div className="conversation-snippet" key={index}>
                            <Menu
                                model={initChatOptions} popup ref={menu}
                                id={`popup-menu-contact`}
                            />
                            <button onClick={(e) => toggleMenu(e)} className={'p-link lightBlue conversation-button-link'}>
                                {x}
                            </button>
                        </div>
                    );
                });
                return (
                    <div>
                        <div className={'p-d-flex'}>
                            <div className={'p-mr-3'}>
                                <div className="p-mt-4">
                                    <img className="conversation-photo" src='/assets/layout/images/profile.png' alt={'contact'}/>
                                </div>
                            </div>

                            <div className="conversation-info p-mt-3">
                                {nameGroup()}
                                {contact_buttons}
                            </div>
                        </div>
                    </div>
                )
            case 'application/maps':
                return (
                    <div>
                        {nameGroup()}
                        <div dangerouslySetInnerHTML={{ __html: "<iframe width='100%' height='200' frameborder='0' scrolling='no' marginheight='0' marginwidth='0' src='https://maps.google.com/maps?width=100%25&amp;height=200&amp;hl=en&amp;q="+data.message.split('q=')[1]+"+(Aqui)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed'></iframe>"}} />
                    </div>
                )
            default:
                return (
                    <Linkify componentDecorator={linkDecorator}>
                        {messageReferral}
                        {messageForwarded}
                        <div className={'message-body'}>
                            <span className={'message-body-text'}>
                                {formatBody(data.emoji)}
                            </span>
                        </div>
                    </Linkify>
                )
        }
    }, [props.message]);
    return (
            <div className='p-d-flex p-jc-between'>
                <div>
                    { DialogImage() }
                    { getContext() }
                    { SwitchMimetype() }
                </div>
                <div className={'message-preview-options p-d-flex'}>
                    <div className={'message-preview-icon p-ml-auto'}>
                        {props.options}
                    </div>
                </div>
            </div>
    );
}
