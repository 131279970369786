import React, {useState, useEffect, Fragment, useRef, useContext} from 'react';
import ApiLoan from "../../service/ApiLoanService";
import { DataScroller } from 'primereact/datascroller';
import {InputText} from "primereact/inputtext";
import {Checkbox} from "primereact/checkbox";
import { Toast } from 'primereact/toast';
import {MessageContext} from "../../context/messageContext";
import "../../assets/css/CustomTagsInput.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {switchRole} from "../../shared/roles";

const CustomTagsInput = (props) => {
    const [editCustomTag, setEditCustomTag] = useState(null);
    const [nameCustomTag, setNameCustomTag] = useState(null);

    const [selectedTags, setSelectedTags] = useState([]);
    const [globalFilter, setGlobalFilter] = useState("");
    const [values, setValues] = useState([]);
    const [count, setCount] = useState(0);
    const [selectedCustomTagsDisabled, setSeletedCustomTagsDisabled] = useState(false);
    const message_context = useContext(MessageContext);

    useEffect(() => {
        if (message_context.selectedCustomTags.length >= 0){
            setSelectedTags(message_context.selectedCustomTags)
        }
    }, [message_context.selectedCustomTags]);

    useEffect(() => {
        const searchByGlobalFilter = () => {
            return message_context.customTags.filter(x => x.name.toLowerCase().includes(globalFilter.toLowerCase()));
        }
        const dataScrollerValue = globalFilter === "" ? message_context.customTags : searchByGlobalFilter();
        setValues(dataScrollerValue);
    }, [globalFilter, message_context.customTags]);

    useEffect(() => {
        if (editCustomTag === null && selectedCustomTagsDisabled){
            setSeletedCustomTagsDisabled(false);
        }
    }, [editCustomTag, selectedCustomTagsDisabled]);

    const onTagChange = (e) => {
        let tags = [...selectedTags];

        if (e.checked) {
            tags.push(e.value);
        }
        else {
            tags.splice(tags.indexOf(e.value), 1);
        }
        setCount(count+1);

        setSelectedTags(tags);
    }

    const editHandler = (data) => {
        setEditCustomTag(data.id);
        setNameCustomTag(data.name);
        setSeletedCustomTagsDisabled(true);
    }

    const deleteHandler = (data) => {
        const custom_tag_id = data.id;
        const apiService = new ApiLoan();

        apiService.deleteResource({
            url: 'custom_tags',
            resource_id: custom_tag_id
        }).then(response => {
            showSuccess('Etiqueta Eliminada');
        }).catch(error => {
            showError(error.response.data.message);
        });
    }

    const editCustomTagHandler = (e) => {
        let value = {...e}
        const apiService = new ApiLoan();
        value.name = nameCustomTag;

        let dataForm = {
            name: value.name
        }

        apiService.patchResource({
            url: 'custom_tags',
            resource_id: value.id,
            data: dataForm
        }).then(response => {
            showSuccess('Etiqueta Modificada');
            setEditCustomTag(null);
            let custom_tags = [...values];
            let custom_tag_edited = custom_tags.filter(x => x.id === value.id)[0];
            if (custom_tag_edited !== undefined) {
                custom_tag_edited.name = value.name;
                setValues(custom_tags);
            }
            setSeletedCustomTagsDisabled(false);
        }).catch(error => {
            if (typeof error.response.data.message === 'object'){
                Object.values(error.response.data.message).map(data => {
                    if (typeof(data) === 'object'){
                        data.map(msg => {
                            return showError(msg);
                        });
                    }
                    return data;
                });
            }
        });
    }

    const itemToggleButtons = (data) => {
        let buttons;
        let role = switchRole();

        if (data.id === editCustomTag){
            buttons = (
                <Fragment>
                    <button className="p-link" onClick={(e) => editCustomTagHandler(data)}>
                        <FontAwesomeIcon icon={"check"} style={{background: '#4CAF50'}} color={'white'} size={"xs"} className={"btn-actions btn-fontawesome"}/>
                    </button>
                    <button className="p-link" onClick={(e) => setEditCustomTag(null)}>
                        <FontAwesomeIcon icon={"times"} style={{background: 'red'}} color={'white'} size={"xs"} className={"btn-actions btn-fontawesome"}/>
                    </button>
                </Fragment>
            );
        } else {

            let disabled = true;
            if (!data.fixed){
                disabled = false;
            }
            if (selectedCustomTagsDisabled){
                disabled = true;
            }
            if (role === 'customer_support'){
                disabled = true;
            }
            buttons = (
                <Fragment>
                    {
                        //<Checkbox disabled={selectedCustomTagsDisabled} className={"btn-actions btn-actions-default btn-fontawesome checkbox-tag"} inputId="custom-tags" name="custom_tag" value={data.id}
                        //      onChange={onTagChange} checked={selectedTags.indexOf(data.id) !== -1} />
                    }
                    <button className={`p-link ${disabled ? 'custom-tag-input-btn-disabled' : ''}`} disabled={disabled} onClick={(e) => editHandler(data)} >
                        <FontAwesomeIcon icon={"pen"} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome"}/>
                    </button>
                    <button className={`p-link ${disabled ? 'custom-tag-input-btn-disabled' : ''}`} disabled={disabled} onClick={(e) => deleteHandler(data)}>
                        <FontAwesomeIcon icon={"trash-alt"} style={{background: 'red'}} color={'white'} size={"xs"} className={"btn-actions btn-fontawesome"}/>
                    </button>
                </Fragment>
            );
        }
        return buttons;
    }

    const inputTag = (data) => {
        let inputElement = (
            <div className="tag-name" style={{backgroundColor: data.color}}>
                {data.name}
            </div>
        );
        if (data.id === editCustomTag){
            inputElement = (
                <span className="p-float-label">
                  <InputText
                      className="p-inputtext-sm"
                      value={nameCustomTag}
                      onChange={(e) => setNameCustomTag(e.target.value)}
                  />
                  <label htmlFor="input">Ingrese Nombre</label>
                </span>
            );
        }

        return inputElement;
    }

    const itemTemplate = (data) => {
        const input = inputTag(data);

        return (
            <div className="p-grid p-fluid p-mt-4">

                <div className="p-col-12 p-sm-12 p-md-12 p-lg-6">
                    {input}
                </div>
                <div className="p-col-12 p-sm-12 p-md-12 p-lg-6">
                    <div className="tag-action">
                        {itemToggleButtons(data)}
                    </div>
                </div>
            </div>
        );
    }

    const selectedCustomTagsHandler = (e, val, initial) => {
        let tags = val === undefined ? selectedTags : val;

        if (initial !== undefined){
            setSelectedTags([]);
        }

        message_context.updateSelectedCustomTags(tags);
        message_context.updateCloseModal(true);

    }

    const selectedTagsButtons = () => {
        let form = null;

        if (selectedTags.length > 0){
            form = (
                <div className="selected-tags-buttons">
                    <button className="p-link" onClick={(e) => selectedCustomTagsHandler(e)}>
                        <FontAwesomeIcon icon={"check"} style={{background: '#4CAF50'}} size={"xs"} className={"btn-actions btn-actions-default btn-fontawesome"}/>
                    </button>
                </div>
            );
        } else {
            if (count > 0){
                if (message_context.selectedCustomTags.length !== 0){
                    message_context.updateSelectedCustomTags([])
                }
            }
        }
        return form;
    }

    const header_button = (
        <Fragment>
            <div className="p-col-12">
                <InputText type="search" className="p-inputtext-sm" value={globalFilter} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Buscar Etiqueta" />
            </div>
        </Fragment>
    );


    const toast = useRef(null);

    const showSuccess = (detail) => {
        let summary_message = 'Etiquetas';
        toast.current.show({severity: 'success', summary: summary_message, detail: detail});
    }

    const showError = (detail) => {
        let summary_message = 'Etiquetas';
        toast.current.show({severity: 'error', summary: summary_message, detail: detail});
    }

    return (
        <Fragment>
            <Toast ref={toast} />
            <div className="datascroller-tags">
                <DataScroller value={values} lazy itemTemplate={itemTemplate} rows={10} inline scrollHeight="350px" header={header_button} />
            </div>
            <div style={{height:'20px'}}></div>
            <div>
                {
                //selectedTagsButtons()
                }
            </div>
        </Fragment>
    );
}

export default CustomTagsInput;