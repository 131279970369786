import React, {useState, useEffect, useRef, Fragment} from 'react';
import "../../../assets/css/SheetForm.css";
import { Button } from 'primereact/button';
import {Toast} from "primereact/toast";
import {InputTextarea} from "primereact/inputtextarea";
import CopyToClipboard from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const VariableForm = (props) => {
    const [formValues, setFormValues] = useState({
        name: null,
        variable: null,
        error: {},
    });
    const refToast = useRef(null);
    const refInputQuestions = useRef(null);

    useEffect(() => {
        if(props.formData){
            setFormValues(props.formData.question_variable_config)
        }
    }, [props.formData]);

    const copyValue = (value) => {
        return(
            <CopyToClipboard style={{bottom: '8px'}} text={value}>
                <FontAwesomeIcon icon={"copy"} color={'#FD971F'} size="xs"
                                 className={"btn-actions btn-fontawesome"} />
            </CopyToClipboard>
        )
    }

    const recursiveExtractAllQuestionConfigValues = (obj, accumulator = []) => {
        if (obj && typeof obj === 'object') {
            if (Array.isArray(obj)) {
                obj.forEach(item => recursiveExtractAllQuestionConfigValues(item, accumulator));
            } else {
                for (let key in obj) {
                    if (key === 'question_variable_config') {
                        accumulator.push({...obj[key], 'question': obj['question']});
                    } else {
                        recursiveExtractAllQuestionConfigValues(obj[key], accumulator);
                    }
                }
            }
        }
    };

    const updateVariable = (e) => {
        let newFormValues = {...formValues}
        const variable = [];
        newFormValues.name = e.value;
        newFormValues.variable = processVariable(e.value);
        recursiveExtractAllQuestionConfigValues(props.formValues, variable);
        for (let i = 0; i < variable.length; i++) {
            const element = variable[i];
            if (element.question !== props.formData.question) {
                if (element.name) {
                    if (processVariable(element.name) === newFormValues.variable) {
                        newFormValues.error = { name: ['Variable Existente'] };
                        break;
                    } else {
                        newFormValues.error = {};
                        break;
                    }
                }
            }
        }
        setFormValues(newFormValues);
    }

    const errorVariable = (data) => {
        let newFormValues = {...formValues}
        newFormValues['error'] = data.errors;
        setFormValues(newFormValues);
    }

    const getFieldError = (errors, field) => {
        if (errors === undefined){
            return
        }
        let form = null;
        
        if (Object.keys(errors).filter(x=> x === field).length > 0){
            form = (
                <small style={{color:'#f44336'}} className="p-error">
                    {errors[field][0]}
                </small>
            );
        }

        return form;
    }

    const recursiveRemoveKeyInBodyByValue = (obj, targetValue) => {
        if (obj && typeof obj === 'object') {
            if (Array.isArray(obj)) {
                return obj.map(item => recursiveRemoveKeyInBodyByValue(item, targetValue));
            } else {
                const newObj = {};
                for (let key in obj) {
                    newObj[key] = recursiveRemoveKeyInBodyByValue(obj[key], targetValue);
                }
                if (obj['provider_webhook'] && typeof obj['provider_webhook'] === 'object') {
                    const webhookObj = obj['provider_webhook'];
                    if (webhookObj['body'] && typeof webhookObj['body'] === 'object') {
                        const bodyObj = webhookObj['body'];
                        for (let bodyKey in bodyObj) {
                            if (bodyObj[bodyKey] === targetValue) {
                                delete bodyObj[bodyKey];
                            }
                        }
                    }
                }
                return newObj;
            }
        } else {
            return obj;
        }
    };

    const handleDelete = () => {
        let olfFormValues = {...formValues};
        props.setFormData({name: null, variable: null, error: {}});
        props.setQuestionnaires(recursiveRemoveKeyInBodyByValue(props.questionnaires, olfFormValues.variable));
        props.hideVariable();
    }

    const processVariable = (inputString) => {
        if (!inputString) {
            return null;
        }
    
        const cleanString = inputString
            .replace(/[^\w\sáéíóúü]/gi, '')
            .replace(/[áéíóúü]/gi, match => {
                const mapping = {
                    'á': 'a',
                    'é': 'e',
                    'í': 'i',
                    'ó': 'o',
                    'ú': 'u',
                    'ü': 'u'
                };
                return mapping[match] || match;
            });
    
        const words = cleanString.split(/\s+/);
    
        const pascalCaseString = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join('');
        const result = `{${pascalCaseString}}`;
        return result;
    };

    const handleSubmit = () => {
        if(!formValues.name){
            errorVariable({errors: {name: ['Campo Obligatorio']}})
        }else{
            if (Object.keys(formValues.error).length === 0){
                props.setFormData(formValues);
                props.hideVariable();
            }
        }
    }

    let form = (
        <div className="p-grid p-formgrid" style={{position: 'relative', top: '20px', paddingBottom: '20px'}}>
            <div className="p-col-12 p-md-12">
                <Toast ref={refToast}/>
                <div className="p-grid p-formgrid">
                    <div className="p-col-12">
                        <div className="p-col-12">
                            <div className="p-grid p-formgrid">
                                <div className="p-field p-col">
                                    <div className='p-inputgroup'>
                                        <span className="p-float-label space-right">
                                            <InputTextarea name={'name'} value={formValues.name}
                                                       onChange={(e) => updateVariable(e.target)}
                                                       ref={el => refInputQuestions.current = el}
                                                       className={'sheet-question'} rows={1} />
                                            <label htmlFor="inputtext">{'Ingresa Variable'}</label>
                                         </span>
                                    </div>
                                    {getFieldError(formValues.error, 'name')}
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="p-grid p-formgrid">
                                <div className="p-field p-col">
                                    <div className='p-inputgroup'>
                                    <span><b>{formValues.variable}</b></span>
                                    {formValues.name ? copyValue(formValues.variable) : null}
                                    </div>
                                    {getFieldError(formValues.error, 'variable')}
                                </div>
                            </div>
                        </div>
                        <div className='p-inputgroup'>
                            <Button label={props.formData.question_variable_config.name !== null ? 'Guardar' : 'Crear'}
                            onClick={handleSubmit} className="p-button-raised p-ml-auto" style={{width: props.formData.question_variable_config.name !== null ? '50%' : '100%'}}
                            icon={props.formData.question_variable_config.name !== null ? 'pi pi-pencil' : 'pi pi-plus'}
                            />
                            <span>&nbsp;</span>
                            {props.formData.question_variable_config.name !== null &&
                                <Button className="p-button-raised p-button-danger p-ml-auto" label={'Eliminar'} style={{width: '50%'}}
                                onClick={handleDelete}
                            icon={'pi pi-times'}
                            />
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    return form

}

export default VariableForm;